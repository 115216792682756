import React from 'react';
import Checkbox from './Form/Checkbox';

type MediaToggleProps = {
  updateItem: void,
  id: string,
  checked: boolean,
  prop: string,
};

const MediaToggle = ({
  updateItem, id, checked, prop,
}: MediaToggleProps) => {
  const handleCheckBoxChange = (e) => {
    updateItem(id, { [prop]: e.target.checked });
  };

  return <Checkbox onChange={handleCheckBoxChange} id={id} checked={checked} />;
};
export default MediaToggle;
