import React from 'react';
import { Link } from '@react-navigation/web';

import { slide as Menu } from 'react-burger-menu';

type BurgerMenuProps = {
  menuItems: Array,
};
const BurgerMenu = ({ menuItems }: BurgerMenuProps) => (
  <Menu
    pageWrapId="burger-content"
    outerContainerId="burger-container"
    customBurgerIcon={(
      <div>
        <i className="icon-menu" />

        <span>menu</span>
      </div>
)}
    customCrossIcon={(
      <div>
        <i className="fa fa-times" />
      </div>
)}
  >
    <ul className="nav nav-sidebar">
      {menuItems.map(item => (
        <li
          key={`route_${item.routeName}`}
          className={window.location.pathname === item.path ? 'active' : ''}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link routeName={item.routeName}>{item.name}</Link>
        </li>
      ))}
    </ul>
  </Menu>
);
export default BurgerMenu;
