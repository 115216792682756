import { REHYDRATE } from 'redux-persist';
import { combineReducers } from 'redux';
import actions from '../actions';

const initialIsLoadingState = false;
const isLoading = (state = initialIsLoadingState, action = {}) => {
  switch (action.type) {
    case actions.campaigns.apiActionTypes.GET_CAMPAIGNS.REQUEST:
      return true;
    case actions.campaigns.apiActionTypes.GET_CAMPAIGNS.SUCCESS:
    case actions.campaigns.apiActionTypes.GET_CAMPAIGNS.FAILURE:
      return false;
    default:
      return state;
  }
};

const isSaving = (state = initialIsLoadingState, action = {}) => {
  switch (action.type) {
    case actions.campaigns.apiActionTypes.CREATE_CAMPAIGN.REQUEST:
    case actions.campaigns.apiActionTypes.UPDATE_CAMPAIGN.REQUEST:
    case actions.campaigns.apiActionTypes.DELETE_CAMPAIGN.REQUEST:
      return true;
    case actions.campaigns.apiActionTypes.CREATE_CAMPAIGN.SUCCESS:
    case actions.campaigns.apiActionTypes.CREATE_CAMPAIGN.FAILURE:
    case actions.campaigns.apiActionTypes.UPDATE_CAMPAIGN.SUCCESS:
    case actions.campaigns.apiActionTypes.UPDATE_CAMPAIGN.FAILURE:
    case actions.campaigns.apiActionTypes.DELETE_CAMPAIGN.SUCCESS:
    case actions.campaigns.apiActionTypes.DELETE_CAMPAIGN.FAILURE:
      return false;
    default:
      return state;
  }
};

const initialCampaignsState = [];
const data = (state = initialCampaignsState, action = {}) => {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.payload.campaigns && action.payload.campaigns.data) {
        return action.payload.campaigns.data;
      }
      return state;
    case actions.campaigns.apiActionTypes.GET_CAMPAIGNS.REQUEST:
      return initialCampaignsState;
    case actions.campaigns.apiActionTypes.GET_CAMPAIGNS.SUCCESS:
      return action.payload.response;
    case actions.campaigns.apiActionTypes.CREATE_CAMPAIGN.SUCCESS: {
      return [action.payload.response, ...state];
    }
    case actions.campaigns.apiActionTypes.UPDATE_CAMPAIGN.SUCCESS: {
      const index = state.findIndex(i => i.id === action.payload.response.id);
      return [...state.slice(0, index), action.payload.response, ...state.slice(index + 1)];
    }
    case actions.campaigns.apiActionTypes.DELETE_CAMPAIGN.SUCCESS: {
      const index = state.findIndex(i => i.id === action.payload.request.id);
      return [...state.slice(0, index), ...state.slice(index + 1)];
    }
    case actions.auth.uiActionTypes.LOGOUT:
      return initialCampaignsState;
    default:
      return state;
  }
};

const initialVoucherDetailState = {};
const voucherDetail = (state, action = {}) => {
  switch (action.type) {
    case actions.campaigns.apiActionTypes.SEARCH_VOUCHER.SUCCESS:
      return action.payload.response;

    default:
      return initialVoucherDetailState;
  }
};

const campaigns = combineReducers({
  isLoading,
  isSaving,
  data,
  voucherDetail,
});

export default campaigns;
