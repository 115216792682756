import { takeLatest, call, select } from 'redux-saga/effects';
import { apiCall } from './util';
import actions from '../actions';
import selectors from '../selectors';
import { api } from '../services';
// bind api calls to actions

const getSeriesApiCall = apiCall.bind(
  null,
  actions.series.apiActions.getSeries,
  api.getSeries,
);
const createSerieApiCall = apiCall.bind(
  null,
  actions.series.apiActions.createSerie,
  api.createSerie,
);
const updateSerieApiCall = apiCall.bind(
  null,
  actions.series.apiActions.updateSerie,
  api.updateSerie,
);
const deleteSerieApiCall = apiCall.bind(
  null,
  actions.series.apiActions.deleteSerie,
  api.deleteSerie,
);

const getSerieItems = function* (action) {
  const token = yield select(selectors.auth.tokenSelector);
  yield call(getSeriesApiCall, { token, query: action.payload.query });
};

export const getSeriesSaga = function* () {
  yield takeLatest(actions.series.uiActionTypes.GET_SERIES, getSerieItems);
};

const createSerie = function* (action) {
  const { serie = {}, id } = action.payload;
  const token = yield select(selectors.auth.tokenSelector);
  yield call(createSerieApiCall, { token, serie });
};

export const createSerieSaga = function* () {
  yield takeLatest(actions.series.uiActionTypes.CREATE_SERIE, createSerie);
};

const updateSerie = function* (action) {
  const { serie = {}, id } = action.payload;
  const token = yield select(selectors.auth.tokenSelector);
  yield call(updateSerieApiCall, { token, serie, id });
};

export const updateSerieSaga = function* () {
  yield takeLatest(actions.series.uiActionTypes.UPDATE_SERIE, updateSerie);
};

const deleteSerie = function* (action) {
  const { id } = action.payload;
  const token = yield select(selectors.auth.tokenSelector);
  yield call(deleteSerieApiCall, { token, id });
};

export const deleteSerieSaga = function* () {
  yield takeLatest(actions.series.uiActionTypes.DELETE_SERIE, deleteSerie);
};
