import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import { SeasonsScreen } from '../../screens';

const SeasonsScreenContainer = props => <SeasonsScreen {...props} />;

const mapStateToProps = state => ({
  seasons: selectors.seasons.seasonsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getSeasons: bindActionCreators(actions.seasons.uiActions.getSeasons, dispatch),
  deleteSeason: bindActionCreators(actions.seasons.uiActions.deleteSeason, dispatch),
  addAllSeasons: bindActionCreators(actions.seasons.uiActions.addAllSeasons, dispatch),
  showModal: bindActionCreators(actions.app.uiActions.showModal, dispatch),
  updateItem: bindActionCreators(actions.seasons.uiActions.updateSeason, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SeasonsScreenContainer);
