const menuItemsAdmin = [
  {
    routeName: 'Home',
    name: 'Content',
    path: '/',
  },
  {
    routeName: 'Brands',
    name: 'Brands',
    path: '/brands',
  },
  {
    routeName: 'Sync',
    name: 'Sync',
    path: '/sync',
  },
  {
    routeName: 'Campaigns',
    name: 'Campaigns',
    path: '/campaigns',
  },
  {
    routeName: 'Series',
    name: 'Series',
    path: '/series',
  },
  {
    routeName: 'Seasons',
    name: 'Seasons',
    path: '/seasons',
  },
  {
    routeName: 'Audits',
    name: 'Audits',
    path: '/audits',
  },
];
const menuItemsCampaignManager = [
  {
    routeName: 'Home',
    name: 'Campaigns',
    path: '/',
  },
];
export { menuItemsAdmin, menuItemsCampaignManager };
