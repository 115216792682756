import moment from 'moment';

export default [
  {
    value: 'published',
    label: 'Unpublish',
    propertyLabelOff: 'Unpublish',
    type: 'checkbox',
    defaultValue: false,
    getAugmentedUpdate: ({ update }) => ({
      ...update,
      publishDate: null,
    }),
  },
  {
    value: 'publishDate',
    label: 'Publish date',
    propertyLabel: 'Publish date',
    type: 'datetime',
    default: moment(),
    getAugmentedUpdate: ({ update }) => ({
      ...update,
      published: true,
    }),
  },
  {
    value: 'locked',
    label: 'Lock',
    propertyLabelOn: 'Lock',
    propertyLabelOff: 'Unlock',
    type: 'checkbox',
    defaultValue: false,
  },
  {
    value: 'new',
    label: 'New',
    propertyLabelOn: 'Enable new for ',
    propertyLabelOff: 'Disable new for ',
    type: 'checkbox',
    defaultValue: false,
    getAugmentedUpdate: ({ update, updateValue }) => ({
      ...update,
      newCheckedDate: updateValue ? new Date().toISOString() : null,
    }),
  },
  {
    value: 'featured',
    label: 'Feature',
    propertyLabelOn: 'Enable featured for ',
    propertyLabelOff: 'Disable featured for ',
    type: 'checkbox',
    defaultValue: false,
    getAugmentedUpdate: ({ update, updateValue }) => ({
      ...update,
      featuredCheckedDate: updateValue ? new Date().toISOString() : null,
    }),
  },
  {
    value: 'deleted', label: 'Delete', defaultValue: true, actionPrefix: 'Delete',
  },
  {
    value: 'customLabel',
    label: 'Custom label',
    type: 'sub-form',
    fields: [
      {
        value: 'value',
        type: 'text',
        propertyLabel: 'Label',
      },
      {
        value: 'detailValue',
        type: 'text',
        propertyLabel: 'Detail label',
      },
      {
        value: 'color',
        type: 'color',
        propertyLabel: 'Label color',
      },
    ],
  },
];
