import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import { Brand } from '../../components/modals';

const BrandContainer = props => <Brand {...props} />;

const mapStateToProps = state => ({
  isSaving: selectors.brands.isSavingSelector(state),
  uploads: selectors.app.uploadsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  dismissModal: bindActionCreators(actions.app.uiActions.dismissModal, dispatch),
  deleteBrand: bindActionCreators(actions.brands.uiActions.deleteBrand, dispatch),
  createBrand: bindActionCreators(actions.brands.uiActions.createBrand, dispatch),
  updateBrand: bindActionCreators(actions.brands.uiActions.updateBrand, dispatch),
  uploadPreview: bindActionCreators(actions.media.uiActions.uploadPreview, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrandContainer);
