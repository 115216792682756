import {
  createUiActionType, createUiAction, createApiActionTypes, createApiActions,
} from './util';

export const uiActionTypes = {
  GET_SERIES: createUiActionType('SERIES/GET_SERIES'),
  CREATE_SERIE: createUiActionType('SERIES/CREATE_SERIE'),
  UPDATE_SERIE: createUiActionType('SERIES/UPDATE_SERIE'),
  DELETE_SERIE: createUiActionType('SERIES/DELETE_SERIE'),
};

export const uiActions = {
  getSeries: query => createUiAction(uiActionTypes.GET_SERIES, { query }),
  createSerie: serie => createUiAction(uiActionTypes.CREATE_SERIE, { serie }),
  updateSerie: (id, serie) => createUiAction(uiActionTypes.UPDATE_SERIE, { id, serie }),
  deleteSerie: id => createUiAction(uiActionTypes.DELETE_SERIE, { id }),
};

export const apiActionTypes = {
  GET_SERIE: createApiActionTypes('SERIES/GET_SERIE'),
  GET_SERIES: createApiActionTypes('SERIES/GET_SERIES'),
  CREATE_SERIE: createApiActionTypes('SERIES/CREATE_SERIE'),
  UPDATE_SERIE: createApiActionTypes('SERIES/UPDATE_SERIE'),
  DELETE_SERIE: createApiActionTypes('SERIES/DELETE_SERIE'),
};

export const apiActions = {
  getSerie: createApiActions(apiActionTypes.GET_SERIE),
  getSeries: createApiActions(apiActionTypes.GET_SERIES),
  createSerie: createApiActions(apiActionTypes.CREATE_SERIE),
  updateSerie: createApiActions(apiActionTypes.UPDATE_SERIE),
  deleteSerie: createApiActions(apiActionTypes.DELETE_SERIE),
};
