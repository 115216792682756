import React from 'react';
import SeasonArt from '../../components/SeasonArt';
import SeasonToggleContainer from '../../containers/components/SeasonToggle';
import { formatDate } from '../../utils/date';

export default {
  paginated: true,
  columns: [
    {
      prop: 'preview',
      label: 'Preview',
      sortable: true,
    },
    {
      prop: 'id',
      label: 'Id',
      sortable: true,
    },
    {
      prop: 'serieId',
      label: 'Serie Id',
    },
    {
      prop: 'title',
      label: 'Title',
      sortable: true,
    },
    {
      prop: 'language',
      label: 'Language',
      sortable: true,
    },
    {
      prop: 'brand',
      label: 'Distribution brand',
      sortable: true,
    },
    {
      prop: 'published',
      label: 'Published',
      sortable: true,
    },
    {
      prop: 'featured',
      label: 'Featured',
      sortable: true,
    },
    {
      prop: 'new',
      label: 'New',
      sortable: true,
    },
    {
      action: 'edit',
      label: 'Action',
      button_label: 'Edit',
    },
  ],
  transformer: (row) => {
    const href = row?.preview?.small?.filename || '';
    let { title } = row;

    if (row.editorialTitle && row.editorialTitle.length) {
      title = (
        <dl>
          <dd>{row.editorialTitle}</dd>
          <dd>
            <strong>Original: </strong>
            {row.title}
          </dd>
        </dl>
      );
    }
    return {
      ...row,
      title,
      id: <span className="table-col-id">{row.id}</span>,
      serieId: row.serieId,
      preview: <SeasonArt imageUrl={href} style={{ width: 80 }} />,
      published: row.published ? <div className="column-icon"><i className="fa fa-check" /></div> : null,
      featured: row.featured ? <div className="column-icon"><i className="fa fa-check" /></div> : null,
      new: row.new ? <div className="column-icon"><i className="fa fa-check" /></div> : null,
      original: row,
    };
  },
};

const seasonsLanguages = [{ value: 'NL', label: 'NL' }, { value: 'FR', label: 'FR' }];

export { seasonsLanguages };
