import moment from 'moment';

const getMediaItem = ({ item }) => (item || {}).original || {};

const getMediaMutationStateFromFieldDependencies = (currentValues, nextValues) => {
  if (!currentValues) {
    return nextValues;
  }

  const values = { ...nextValues };

  // Remove lockedDate on if it is in the past
  if (nextValues.locked === true && currentValues.unlockDate?.isBefore(moment())) {
    values.unlockDate = null;
  }

  // Set locked if unlockDate in future
  if (nextValues.unlockDate?.isAfter(moment())) {
    values.locked = true;
  }

  // Set unlocked if unlockDate in past
  if (nextValues.unlockDate?.isBefore(moment())) {
    values.locked = false;
  }

  return values;
};


export { getMediaItem, getMediaMutationStateFromFieldDependencies };

export default getMediaItem;
