import { combineReducers } from 'redux';
import moment from 'moment';
import actions from '../actions';

const initialErrorState = [];
const data = (state = initialErrorState, action = {}) => {
  const errorPayload = action.payload ? action.payload.error : false;
  switch (action.type) {
    case actions.auth.apiActionTypes.LOGIN.SUCCESS: {
      return [];
    }
    case actions.errors.uiActionTypes.DISMISS: {
      return [];
    }
    default: {
      if (errorPayload) {
        const error = {
          type: action.type,
          payload: errorPayload,
          timestamp: moment().toISOString(),
        };
        return [...state, error];
      }
      return state;
    }
  }
};

const errors = combineReducers({
  data,
});

export default errors;
