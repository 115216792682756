import React, { PureComponent } from 'react';
import syncsTableConfig from '../config/tables/sync';
import Table from './Table';
import FilterWrapper from './Filters/Wrapper';
import Search from './Filters/Search';
import { inString } from '../utils/search';
import { getContentId } from '../utils/sync';

type SyncTableProps = {
  syncData: Array,
};

class SyncTable extends PureComponent<SyncTableProps> {
  state = {
    search: '',
  };

  getItems() {
    const { syncData } = this.props;
    const { search } = this.state;
    if (search && search.length) {
      return syncData.filter(item => {
        if (inString(getContentId(item), search)) {
          return true;
        }

        return false;
      });
    }
    return syncData;
  }

  handleSearchChange = e => {
    const { value } = e.target;
    this.setState({ search: value });
  };

  handleButtonClick = (action, data) => {
    let item = {};

    switch (action) {
      case 'event':
        item = { name: 'Source', json: data.event.Records };
        break;
      case 'metadata':
        item = { name: 'Meta', json: data.metadata };
        break;
      case 'transformedMedia':
        item = { name: 'Transform', json: data.transformedMedia };
        break;
      case 'error':
        item = { name: 'Error', json: data.err };
        break;
      default:
        item = { name: '', json: {} };
        break;
    }

    const { showModal } = this.props;
    showModal({ name: 'Json', item });
  };

  render() {
    const { loading } = false;
    const { search } = this.state;
    return (
      <div>
        <FilterWrapper>
          <Search
            value={search}
            onChange={this.handleSearchChange}
            id="media-search"
            placeholder="search"
            label="Search for content Id"
          />
        </FilterWrapper>
        <Table
          items={this.getItems()}
          loading={loading}
          onAction={this.handleButtonClick}
          {...syncsTableConfig}
          paginated
        />
      </div>
    );
  }
}

export default SyncTable;
