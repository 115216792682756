import {
  createUiActionType, createUiAction, createApiActionTypes, createApiActions,
} from './util';

export const uiActionTypes = {
  GET_AUDITS_DATA: createUiActionType('AUDITS/GET_AUDITS_DATA'),
};

export const uiActions = {
  getAuditsData: query => createUiAction(uiActionTypes.GET_AUDITS_DATA, { query }),
};

export const apiActionTypes = {
  GET_AUDITS_DATA: createApiActionTypes('AUDITS/GET_AUDITS_DATA'),
};

export const apiActions = {
  getAuditsData: createApiActions(apiActionTypes.GET_AUDITS_DATA),
};
