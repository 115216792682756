import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import BrandsTable from '../../components/BrandsTable';

const BrandsTableTableContainer = props => <BrandsTable {...props} />;

const mapStateToProps = state => ({
  brands: selectors.brands.brandsSelector(state),
  loading: selectors.brands.isLoadingSelector(state),
});

const mapDispatchToProps = dispatch => ({
  showModal: bindActionCreators(actions.app.uiActions.showModal, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandsTableTableContainer);
