import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';

import MediaToggle from '../../components/MediaToggle';

const mediaToggleContainer = props => <MediaToggle {...props} />;

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  updateItem: bindActionCreators(actions.media.uiActions.updateItem, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(mediaToggleContainer);
