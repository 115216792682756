import { createSelector } from 'reselect';

export const tokenSelector = createSelector(
  state => state.auth.data.token,
  token => token,
);

export const isLoggedInSelector = createSelector(
  tokenSelector,
  token => token && token.length > 0,
);

export const userSelector = createSelector(
  state => state.auth.data.user,
  user => user,
);
