import React, { Component } from 'react';
import Table from './Table';
import mediaTableConfig, { mediaLanguages } from '../config/tables/media';
import Search from './Filters/Search';
import FilterWrapper from './Filters/Wrapper';
import Select from './Filters/Select';
import BulkActions from './BulkActions';
import { PAGE_SIZE } from '../config/pagination';

class MediaTable extends Component {
  state = {
    search: '',
    language: null,
    category: null,
    brand: null,
    subCategory: null,
    published: null,
    seasonId: null,
    selection: [],
    locked: null,
    featured: null,
    isNew: null,
    page: 1,
    size: PAGE_SIZE,
  };

  componentWillMount() {
    const { data } = this.props;

    if (data && data.items && data.items.length) {
      this.checkFilterOptions(data);
    }
  }

  componentDidMount() {
    this.getItems();
  }

  componentWillUpdate(nextProps) {
    const { data } = this.props;
    if (data.items.length !== nextProps.data.items.length) {
      this.checkFilterOptions(nextProps.data);
    }
  }

  checkFilterOptions = ({ aggregations }) => {
    if (aggregations) {
      const {
        categories, seasons, brands, subCategories,
      } = aggregations;

      this.categories = categories;
      this.seasons = seasons;
      this.brands = brands;
      this.subCategories = subCategories;
    }
  };

  handleAction = (type, props = {}) => {
    const { showModal } = this.props;
    if (type === 'playlist') {
      showModal({ name: 'Playlist', ...props });
    } else if (type === 'games') {
      showModal({ name: 'Game', ...props });
    } else {
      showModal({ name: 'OtherMedia', ...props });
    }
  };

  handleEdit = (e, item) => {
    e.preventDefault();
    this.handleAction(item.subCategory, { item });
  };

  handleChange = property => (value) => {
    const newState = { [property]: value };
    if (property !== 'page') {
      newState.page = 1;
    }

    this.setState(newState);
    setTimeout(() => {
      this.getItems();
    });
  };

  handleSortChange = (sortBy, sortOrder) => {
    this.setState({ sort_by: sortBy, sort_order: sortOrder });
    setTimeout(() => {
      this.getItems();
    });
  };

  handleSearchChange = (e) => {
    const { debounceTimeoutId } = this.state;
    if (debounceTimeoutId) {
      clearTimeout(debounceTimeoutId);
    }

    const { value } = e.target;
    const timeoutId = setTimeout(() => {
      this.getItems();
    }, 400);

    this.setState({
      search: value,
      page: 1,
      debounceTimeoutId: timeoutId,
    });
  };

  getItems = () => {
    const queryObject = {};
    Object.keys(this.state).forEach((key) => {
      if (this.state[key] !== null && key !== 'selection') {
        const prop = key === 'isNew' ? 'new' : key;
        queryObject[prop] = this.state[key];
      }
    });

    this.props.getItems(queryObject);
  };

  exportMedia = () => {
    const queryObject = {};
    const { data } = this.props;
    const { total } = data;

    Object.keys(this.state).forEach((key) => {
      if (key === 'size') {
        queryObject.size = total;
        return;
      }

      if (this.state[key] !== null && key !== 'selection') {
        const prop = key === 'isNew' ? 'new' : key;
        queryObject[prop] = this.state[key];
      }
    });

    this.props.exportMedia(queryObject);
  }

  handleBundleClick = (selectedBulkActionName, bulkValue, selectedBulkAction) => {
    const { bulkUpdateItems } = this.props;
    const { selection } = this.state;
    bulkUpdateItems(selectedBulkActionName, bulkValue, selection, selectedBulkAction);
  };

  toggleAllSelection = () => {
    const { selection } = this.state;
    const { data } = this.props;
    const items = data.items.map(item => item.id);
    if (selection.length === items.length) {
      this.setState({ selection: [] });
    } else {
      this.setState({ selection: items });
    }
  };

  toggleSelection = (key) => {
    const { selection } = this.state;
    let result = [...selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      result = [...result.slice(0, keyIndex), ...result.slice(keyIndex + 1)];
    } else {
      result.push(key);
    }
    this.setState({ selection: result });
  };

  clearSelection = () => {
    this.setState({ selection: [] });
  };

  render() {
    const {
      data, loading, bulkProgress, isExportLoading,
    } = this.props;
    const {
      search,
      language,
      selection,
      category,
      brand,
      subCategory,
      published,
      seasonId,
      locked,
      featured,
      isNew,
    } = this.state;
    const { items } = data;

    return (
      <div>
        <div className="d-flex justify-content-end mb-5">
          <button
            className="btn btn-secondary mr-2"
            onClick={() => this.handleAction('games')}
          >
            create game
          </button>
          <button
            className="btn btn-secondary mr-2"
            onClick={() => this.handleAction('playlist')}
          >
            create playlist
          </button>
          <button disabled={isExportLoading} className="btn btn-primary" onClick={this.exportMedia}>
            export media
          </button>
        </div>
        <FilterWrapper>
          <Search
            value={search}
            onChange={this.handleSearchChange}
            id="media-search"
            placeholder="search"
            label="Search for title, custom label or id"
          />
          <Select
            value={language}
            onChange={this.handleChange('language')}
            id="language-select"
            items={mediaLanguages}
            label="Language"
          />
          <Select
            value={category}
            onChange={this.handleChange('category')}
            id="category-select"
            items={this.categories}
            label="Categories"
          />
          <Select
            value={subCategory}
            onChange={this.handleChange('subCategory')}
            id="sub-category-select"
            items={this.subCategories}
            label="Sub Category"
          />
          <Select
            value={brand}
            onChange={this.handleChange('brand')}
            id="brands-select"
            items={this.brands}
            label="Brands"
          />
          <Select
            value={published}
            onChange={this.handleChange('published')}
            id="published-select"
            items={[
              { value: true, label: 'Published' },
              { value: false, label: 'Not published' },
            ]}
            label="Published"
          />
          <Select
            value={locked}
            onChange={this.handleChange('locked')}
            id="locked-select"
            items={[
              { value: true, label: 'Locked' },
              { value: false, label: 'Not Locked' },
            ]}
            label="Locked"
          />
          <Select
            value={featured}
            onChange={this.handleChange('featured')}
            id="locked-select"
            items={[
              { value: true, label: 'Featured' },
              { value: false, label: 'Not Featured' },
            ]}
            label="Featured"
          />
          <Select
            value={isNew}
            onChange={this.handleChange('isNew')}
            id="locked-select"
            items={[
              { value: true, label: 'New' },
              { value: false, label: 'Not New' },
            ]}
            label="New"
          />
          <Select
            value={seasonId}
            onChange={this.handleChange('seasonId')}
            id="season-select"
            items={this.seasons}
            label="Season"
          />
        </FilterWrapper>
        {selection.length ? (
          <BulkActions
            selectionLength={selection.length}
            onBulkClick={this.handleBundleClick}
            onCancel={this.clearSelection}
            bulkProgress={bulkProgress}
          />
        ) : null}
        <Table
          items={items}
          loading={loading}
          withCheckboxes
          allSelected={selection.length === items.length}
          onToggleAll={this.toggleAllSelection}
          onCheckboxSelected={this.toggleSelection}
          getKey={item => item.original.id}
          selection={selection}
          onEdit={this.handleEdit}
          onPageChange={this.handleChange('page')}
          onSortChange={this.handleSortChange}
          count={data.total}
          applySort={false}
          {...mediaTableConfig}
          paginated
        />
      </div>
    );
  }
}

export default MediaTable;
