import { delay } from 'redux-saga';
import {
  take,
  takeLatest,
  takeEvery,
  put,
  call,
  fork,
  select,
  all,
} from 'redux-saga/effects';
import path from 'path';
import { REHYDRATE } from 'redux-persist';
import apiCall from './util';
import actions from '../actions';
import selectors from '../selectors';
import { api } from '../services';

// bind api calls to actions

const loginApiCall = apiCall.bind(
  null,
  actions.auth.apiActions.login,
  api.loginUser,
);
const appStartApiCall = apiCall.bind(
  null,
  actions.app.apiActions.appStart,
  api.appStart,
);
const uploadFileApiCall = apiCall.bind(
  null,
  actions.app.apiActions.uploadFile,
  api.uploadFile,
);

const getSyncDataApiCall = apiCall.bind(
  null,
  actions.sync.apiActions.getSyncData,
  api.getSyncData,
);
const getAuditsDataApiCall = apiCall.bind(
  null,
  actions.audits.apiActions.getAuditsData,
  api.getAuditsData,
);
const getBrandsApiCall = apiCall.bind(
  null,
  actions.brands.apiActions.getBrands,
  api.getBrands,
);
const createBrandApiCall = apiCall.bind(
  null,
  actions.brands.apiActions.createBrand,
  api.createBrand,
);
const updateBrandApiCall = apiCall.bind(
  null,
  actions.brands.apiActions.updateBrand,
  api.updateBrand,
);
const deleteBrandApiCall = apiCall.bind(
  null,
  actions.brands.apiActions.deleteBrand,
  api.deleteBrand,
);

// sagas
const login = function* (action, token) {
  if (token) {
    yield call(loginApiCall, { token });
  } else {
    const { userId, password } = action.payload;
    yield call(loginApiCall, { userId, password });
  }
};

export const loginSaga = function* () {
  yield takeLatest(actions.auth.uiActionTypes.LOGIN, login);
};

const checkLogin = function* (action) {
  const token = yield select(selectors.auth.tokenSelector);
  if (token && token.length) {
    // validate token here
    yield login(action, token);
  } else {
    yield call(appStartApiCall, {});
  }
};

export const checkLoginSaga = function* () {
  yield takeLatest(REHYDRATE, checkLogin);
};

const logout = function* (action) {
  if (action.payload.error && action.payload.error === 'Unauthorized') {
    yield put(actions.auth.uiActions.logout());
  }
};

export const logoutSaga = function* () {
  yield takeLatest(actions.auth.apiActionTypes.LOGIN.FAILURE, logout);
};

// Bootstrap
const bootstrap = function* () {
  const token = yield select(selectors.auth.tokenSelector);
  const user = yield select(selectors.auth.userSelector);
  yield call(appStartApiCall, {});
};

export const bootstrapSaga = function* () {
  yield takeLatest(actions.auth.apiActionTypes.LOGIN.SUCCESS, bootstrap);
};

// SYNC

const getSyncData = function* (action) {
  const token = yield select(selectors.auth.tokenSelector);
  yield call(getSyncDataApiCall, { token });
};

export const getSyncSaga = function* () {
  yield takeLatest(actions.sync.uiActionTypes.GET_SYNC_DATA, getSyncData);
};

// AUDITS

const getAuditsData = function* (action) {
  const token = yield select(selectors.auth.tokenSelector);
  yield call(getAuditsDataApiCall, { token, query: action.payload.query });
};

export const getAuditsSaga = function* () {
  yield takeLatest(actions.audits.uiActionTypes.GET_AUDITS_DATA, getAuditsData);
};

// BRANDS

const getBrands = function* (action) {
  const token = yield select(selectors.auth.tokenSelector);
  yield call(getBrandsApiCall, { token });
};

export const getBrandsSaga = function* () {
  yield takeLatest(actions.brands.uiActionTypes.GET_BRANDS, getBrands);
};

const createBrand = function* (action) {
  const { brand, name } = action.payload;
  const token = yield select(selectors.auth.tokenSelector);
  const brandCreate = {
    ...brand,
    name,
  };
  yield call(createBrandApiCall, { token, brand: brandCreate });
};

export const createBrandSaga = function* () {
  yield takeLatest(actions.brands.uiActionTypes.CREATE_BRAND, createBrand);
};

const updateBrand = function* (action) {
  const token = yield select(selectors.auth.tokenSelector);
  const { update: brandUpdate, name } = action.payload;
  yield call(updateBrandApiCall, { token, name, update: brandUpdate });
};

export const updateBrandSaga = function* () {
  yield takeLatest(actions.brands.uiActionTypes.UPDATE_BRAND, updateBrand);
};

const deleteBrand = function* (action) {
  const token = yield select(selectors.auth.tokenSelector);
  const { name } = action.payload;
  yield call(deleteBrandApiCall, { token, name });
};

export const deleteBrandSaga = function* () {
  yield takeLatest(actions.brands.uiActionTypes.DELETE_BRAND, deleteBrand);
};

const uploadGameZip = function* (action) {
  const { file } = action.payload;
  const fileName = path.basename(file.name, path.extname(file.name));
  const token = yield select(selectors.auth.tokenSelector);
  const uploads = yield call(uploadFileApiCall, {
    token,
    file,
    path: 'assets/games/uploads',
  });
};

export const uploadGameZipSaga = function* () {
  yield takeEvery(actions.media.uiActionTypes.UPLOAD_GAME_ZIP, uploadGameZip);
};

const uploadPreview = function* (action) {
  const {
    file, uploadPath = 'assets/previews', uploadKey, uploadType,
  } = action.payload;
  const fileName = path.basename(file.name, path.extname(file.name));
  const token = yield select(selectors.auth.tokenSelector);
  const uploads = yield call(uploadFileApiCall, {
    token,
    file,
    path: uploadPath,
    uploadKey,
    uploadType,
  });
};

export const uploadPreviewSaga = function* () {
  yield takeEvery(actions.media.uiActionTypes.UPLOAD_PREVIEW, uploadPreview);
};
