import React from 'react';

type CheckboxProps = {
  checked: boolean,
  onChange: void,
  label: string,
  id: string,
  onChange: void,
};

const Checkbox = ({ checked, onChange, label, id }: CheckboxProps) => (
  <div className="form-check">
    <input
      id={id}
      type="checkbox"
      checked={checked}
      className="form-check-input"
      onChange={onChange}
    />
    {label && <label htmlFor={id}>{label}</label>}
  </div>
);
export default Checkbox;
