import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import SyncTable from '../../components/SyncTable';

const SyncsTableTableContainer = props => <SyncTable {...props} />;

const mapStateToProps = state => ({
  syncData: selectors.sync.syncDataSelector(state),
});

const mapDispatchToProps = dispatch => ({
  showModal: bindActionCreators(actions.app.uiActions.showModal, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SyncsTableTableContainer);
