import React, { Component } from 'react';
import classnames from 'classnames';

import styles from './Overlay.scss';

class ModalOverlay extends Component {
  componentDidMount() {
    document.body.classList.add('overflow-hidden');
  }

  componentWillUnmount() {
    document.body.classList.remove('overflow-hidden');
  }

  render() {
    const {
      title, children, className, onClose,
    } = this.props;

    return (
      <div className={className}>
        <div className={styles.overlay} />

        <div className={`form-panel-scroll ${styles.panel}`}>
          <button onClick={onClose} className={styles.modalCloseButton}>
            <i className="fa fa-times" />
          </button>
          <div className={styles.panelContent}>
            {title && <h2>{title}</h2>}
            <div className={classnames(styles.form, 'clearfix')}>{children}</div>
          </div>
          <div className={styles.panelPadding} />
        </div>
      </div>
    );
  }
}

export default ModalOverlay;
