import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../styles/theme';

class Login extends PureComponent {
  state = {
    loading: false,
    userIdError: false,
    passwordError: false,
    userId: '',
    password: '',
    errorText: '',
  };

  componentWillMount() {
    document.title = 'Studio 100 admin - login';
  }

  setUserName(userId) {
    const state = { userId, loading: false, userIdError: false };
    this.setState(state);
  }

  setPassword(password) {
    const state = { password, loading: false, passwordError: false };
    this.setState(state);
  }

  componentWillReceiveProps(nextProps) {
    clearTimeout(this.loadingTimeout);
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    clearTimeout(this.loadingTimeout);
  }

  handleOnSubmit = (event) => {
    event.preventDefault();
    const { userId, password } = this.state;
    if (userId.length > 0 && password.length > 0) {
      this.setState({ loading: true });
      this.loadingTimeout = setTimeout(() => {
        this.setState({
          loading: false,
          errorText: 'There seems to be something wrong, please try again later!',
        });
      }, 10000);
      return this.props.login({
        userId,
        password,
        role: 'admin',
      });
    }
    if (!userId.length > 0) {
      return this.setState({ userIdError: true });
    }
    if (!password.length > 0) {
      return this.setState({ passwordError: true });
    }
  };

  showLoginError() {
    if (this.props.loginError) {
      return <div style={styles.loginError}>Please provide valid user credentials!</div>;
    }
    if (this.state.errorText) {
      return <div style={styles.loginError}>{this.state.errorText}</div>;
    }
    return null;
  }

  render() {
    const userIdInputStyle = this.state.userIdError ? styles.inputError : {};
    const passwordInputStyle = this.state.passwordError ? styles.inputError : {};
    return (
      <div style={styles.loginContainer}>
        <form id="login-form" onSubmit={this.handleOnSubmit}>
          <div style={styles.loginFormContent}>
            <div style={styles.loginTitleContainer}>
              <div style={styles.loginTitle}>
                <img src="logo.png" alt="studio 100 logo" />
              </div>
            </div>
            <div style={styles.formField}>
              <input
                type="text"
                placeholder="Username"
                style={{
                  ...styles.inputField, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, ...userIdInputStyle,
                }}
                onChange={e => this.setUserName(e.target.value)}
              />
            </div>
            <div style={styles.formField}>
              <input
                type="password"
                placeholder="Password"
                style={{
                  ...styles.inputField, borderTopLeftRadius: 0, borderTopRightRadius: 0, ...passwordInputStyle,
                }}
                onChange={e => this.setPassword(e.target.value)}
              />
            </div>
            <div style={styles.loginButtonContainer}>
              <button style={styles.loginButton} id="login-button" type="submit">
                {this.state.loading && <span>loading...</span>}
                {!this.state.loading && <span>Login</span>}
              </button>
            </div>
            <div style={styles.errorContainer}>{this.showLoginError()}</div>
          </div>
        </form>
      </div>
    );
  }
}

const styles = {
  loginContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  loginTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 60,
  },
  loginTitle: {
    fontSize: 32,
    color: colors.indigo,
  },
  loginSubTitle: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.darkgrey,
    textTransform: 'uppercase',
  },
  loginFormContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 300,
  },
  loginError: {
    fontSize: 14,
    color: colors.red,
    padding: 15,
  },
  inputError: {
    borderColor: colors.red,
  },
  formField: {
    width: '100%',
  },
  inputField: {
    width: '100%',

    display: 'block',
    padding: 10,
    fontSize: 16,
    boxSizing: 'border-box',
    marginBottom: -1,
    lineHeight: 1.5,
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '.25rem',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  },
  loginButtonContainer: {
    width: '100%',
    marginTop: 20,
  },
  loginButton: {
    width: '100%',
    backgroundColor: colors.indigo,
    color: colors.white,
    height: '3em',
    borderRadius: 10,
  },
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  // isAuthenticated: React.PropTypes.bool.isRequired,
  // isLoading: React.PropTypes.bool.isRequired,
  // redirectTo: React.PropTypes.func.isRequired,
  // onLogin: React.PropTypes.func.isRequired,
  // loginError: React.PropTypes.object,
  // entryPoint: React.PropTypes.string,
};

export default Login;
