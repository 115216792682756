import { REHYDRATE } from 'redux-persist';
import { combineReducers } from 'redux';
import actions from '../actions';

const initialStatusState = 'disabled';
const status = (state = initialStatusState, action = {}) => {
  switch (action.type) {
    case actions.auth.apiActionTypes.LOGIN.FAILURE:
    case actions.auth.apiActionTypes.AUTHORIZE.FAILURE:
      return 'failed';
    case actions.auth.apiActionTypes.LOGIN.SUCCESS:
    case actions.auth.apiActionTypes.AUTHORIZE.SUCCESS:
      return 'initialized';
    case actions.auth.uiActionTypes.UNCONFIRM:
      return 'unconfirmed';
    case actions.auth.uiActionTypes.LOGOUT:
      return initialStatusState;
    default:
      return state;
  }
};

const initialIsLoadingState = false;
const isLoading = (state = initialIsLoadingState, action = {}) => {
  switch (action.type) {
    case actions.auth.uiActionTypes.LOGIN:
      return true;
    case actions.auth.apiActionTypes.LOGIN.SUCCESS:
    case actions.auth.apiActionTypes.LOGIN.FAILURE:
      return false;
    case actions.auth.uiActionTypes.LOGOUT:
      return initialIsLoadingState;
    default:
      return state;
  }
};

const initialDataState = {};
const data = (state = initialDataState, action = {}) => {
  switch (action.type) {
    case REHYDRATE: {
      if (action.payload && action.payload.auth) {
        return action.payload.auth.data;
      }
      return state;
    }
    case actions.auth.apiActionTypes.LOGIN.SUCCESS:
      return action.payload.response;
    case actions.auth.uiActionTypes.LOGOUT:
      return initialDataState;
    default:
      return state;
  }
};

const auth = combineReducers({
  status,
  isLoading,
  data,
});

export default auth;
