import React from 'react';
import Table from './Table';
import TableCell from './TableCell';
import TablePlaceholder from './TablePlaceholder';
import TableRow from './TableRow';
import TableHeaders from './TableHeaders';
import TableLoader from './TableLoader';
import PaginationControlBar from './PaginationControlBar';
import { sortItems } from '../../utils/sort';
import { PAGE_SIZE } from '../../config/pagination';

class TableContainer extends React.Component {
  state = {
    page: 1,
    total: PAGE_SIZE,
    sort: null,
    sortDirection: null,
  };

  handlePageChange = page => {
    this.setState({ page });
    if (!this.props.applySort) {
      this.props.onPageChange(page);
    }
    window.scrollTo(0, 0);
  };

  handleCheckBoxCheck = id => {
    const { onCheckboxSelected } = this.props;
    onCheckboxSelected(id);
  };

  handleFilteredChange = prop => {
    this.setState({
      sort: prop['sort-by'],
      sortDirection: prop['sort-direction'],
    });
    if (this.props.onSortChange) {
      this.props.onSortChange(prop['sort-by'], prop['sort-direction']);
    }
  };

  renderCheckbox = item => {
    const { getKey, selection } = this.props;

    const id = getKey ? getKey(item) : item.id;
    return (
      <TableCell className="checkbox">
        <input
          type="checkbox"
          onChange={() => this.handleCheckBoxCheck(id)}
          checked={selection.indexOf(id) > -1}
        />
      </TableCell>
    );
  };

  render() {
    const {
      columns,
      items,
      transformer,
      onEdit,
      onAction,
      actions,
      loading,
      renderActions,
      activeFilters,
      headers,
      paginated,
      withCheckboxes,
      onCheckboxSelected,
      onToggleAll,
      allSelected,
      applySort = true,
      count,
    } = this.props;
    const { total, page, sort, sortDirection } = this.state;
    if (loading) {
      return (
        <TablePlaceholder
          headers={
            <TableHeaders
              columns={headers || columns}
              withCheckboxes={withCheckboxes}
              withActions={onEdit}
            />
          }
          columns={columns ? columns.length + 1 : 5}
        >
          <TableLoader />
        </TablePlaceholder>
      );
    }
    let renderData = applySort
      ? sortItems(sort, sortDirection, items).slice(
          (page - 1) * total,
          total * page,
        )
      : items;

    if (transformer) {
      renderData = renderData.map(transformer);
    }
    return (
      <div>
        {renderData.length ? (
          <Table
            pagination={
              paginated && (
                <PaginationControlBar
                  totalItems={count || items.length}
                  activePage={+page}
                  perPage={+total}
                  translations={{
                    gap: '..',
                    perPage: 'per page',
                    of: 'from',
                    previous: 'Previous',
                    next: 'Next',
                    goto: 'Go to',
                  }}
                  onPageChanged={this.handlePageChange}
                />
              )
            }
          >
            <thead>
              <TableHeaders
                onFiltersChanged={this.handleFilteredChange}
                activeFilters={{
                  'sort-by': sort,
                  'sort-direction': sortDirection,
                }}
                columns={columns}
                withActions={onEdit}
                withCheckboxes={withCheckboxes}
                onToggleAll={onToggleAll}
                allSelected={allSelected}
              />
            </thead>
            <tbody>
              {items &&
                columns &&
                renderData.map(item => (
                  <TableRow
                    key={`table-row-${item.key
                      || (item.original ? item.original.id : item.id)}-${item.updatedAt}`}
                  >
                    {withCheckboxes && this.renderCheckbox(item)}
                    {columns.map(col => {
                      const keySuffix = 'prop' in col ? col.prop : col.action;
                      if (col.action) {
                        const show = col.show ? col.show(item) : true;
                        if (!show) {
                          return (
                            <TableCell
                              key={`table-row-${item.id}-${keySuffix}`}
                            >
                              {col.noShowLabel || '-'}
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell key={`table-row-${item.id}-${keySuffix}`}>
                            <button
                              onClick={e => onAction(col.action, { ...item })}
                              className="btn"
                            >
                              <i className="fa fa-edit" />
                              {col.button_label}
                            </button>
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={`table-row-${item.id}-${col.prop}`}>
                          {item[col.prop || col]}
                        </TableCell>
                      );
                    })}
                    <TableCell className="multiple-actions">
                      {onEdit && (
                        <button onClick={e => onEdit(e, item)} className="btn">
                          <i className="fa fa-edit" />
                          edit
                        </button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </tbody>
          </Table>
        ) : (
          <TablePlaceholder
            headers={<TableHeaders columns={columns} />}
            columns={columns ? columns.length + 1 : 5}
          >
            No data
          </TablePlaceholder>
        )}
      </div>
    );
  }
}

export default TableContainer;
