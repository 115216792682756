import React from 'react';

const TableHeaderCell = ({ children, ...props }) => <th {...props}>{children}</th>;
TableHeaderCell.Sortable = ({
  direction, onClick, className, children,
}) => (
  <TableHeaderCell className={`${className} sortable`} onClick={onClick}>
    {children}
    {direction && <i className="direction-icon">{direction === 'asc' ? '↑' : '↓'}</i>}
  </TableHeaderCell>
);
export default TableHeaderCell;
