import qs from 'qs';
import config from '../../config';

const queryStringify = query => qs.stringify(query);

const apiRequest = function (url, options) {
  return fetch(url, options)
    .then(response => response
      .json()
      .then(json => ({ json, response }))
      .catch(err => Promise.reject({
        html: response._bodyText,
        status: response.status,
        statusText: response.statusText,
        url: response.url,
      })))
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
    .then(response => ({ response }))
    .catch(error => error);
};

export const loginUser = (loginData) => {
  const url = `${config.API_ROOT}/admin/login`;
  const options = {
    method: 'POST',
    mode: 'cors',
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(loginData),
  };
  return apiRequest(url, options);
};

export const appStart = ({ token }) => {
  const url = `${config.API_ROOT}/app`;
  const options = {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      // 'Accept': 'application/json',
      // 'Content-Type': 'application/json',
    }),
  };
  return apiRequest(url, options);
};

export const uploadFile = ({
  token, file, path, uploadKey, uploadType,
}) => {
  const url = `${config.API_ROOT}/admin/upload`;
  const body = new FormData();
  body.append('file', file);
  const options = {
    method: 'POST',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'X-Upload-Path': path,
      ...(uploadKey ? { 'X-Upload-Key': uploadKey } : {}),
      ...(uploadType ? { 'X-Upload-Type': uploadType } : {}),
    }),
    body,
  };
  return apiRequest(url, options);
};

export const getMedia = ({ token, query, proximus }) => {
  const url = `${config.API_ROOT}/admin/media?${queryStringify(query)}`;
  const options = {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  return apiRequest(url, options);
};

export const getMediaItem = ({ token, id, proximus }) => {
  const url = `${config.API_ROOT}/media/${id}`;
  const options = {
    method: 'POST',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({ proximus }),
  };
  return apiRequest(url, options);
};

export const findGameItem = ({ token, gameId }) => {
  const url = `${config.API_ROOT}/admin/game/${gameId}`;
  const options = {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  return apiRequest(url, options);
};

export const searchMedia = ({ token, query, text }) => {
  const url = `${config.API_ROOT}/media/search?${qs.stringify(query)}`;
  const options = {
    method: 'POST',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({ text }),
  };
  return apiRequest(url, options);
};

export const createMediaItem = ({ token, item }) => {
  const url = `${config.API_ROOT}/admin/media`;
  const options = {
    method: 'POST',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(item),
  };
  return apiRequest(url, options);
};

export const updateMediaItem = ({ token, id, update }) => {
  const url = `${config.API_ROOT}/admin/media/${id}`;
  const options = {
    method: 'PUT',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(update),
  };
  return apiRequest(url, options);
};

export const getGames = ({ token }) => getMedia({ token, category: 'games' });

export const getSyncData = ({ token }) => {
  const url = `${config.API_ROOT}/admin/sync`;
  const options = {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  return apiRequest(url, options);
};

export const getAuditsData = ({ token, query }) => {
  const url = `${config.API_ROOT}/admin/audits`;
  const options = {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  return apiRequest(`${url}?${queryStringify(query)}`, options);
};

export const getBrands = ({ token }) => {
  const url = `${config.API_ROOT}/admin/brands`;
  const options = {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  return apiRequest(url, options);
};

export const createBrand = ({ token, brand }) => {
  const url = `${config.API_ROOT}/admin/brands`;
  const options = {
    method: 'POST',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(brand),
  };
  return apiRequest(url, options);
};

export const updateBrand = ({ token, name, update }) => {
  const url = `${config.API_ROOT}/admin/brands/${encodeURIComponent(name)}`;
  const options = {
    method: 'PUT',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(update),
  };
  return apiRequest(url, options);
};

export const deleteBrand = ({ token, name }) => {
  const url = `${config.API_ROOT}/admin/brands/${encodeURIComponent(name)}`;
  const options = {
    method: 'DELETE',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  return apiRequest(url, options);
};

export const getCampaigns = ({ token }) => {
  const url = `${config.API_ROOT}/admin/campaigns`;
  const options = {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  return apiRequest(url, options);
};

export const createCampaign = ({ token, campaign }) => {
  const url = `${config.API_ROOT}/admin/campaigns`;
  const options = {
    method: 'POST',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(campaign),
  };
  return apiRequest(url, options);
};

export const deleteCampaign = ({ token, id }) => {
  const url = `${config.API_ROOT}/admin/campaigns/${id}`;
  const options = {
    method: 'DELETE',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  return apiRequest(url, options);
};

export const updateCampaign = ({ token, id, campaign }) => {
  const url = `${config.API_ROOT}/admin/campaigns/${id}`;
  const options = {
    method: 'PUT',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(campaign),
  };
  return apiRequest(url, options);
};

export const getCampaign = ({ token, id }) => {
  const url = `${config.API_ROOT}/admin/campaigns/${id}`;
  const options = {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  return apiRequest(url, options);
};

export const searchVoucher = ({ token, id, code }) => {
  const url = `${config.API_ROOT}/admin/campaigns/${id}/voucher/${code}`;
  const options = {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  return apiRequest(url, options);
};
export const activeVouchers = ({ token }) => {
  const url = `${config.API_ROOT}/admin/vouchers/active`;
  const options = {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  return apiRequest(url, options);
};
// Seasons
export const getSeasons = ({ token, query }) => {
  const url = `${config.API_ROOT}/admin/seasons?${queryStringify(query)}`;
  const options = {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  return apiRequest(url, options);
};

export const createSeason = ({ token, season }) => {
  const url = `${config.API_ROOT}/seasons`;
  const options = {
    method: 'POST',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(season),
  };
  return apiRequest(url, options);
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
      if ((encoded.length % 4) > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = error => reject(error);
  });
}

export const updateSeason = async ({ token, id, season }) => {
  const url = `${config.API_ROOT}/seasons/${id}`;
  const body = season;
  if (body.upload) {
    body.upload.file = await getBase64(body.upload.file);
  }

  const options = {
    method: 'PUT',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(body),
  };
  return apiRequest(url, options);
};

export const deleteSeason = ({ token, id }) => {
  const url = `${config.API_ROOT}/seasons/${id}`;
  const options = {
    method: 'DELETE',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  return apiRequest(url, options);
};

export const addAllSeasons = ({ token }) => {
  const url = `${config.API_ROOT}/admin/seasons`;
  const options = {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  return apiRequest(url, options);
};


// SERIES

export const getSeries = ({ token, query }) => {
  const url = `${config.API_ROOT}/admin/series?${queryStringify(query)}`;
  const options = {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  return apiRequest(url, options);
};

export const createSerie = ({ token, serie }) => {
  const url = `${config.API_ROOT}/admin/series`;
  const options = {
    method: 'POST',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(serie),
  };
  return apiRequest(url, options);
};


export const updateSerie = async ({ token, id, serie }) => {
  const url = `${config.API_ROOT}/admin/series/${id}`;

  const options = {
    method: 'PUT',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(serie),
  };
  return apiRequest(url, options);
};

export const deleteSerie = ({ token, id }) => {
  const url = `${config.API_ROOT}/admin/series/${id}`;
  const options = {
    method: 'DELETE',
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  return apiRequest(url, options);
};
