import { createSelector } from 'reselect';

export const isLoadingSelector = createSelector(
  state => state.media.isLoading,
  isLoading => isLoading,
);

export const isSavingSelector = createSelector(
  state => state.media.isSaving,
  isSaving => isSaving,
);

export const mediaSelector = createSelector(
  state => state.media.data,
  media => media,
);

export const playlistMediaSelector = createSelector(
  state => state.media.playlistData,
  media => media,
);

export const bulkProgressSelector = createSelector(
  state => state.media.bulkProgress,
  bulkProgress => bulkProgress,
);

export const isExportLoadingSelector = createSelector(
  state => state.media.isExportLoading,
  isExportLoading => isExportLoading,
);
