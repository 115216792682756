import React from 'react';
import classnames from 'classnames';
import styles from './Table.scss';

const Table = ({
  children, striped = false, className, pagination, ...props
}) => (
  <div className={classnames(styles.root, 'table-responsive')}>
    <table className={classnames('table', { 'table-striped': striped }, className)} {...props}>
      {children}
    </table>
    {pagination}
  </div>
);

export default Table;
