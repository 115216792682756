import React, { PureComponent } from 'react';
import seriesTableConfig, { seriesBroadcastTags, seriesLanguages } from '../config/tables/series';
import Table from './Table';
import FilterWrapper from './Filters/Wrapper';
import Search from './Filters/Search';
import Select from './Filters/Select';
import { inString } from '../utils/search';
import { PAGE_SIZE } from '../config/pagination';

class SeriesTable extends PureComponent {
  state = {
    search: '',
    broadcastTag: null,
    language: null,
    page: 1,
    size: PAGE_SIZE,
  };

  componentDidMount() {
    this.props.getItems({});
  }

  handleLanguageChange = (language) => {
    this.setState({ language });
  };

  handleBroadcastTagChange = (broadcastTag) => {
    this.setState({ broadcastTag });
  };

  handleTargetChange = (target) => {
    this.setState({ target });
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    this.setState({ search: value });
  };

  getItems = () => {
    const { data } = this.props;
    const { search, language, broadcastTag } = this.state;
    if ((search && search.length) || language || broadcastTag) {
      return data.filter((item) => {
        if (language && item.language !== language) {
          return false;
        }
        if (broadcastTag && item.broadcastTag !== broadcastTag) {
          return false;
        }
        if (inString(item.title, search)) {
          return true;
        }
        if (inString(item.editorialTitle, search)) {
          return true;
        }
        if (inString(item.brand, search)) {
          return true;
        }
        return false;
      });
    }
    return data;
  };

  handleButtonClick = (action, data) => {
    const { showModal } = this.props;
    showModal({ name: 'Serie', item: data });
  };

  render() {
    const { loading } = this.props;
    const { search, language, broadcastTag } = this.state;

    return (
      <div>
        <FilterWrapper>
          <Search
            value={search}
            onChange={this.handleSearchChange}
            id="seasons-search"
            placeholder="search"
            label="Search"
          />
          <Select
            value={language}
            onChange={this.handleLanguageChange}
            id="language-select"
            items={seriesLanguages}
            label="Language"
          />
          <Select
            value={broadcastTag}
            onChange={this.handleBroadcastTagChange}
            id="broadcastTag-select"
            items={seriesBroadcastTags}
            label="Tag"
          />
        </FilterWrapper>
        <Table
          items={this.getItems()}
          loading={loading}
          onAction={this.handleButtonClick}
          {...seriesTableConfig}
          paginated
        />
      </div>
    );
  }
}

export default SeriesTable;
