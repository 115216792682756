import React, { PureComponent } from 'react';
import AuditsTableContainer from '../containers/components/AuditsTable';
import LayoutContainer from '../containers/components/Layout';

type AuditsScreenProps = {
  getAuditsData: void,
};

class AuditsScreen extends PureComponent<AuditsScreenProps> {
  render() {
    return (
      <LayoutContainer title="Audits">
        <AuditsTableContainer />
      </LayoutContainer>
    );
  }
}

export default AuditsScreen;
