import * as auth from './auth';
import * as app from './app';
import * as errors from './errors';
import * as media from './media';
import * as sync from './sync';
import * as audits from './audits';
import * as brands from './brands';
import * as campaigns from './campaigns';
import * as seasons from './seasons';
import * as series from './series';

export default {
  auth,
  app,
  errors,
  media,
  sync,
  audits,
  brands,
  campaigns,
  seasons,
  series,
};
