import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import { SeriesScreen } from '../../screens';

const SeriesScreenContainer = props => <SeriesScreen {...props} />;

const mapStateToProps = state => ({
  series: selectors.series.seriesSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getSeries: bindActionCreators(actions.series.uiActions.getSeries, dispatch),
  deleteSerie: bindActionCreators(actions.series.uiActions.deleteSerie, dispatch),
  showModal: bindActionCreators(actions.app.uiActions.showModal, dispatch),
  updateItem: bindActionCreators(actions.series.uiActions.updateSerie, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SeriesScreenContainer);
