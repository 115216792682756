import React from 'react';
import MediaToggleContainer from '../../containers/components/MediaToggle';
import { formatDate } from '../../utils/date';

export default {
  paginated: true,
  columns: [
    {
      prop: 'preview',
      label: 'Preview',
    },
    {
      prop: 'id',
      label: 'Id',
      sortable: true,
    },
    {
      prop: 'title',
      label: 'Title',
      sortable: true,
    },
    {
      prop: 'category',
      label: 'Category',
      sortable: true,
    },
    {
      prop: 'subCategory',
      label: 'Sub Category',
      sortable: true,
    },
    {
      prop: 'brand',
      label: 'Brand',
      sortable: true,
    },
    {
      prop: 'subBrand',
      label: 'sub Brand',
      sortable: true,
    },
    {
      prop: 'language',
      label: 'Language',
      sortable: true,
    },
    {
      prop: 'seasonTitle',
      label: 'Season Title',
    },
    {
      prop: 'publishDate',
      label: 'Publish date',
      sortable: true,
    },
    {
      prop: 'published',
      label: 'Published',
      sortable: true,
    },
    {
      prop: 'locked',
      label: 'Locked',
      sortable: true,
    },
    {
      prop: 'customLabel',
      label: 'Custom label',
    },
  ],
  transformer: (row) => {
    const href = ((row.preview || {}).small || {}).filename || '';
    let { title } = row;
    if (row.editorialTitle && row.editorialTitle.length) {
      title = (
        <dl>
          <dd>{row.editorialTitle}</dd>
          <dd>
            <strong>Original: </strong>
            {row.title}
          </dd>
        </dl>
      );
    }
    return {
      ...row,
      title,
      id: <span className="table-col-id">{row.gameId || row.id}</span>,
      publishDate: formatDate(row.publishDate),
      published: (
        <MediaToggleContainer
          prop="published"
          checked={row.published}
          id={row.id}
        />
      ),
      locked: (
        <MediaToggleContainer prop="locked" checked={row.locked} id={row.id} />
      ),
      customLabel: row.customLabel && row.customLabel.value,
      preview: <img src={href} style={{ width: 80 }} />,
      previewUrl: href,
      original: row,
    };
  },
};
const mediaLanguages = [
  { value: 'NL', label: 'NL' },
  { value: 'FR', label: 'FR' },
];

export { mediaLanguages };
