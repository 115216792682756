import { createSelector } from 'reselect';

export const isLoadingSelector = createSelector(
  state => state.campaigns.isLoading,
  isLoading => isLoading,
);

export const isSavingSelector = createSelector(
  state => state.campaigns.isSaving,
  isSaving => isSaving,
);

export const campaignsSelector = createSelector(
  state => state.campaigns.data,
  campaigns => campaigns,
);

export const voucherDetailSelector = createSelector(
  state => state.campaigns.voucherDetail,
  voucherDetail => voucherDetail,
);
