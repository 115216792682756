import React from 'react';
import ReactSelect from 'react-select';
import classnames from 'classnames';
import styles from './Select.scss';

const Select = ({
  items, value, onChange, id, label, withNone = true,
}) => {
  const none = { value: null, label: 'Geen' };
  const selected = (items || []).find(i => i.value === value);
  return (
    <div className={classnames(styles.root)}>
      <label htmlFor={id}>{label}</label>
      <ReactSelect
        id={id}
        options={[selected && withNone ? none : null, ...(items || [])].filter(o => o)}
        value={selected}
        onChange={item => onChange(item.value)}
      />
    </div>
  );
};

export default Select;
