import React from 'react';

type InputProps = {
  value: string,
  onChange: void,
  placeholder: string,
  label: string,
  id: string,
  onChange: void,
  type: string;
};

const Input = ({
  value, onChange, placeholder, label, id, type = 'text', ...props
}: InputProps) => (
  <div className="form-group">
    <label htmlFor={id}>{label}</label>
    <input
      id={id}
      type={type}
      value={value}
      className="form-control"
      onChange={onChange}
      placeholder={placeholder}
      {...props}
    />
  </div>
);
export default Input;
