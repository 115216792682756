/* eslint-disable no-restricted-syntax */
/* eslint-disable func-names */
import { delay } from 'redux-saga';
import {
  take,
  takeLatest,
  takeEvery,
  put,
  call,
  fork,
  select,
  all,
} from 'redux-saga/effects';
import { apiCall, downloadCsv } from './util';
import actions from '../actions';
import selectors from '../selectors';
import { api } from '../services';
import { getMediaMutationStateFromFieldDependencies } from '../../components/modals/utils/media';
// bind api calls to actions

const createMediaItemApiCall = apiCall.bind(
  null,
  actions.media.apiActions.createItem,
  api.createMediaItem,
);
const updateMediaItemApiCall = apiCall.bind(
  null,
  actions.media.apiActions.updateItem,
  api.updateMediaItem,
);
const getMediaApiCall = apiCall.bind(
  null,
  actions.media.apiActions.getMedia,
  api.getMedia,
);
const getExportMediaApiCall = apiCall.bind(
  null,
  actions.media.apiActions.getExportMedia,
  api.getMedia,
);
const getPlaylistMediaApiCall = apiCall.bind(
  null,
  actions.media.apiActions.getPlaylistMedia,
  api.getMedia,
);

// Media

const createMediaItem = function* (action) {
  const token = yield select(selectors.auth.tokenSelector);
  const { item } = action.payload;
  yield call(createMediaItemApiCall, { token, item });
};

const getMediaItems = function* (action, props) {
  const token = yield select(selectors.auth.tokenSelector);
  yield call(getMediaApiCall, { token, query: action.payload.query });
};

export const getMediaItemsSaga = function* () {
  yield takeLatest(actions.media.uiActionTypes.GET_MEDIA, getMediaItems);
};

const getPlaylistMediaItems = function* (action, props) {
  const token = yield select(selectors.auth.tokenSelector);
  yield call(getPlaylistMediaApiCall, { token, query: action.payload.query });
};

export const getPlaylistMediaItemsSaga = function* () {
  yield takeLatest(actions.media.uiActionTypes.GET_PLAYLIST_MEDIA, getPlaylistMediaItems);
};

export const createMediaItemSaga = function* () {
  yield takeLatest(actions.media.uiActionTypes.CREATE_ITEM, createMediaItem);
};

const updateMediaItem = function* (action) {
  const token = yield select(selectors.auth.tokenSelector);
  const { id, update } = action.payload;
  yield call(updateMediaItemApiCall, { token, id, update });
};
const deleteMediaItem = function* (action) {
  const token = yield select(selectors.auth.tokenSelector);
  const { id } = action.payload;
  yield call(updateMediaItemApiCall, { token, id, update: { deleted: true } });
};
export const updateMediaItemSaga = function* () {
  yield takeLatest(actions.media.uiActionTypes.UPDATE_ITEM, updateMediaItem);
};

export const deleteMediaItemSaga = function* () {
  yield takeLatest(actions.media.uiActionTypes.DELETE_ITEM, deleteMediaItem);
};

const bulkUpdateMediaItems = function* (action) {
  let progress = 0.0;
  const token = yield select(selectors.auth.tokenSelector);
  const media = yield select(selectors.media.mediaSelector);
  const {
    selection, action: updateKey, value: updateValue, actionConfig,
  } = action.payload;
  yield put(actions.media.uiActions.setBulkProgress(0, true));
  for (const id of selection) {
    let update = {
      [`${updateKey}`]: updateValue,
    };

    if (actionConfig && actionConfig.getAugmentedUpdate) {
      update = actionConfig.getAugmentedUpdate({
        update, updateKey, updateValue, id,
      });
    }

    const mediaItem = media.items.find(item => item.id === id);

    yield call(updateMediaItemApiCall, { token, id, update: getMediaMutationStateFromFieldDependencies(mediaItem, update) });
    progress += 100 / selection.length;
    yield put(
      actions.media.uiActions.setBulkProgress(Math.round(progress), true),
    );
    yield delay(100);
  }
  yield put(actions.media.uiActions.setBulkProgress(0, false));
};

export const bulkUpdateMediaItemsSaga = function* () {
  yield takeLatest(
    actions.media.uiActionTypes.BULK_UPDATE_ITEMS,
    bulkUpdateMediaItems,
  );
};

const formatMediaForExport = (media) => {
  const formattedMedia = media.map(med => [
    med.id,
    med.title ? med.title.replace('|', '@@') : '',
    med.category,
    med.subCategory,
    med.brand,
    med.locked ? true : false,
    med.published ? true : false,
    med.publishDate,
    med.language,
    med.duration,
    med.seasonId || '',
    med.seasonTitle || '',
  ]);

  return formattedMedia;
};

const exportMedia = function* (action) {
  const token = yield select(selectors.auth.tokenSelector);
  const media = yield call(getExportMediaApiCall, { token, query: action.payload.query });

  if (media.items && media.items.length) {
    downloadCsv(
      media.items,
      [
        'catId',
        'title',
        'category',
        'subCategory',
        'brand',
        'locked',
        'published',
        'publishDate',
        'language',
        'duration',
        'seasonId',
        'seasonTitle',
      ],
      'media.csv',
      formatMediaForExport,
    );
  } else {
    alert('No media found to download...');
  }
};

export const exportMediaSaga = function* () {
  yield takeLatest(actions.media.uiActionTypes.EXPORT_MEDIA, exportMedia);
};
