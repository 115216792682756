import { takeLatest, call, select } from 'redux-saga/effects';

import { apiCall, downloadCsv } from './util';
import actions from '../actions';
import selectors from '../selectors';
import { api } from '../services';

// bind api calls to actions

const getCampaignsApiCall = apiCall.bind(
  null,
  actions.campaigns.apiActions.getCampaigns,
  api.getCampaigns,
);
const getCampaignApiCall = apiCall.bind(
  null,
  actions.campaigns.apiActions.getCampaign,
  api.getCampaign,
);
const createCampaignApiCall = apiCall.bind(
  null,
  actions.campaigns.apiActions.createCampaign,
  api.createCampaign,
);
const deleteCampaignApiCall = apiCall.bind(
  null,
  actions.campaigns.apiActions.deleteCampaign,
  api.deleteCampaign,
);
const updateCampaignApiCall = apiCall.bind(
  null,
  actions.campaigns.apiActions.updateCampaign,
  api.updateCampaign,
);
const searchVoucherApiCall = apiCall.bind(
  null,
  actions.campaigns.apiActions.searchVoucher,
  api.searchVoucher,
);

const getActiveVouchers = apiCall.bind(
  null,
  actions.campaigns.apiActions.getActiveVouchers,
  api.activeVouchers,
);

const getCampaigns = function* (action) {
  const token = yield select(selectors.auth.tokenSelector);
  yield call(getCampaignsApiCall, { token });
};

export const getCampaignsSaga = function* () {
  yield takeLatest(actions.campaigns.uiActionTypes.GET_CAMPAIGNS, getCampaigns);
};

const createCampaign = function* (action) {
  const { campaign = {}, id } = action.payload;
  const token = yield select(selectors.auth.tokenSelector);
  yield call(createCampaignApiCall, { token, campaign });
};

export const createCampaignSaga = function* () {
  yield takeLatest(actions.campaigns.uiActionTypes.CREATE_CAMPAIGN, createCampaign);
};

const deleteCampaign = function* (action) {
  const { id } = action.payload;
  const token = yield select(selectors.auth.tokenSelector);
  yield call(deleteCampaignApiCall, { token, id });
};

export const deleteCampaignSaga = function* () {
  yield takeLatest(actions.campaigns.uiActionTypes.DELETE_CAMPAIGN, deleteCampaign);
};

const updateCampaign = function* (action) {
  const { campaign = {}, id } = action.payload;
  const token = yield select(selectors.auth.tokenSelector);
  yield call(updateCampaignApiCall, { token, id, campaign });
};

export const updateCampaignSaga = function* () {
  yield takeLatest(actions.campaigns.uiActionTypes.UPDATE_CAMPAIGN, updateCampaign);
};

const downloadVouchers = function* (action) {
  const { campaign = {} } = action.payload;
  const token = yield select(selectors.auth.tokenSelector);
  const campaignDetail = yield call(getCampaignApiCall, { token, id: campaign.id });
  if (campaignDetail && campaignDetail.vouchers && campaignDetail.vouchers.length > 0) {
    const href = `data:text/csv;charset=utf-8,${encodeURI(campaignDetail.vouchers.join('\n'))}`;
    const link = document.createElement('a');
    link.href = href;
    link.download = 'vouchers.csv';
    const event = document.createEvent('MouseEvents');
    event.initEvent('click');
    link.dispatchEvent(event);
  } else {
    alert('No vouchers found to download...');
  }
};

const voucherTransformer = campaigns => vouchers => vouchers.map((voucher) => {
  const campaign = campaigns.find(c => c.id === voucher.campaignId);
  return [
    voucher.code,
    voucher.userEmail,
    voucher.validationTimestamp,
    campaign ? campaign.name : '',
  ];
});
const exportActiveVouchers = function* () {
  const token = yield select(selectors.auth.tokenSelector);
  const vouchers = yield call(getActiveVouchers, { token });
  const campaigns = yield select(selectors.campaigns.campaignsSelector);

  if (vouchers) {
    downloadCsv(
      vouchers,
      ['code', 'email', 'issued', 'campagn'],
      'vouchers.csv',
      voucherTransformer(campaigns),
    );
  } else {
    alert('No vouchers found to download...');
  }
};

export const exportActiveVouchersSaga = function* () {
  yield takeLatest(actions.campaigns.uiActionTypes.EXPORT_ACTIVE_VOUCHERS, exportActiveVouchers);
};

export const downloadVouchersSaga = function* () {
  yield takeLatest(actions.campaigns.uiActionTypes.DOWNLOAD_VOUCHERS, downloadVouchers);
};

const searchVoucher = function* (action) {
  const { code, id } = action.payload;
  const token = yield select(selectors.auth.tokenSelector);
  yield call(searchVoucherApiCall, { token, id, code });
};

export const searchVoucherSaga = function* () {
  yield takeLatest(actions.campaigns.uiActionTypes.SEARCH_VOUCHER, searchVoucher);
};
