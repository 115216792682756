import { createSelector } from 'reselect';

export const isLoadingSelector = createSelector(
  state => state.brands.isLoading,
  isLoading => isLoading,
);

export const isSavingSelector = createSelector(
  state => state.brands.isSaving,
  isSaving => isSaving,
);

export const brandsSelector = createSelector(
  state => state.brands.data,
  brands => brands,
);
