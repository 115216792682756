import moment from 'moment';
import React from 'react';
import { serieSeasonSortOrders } from '../../utils';
import DefaultModal from './DefaultModal';
import Layout from './Layout';

export default class Serie extends DefaultModal {
  constructor(props) {
    const original = (props.item || {}).original || {};
    super(props);
    this.state = {
      ...this.state,
      id: original.id,
      title: original.title,
      editorialTitle: original.editorialTitle || '',
      poster: original.poster,
      sortOrder: original.sortOrder || serieSeasonSortOrders[0].value,
      bannerImage: original.bannerImage,
      published: original.published || false,
      publishDate: original.publishDate ? moment(original.publishDate) : null,
      featured: original.featured || false,
    };
  }

  componentDidUpdate(newProps) {
    if (this.props.isSaving && !newProps.isSaving) {
      this.props.dismissModal();
    }
  }

  renderModalActions() {
    const { dismissModal, isSaving } = this.props;

    return (
      <Layout.Actions
        disabled={isSaving || this.isProcessingUploads()}
        onCancel={() => dismissModal()}
        submmitting={isSaving}
        onSave={() => this.saveSerie()}
      />
    );
  }

  saveSerie() {
    const {
      item, dismissModal, updateSerie,
    } = this.props;

    if (item && item.original.id) {
      updateSerie(item.original.id, {
        editorialTitle: this.state.editorialTitle === this.state.title ? null : this.state.editorialTitle,
        poster: this.state.poster,
        bannerImage: this.state.bannerImage,
        sortOrder: this.state.sortOrder,
        featured: this.state.featured,
        published: this.state.published,
        publishDate: this.state.publishDate,
      });
    }
    dismissModal();
  }

  render() {
    const { item, uploadPreview } = this.props;

    return (
      <Layout
        title={item.original.title}
        subtitle={(
          <>
            <strong>Id: </strong>
            {item.original.id}
          </>
        )}
      >
        {this.renderCommonFields({ withPublished: true, withFeatured: true })}

        {this.renderProperty('editorialTitle', {
          stateWithPrefix: false,
          label: 'Editorial title',
        })}

        <label>Poster</label>
        {this.renderPreviewField(uploadPreview, { uploadPath: 'assets/series/posters', stateField: 'poster' })}

        <label>Banner</label>
        {this.renderPreviewField(uploadPreview, { uploadPath: 'assets/series/banners', stateField: 'bannerImage' })}

        {this.renderSelect('sortOrder', this.state.sortOrder, serieSeasonSortOrders, false)}

        {this.renderModalActions()}
      </Layout>
    );
  }
}
