import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import { CampaignsScreen } from '../../screens';

const CampaignsScreenContainer = props => <CampaignsScreen {...props} />;

const mapStateToProps = state => ({
  campaigns: selectors.campaigns.campaignsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getCampaigns: bindActionCreators(actions.campaigns.uiActions.getCampaigns, dispatch),
  showModal: bindActionCreators(actions.app.uiActions.showModal, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignsScreenContainer);
