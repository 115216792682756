import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import CampaignsTable from '../../components/CampaignsTable';

const CampaignsTableTableContainer = props => <CampaignsTable {...props} />;

const mapStateToProps = state => ({
  campaigns: selectors.campaigns.campaignsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  showModal: bindActionCreators(actions.app.uiActions.showModal, dispatch),
  exportActiveVouchers: bindActionCreators(
    actions.campaigns.uiActions.exportActiveVouchers,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignsTableTableContainer);
