import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import SeasonsTable from '../../components/SeasonsTable';

const SeasonsTableTableContainer = props => <SeasonsTable {...props} />;

const mapStateToProps = state => ({
  data: selectors.seasons.seasonsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getItems: bindActionCreators(actions.seasons.uiActions.getSeasons, dispatch),
  deleteSeason: bindActionCreators(actions.seasons.uiActions.deleteSeason, dispatch),
  addAllSeasons: bindActionCreators(actions.seasons.uiActions.addAllSeasons, dispatch),
  showModal: bindActionCreators(actions.app.uiActions.showModal, dispatch),
  updateItem: bindActionCreators(actions.seasons.uiActions.updateSeason, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SeasonsTableTableContainer);
