import { combineReducers } from 'redux';
import actions from '../actions';

const initialSyncState = [];
const data = (state = initialSyncState, action = {}) => {
  switch (action.type) {
    case actions.sync.apiActionTypes.GET_SYNC_DATA.SUCCESS:
      return action.payload.response;
    default:
      return state;
  }
};

const errors = combineReducers({
  data,
});

export default errors;
