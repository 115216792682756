import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import SeriesTable from '../../components/SeriesTable';

const SeriesTableTableContainer = props => <SeriesTable {...props} />;

const mapStateToProps = state => ({
  data: selectors.series.seriesSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getItems: bindActionCreators(actions.series.uiActions.getSeries, dispatch),
  deleteSeason: bindActionCreators(actions.series.uiActions.deleteSerie, dispatch),
  showModal: bindActionCreators(actions.app.uiActions.showModal, dispatch),
  updateItem: bindActionCreators(actions.series.uiActions.updateSerie, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SeriesTableTableContainer);
