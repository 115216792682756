import {
  createUiActionType, createUiAction, createApiActionTypes, createApiActions,
} from './util';

export const uiActionTypes = {
  APP_START: createUiActionType('APP/APP_START'),
  SHOW_MODAL: createUiActionType('APP/SHOW_MODAL'),
  DISMISS_MODAL: createUiActionType('APP/DISMISS_MODAL'),
  SHOW_NOTIFICATION: createUiActionType('APP/SHOW_NOTIFICATION'),
  DISMISS_NOTIFICATION: createUiActionType('APP/DISMISS_NOTIFICATION'),
};

export const uiActions = {
  appStateChange: appState => createUiAction(uiActionTypes.APPSTATE_CHANGE, { appState }),
  connectivityChange: networkStatus => createUiAction(uiActionTypes.CONNECTIVITY_CHANGE, { networkStatus }),
  appStart: params => createUiAction(uiActionTypes.APP_START, params),
  showModal: modal => createUiAction(uiActionTypes.SHOW_MODAL, { modal }),
  dismissModal: modal => createUiAction(uiActionTypes.DISMISS_MODAL, { modal }),
  showNotification: notification => createUiAction(uiActionTypes.SHOW_NOTIFICATION, { notification }),
  dismissNotification: params => createUiAction(uiActionTypes.DISMISS_NOTIFICATION, params),
};

export const apiActionTypes = {
  APP_START: createApiActionTypes('APP/APP_START'),
  UPLOAD_FILE: createApiActionTypes('APP/UPLOAD_FILE'),
};

export const apiActions = {
  appStart: createApiActions(apiActionTypes.APP_START),
  uploadFile: createApiActions(apiActionTypes.UPLOAD_FILE),
};
