import { put, call } from 'redux-saga/effects';

const CSV_DELIMITER = '|';
const apiCall = function*(apiAction, apiFn, args) {
  yield put(apiAction.request(args));
  const { response, error } = yield call(apiFn, args);
  if (response) {
    yield put(apiAction.success(args, response));
  } else {
    yield put(apiAction.failure(args, error));
  }
  return response;
};
const downloadCsv = (data, headers, fileName, transformer = i => i) => {
  const transformedData = transformer(data);

  transformedData.unshift(headers);

  const csvCode = transformedData.map(d => d.join(CSV_DELIMITER)).join('\n');
  const content = encodeURI(csvCode).replace(/#/g, '%23');

  const href = `data:text/csv;charset=utf-8,${content}`;
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName;
  const event = document.createEvent('MouseEvents');
  event.initEvent('click');
  link.dispatchEvent(event);
};
export { downloadCsv, apiCall };

export default apiCall;
