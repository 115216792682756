import { formatDate } from '../../utils/date';
import { getContentId } from '../../utils/sync';

function getIngestResultType(row) {
  return row.type;
}

export default {
  paginated: true,
  columns: [
    {
      prop: 'id',
      label: 'Sync Id',
      sortable: true,
    },
    {
      prop: 'contentId',
      label: 'Content Id',
      sortable: true,
    },
    {
      action: 'event',
      label: 'Source',
      button_label: 'View',
    },
    {
      action: 'metadata',
      label: 'Meta',
      button_label: 'View',
    },
    {
      action: 'transformedMedia',
      label: 'Transform',
      button_label: 'View',
    },
    {
      prop: 'ingestResult',
      label: 'Ingest',
    },
    {
      action: 'error',
      label: 'Error',
      button_label: 'View',
      show: item => item.original && item.original.err,
      noShowLabel: 'No errors',
    },
    {
      prop: 'createdAt',
      label: 'Created',
    },
    {
      prop: 'updatedAt',
      label: 'Updated',
    },
  ],
  transformer: row => ({
    ...row,
    id: `${row.id}`,
    createdAt: formatDate(row.createdAt),
    updatedAt: formatDate(row.updatedAt),
    contentId: getContentId(row),
    ingestResult: getIngestResultType(row),
    original: row,
  }),
};
