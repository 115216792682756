export const dummy = () => {};

// All possible brand targets.
export const brandTargets = ['mix', 'boys', 'girls'];

// Possible brand ages.
export const brandAges = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const serieSeasonSortOrders = [
  { value: 'chronological_desc', label: 'chronological_desc' },
  { value: 'episode_asc', label: 'episode_asc' },
];

// All the different available languages for Studio 100 media content.
export const availableLanguages = ['NL', 'FR'];
