const fixedRange = (lower, upper) => {
  const count = upper - lower;
  return Array(count)
    .join(',')
    .split(',')
    .map((val, i) => lower + i);
};

export default ({
  activePage, pageCount, maxLinks = 7, gap = '..',
}) => {
  // 1   2  3  4   5  6   7
  if (pageCount <= maxLinks) {
    return fixedRange(1, pageCount + 1);
  }

  const maxLinksMiddle = maxLinks - 2;

  // (1) 2  3  4   5  ..  21
  // 1   2  3 (4)  5  ..  21
  if (activePage < maxLinksMiddle) {
    return [...fixedRange(1, maxLinks - 1), gap, pageCount];
  }

  // 1   .. 17 18  19 20  21
  if (activePage >= pageCount - maxLinksMiddle) {
    return [1, gap, ...fixedRange(pageCount - maxLinksMiddle, pageCount + 1)];
  }

  // 1   .. 4  (5) 6  ..  21
  const maxLinksMiddleLower = activePage - Math.floor(maxLinksMiddle / 2);
  const lowerFix = Math.round(maxLinksMiddle / 2) === maxLinksMiddle / 2 ? 1 : 0;
  const maxLinksMiddleUpper = activePage + Math.ceil(maxLinksMiddle / 2);
  const upperFix = fixedRange(maxLinksMiddleLower + lowerFix, maxLinksMiddleUpper).length < maxLinksMiddle ? 1 : 0;
  return [
    1,
    gap,
    ...fixedRange(maxLinksMiddleLower + lowerFix, maxLinksMiddleUpper + upperFix),
    gap,
    pageCount,
  ];
};
