import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import localForage from 'localforage';
import { persistStore, persistCombineReducers } from 'redux-persist';

import reducers from './store/reducers';
import sagas from './store/sagas';
import { AppNavigator } from './containers/navigators';

class Studio100GO extends Component {
  constructor(props) {
    super(props);
    const sagaMiddleware = createSagaMiddleware();
    const middleware = [sagaMiddleware];
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const createStoreWithMiddleware = composeEnhancers(applyMiddleware(...middleware))(createStore);
    localForage.config({
      name: 'Studio100GOAdmin',
      storeName: 'store', // Should be alphanumeric, with underscores.
    });
    const persistConfig = {
      key: 'studio100-admin',
      storage: localForage,
      stateReconciler: (inboundState, originalState, reducedState) => reducedState, // false -> https://github.com/rt2zz/redux-persist/pull/593
      whitelist: ['auth'],
    };
    const rootReducer = persistCombineReducers(persistConfig, reducers);
    this.store = createStoreWithMiddleware(rootReducer, {});
    persistStore(this.store);
    sagaMiddleware.run(sagas);
  }

  render() {
    return (
      <Provider store={this.store}>
        <AppNavigator />
      </Provider>
    );
  }
}

export default Studio100GO;
