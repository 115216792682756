import React from 'react';
import moment from 'moment';

import formatAllBrandByLanguage from '../../utils/brand';
import Select from '../Form/Select';
import Input from '../Form/Input';
import { getMediaItem, getMediaMutationStateFromFieldDependencies } from './utils/media';
import Checkbox from '../Form/Checkbox';
import DatePicker from '../Form/DatePicker';
import ColorInput from '../Form/ColorInput';
import { UploadContainer } from '../../containers/components';

type DefaultModalProps = {
  item: Object,
};

class DefaultModal extends React.Component<DefaultModalProps> {
  constructor(props, defaultProperties = true, type = '') {
    const { item } = props;
    super(props);
    const original = (item || {}).original || {};

    this.type = type;
    this.media = getMediaItem(props);
    if (defaultProperties) {
      this.state = {
        uploads: [],
        editorialTitle: original.editorialTitle || null,
        published: original.published || false,
        locked: original.locked || false,
        newMedia: original.new || false,
        featured: original.featured || false,
        publishDate: original.publishDate ? moment(original.publishDate) : null,
        unlockDate: original.unlockDate ? moment(original.unlockDate) : null,
        customLabelValue: original?.customLabel?.value || null,
        customLabelDetailValue: original?.customLabel?.detailValue || null,
        customLabelColor: {
          hex: original?.customLabel?.color || null,
        },
      };
    } else {
      this.state = {
        uploads: [],
      };
    }
  }


  componentWillReceiveProps(nextProps) {
    const uploads = this.getUploadsFromProps(nextProps);
    const uploadResults = uploads.reduce((r, upload) => {
      if (upload.result) {
        return { ...r, [upload.stateField]: upload.result };
      }
      return r;
    }, {});
    this.setState({ uploads, ...uploadResults });
  }

  setFormValues = (nextValues) => {
    const currentValues = this.state;
    this.setState(getMediaMutationStateFromFieldDependencies(currentValues, nextValues));
  }

  getStateValuesDefaultFields = () => {
    const {
      editorialTitle,
      publishDate,
      published,
      locked,
      newMedia,
      featured,
      unlockDate,
      customLabelValue,
      customLabelDetailValue,
      customLabelColor,
      gameId,
      language = 'nl',
      brand,
    } = this.state;
    return {
      gameId,
      editorialTitle,
      publishDate,
      published,
      locked,
      new: newMedia,
      unlockDate,
      featured,
      brand: formatAllBrandByLanguage(brand, language),
      customLabel: {
        value: customLabelValue || null,
        detailValue: customLabelDetailValue || null,
        color: customLabelColor.hex || null,
      },
    };
  };

  // eslint-disable-next-line class-methods-use-this
  getImageUploadConversions(url, stateField) {
    return {
      small: { filename: url.replace('.original.', '.medium-small.') },
      medium: { filename: url.replace('.original.', '.medium-large.') },
      large: { filename: url.replace('.original.', '.large.') },
    };
  }

  getUploadsFromProps(props) {
    const { uploads } = this.state;

    return uploads.map((upload) => {
      const status = props.uploads?.[upload.id] || {};
      const { loading, failed } = status;
      const { url } = status.upload || {};
      if (url) {
        upload.result = this.getImageUploadConversions(url, upload.stateField);
        upload.isUploading = false;
      } else if (!loading && failed) {
        upload.error = true;
        upload.isUploading = false;
      }

      return upload;
    });
  }

  isProcessingUploads() {
    const { uploads } = this.state;

    return uploads.find(upload => upload.isUploading);
  }

  renderProperty(propName, {
    stateWithPrefix = true, type = 'text', label = propName, inputProps = {},
  } = {}) {
    let stateProp = propName;
    if (stateWithPrefix) {
      stateProp = `${this.type}${propName[0].toUpperCase()}${propName.slice(1)}`;
    }
    const { [stateProp]: stateValue } = this.state;

    return (
      <Input
        value={stateValue}
        label={label.charAt(0).toUpperCase() + label.slice(1)}
        type={type}
        id={`${this.type}-${propName}`}
        onChange={e => this.setFormValues({ [`${stateProp}`]: e.target.value })}
        {...inputProps}
      />
    );
  }

  renderCheckbox(propName, { stateWithPrefix = true, label = propName, ...otherProps } = {}) {
    let stateProp = propName;
    if (stateWithPrefix) {
      stateProp = `${this.type}${propName[0].toUpperCase()}${propName.slice(1)}`;
    }
    const { [stateProp]: stateValue } = this.state;
    return (
      <div {...otherProps}>
        <Checkbox
          checked={stateValue}
          label={label}
          id={`${this.type}-${propName}`}
          onChange={(e) => {
            this.setFormValues({ [`${stateProp}`]: e.target.checked });
          }}
        />
      </div>
    );
  }

  renderColorInput(propName, { stateWithPrefix = true, label = propName, ...otherProps } = {}) {
    let stateProp = propName;
    if (stateWithPrefix) {
      stateProp = `${this.type}${propName[0].toUpperCase()}${propName.slice(1)}`;
    }
    const { [stateProp]: stateValue } = this.state;
    return (
      <div {...otherProps}>
        <ColorInput
          value={stateValue}
          label={label}
          id={`${this.type}-${propName}`}
          onChange={color => this.setFormValues({ [`${stateProp}`]: color })}
        />
      </div>
    );
  }

  renderSelect(propName, selectedValue, listOfPossibleValues, stateWithPrefix = true) {
    let stateProp = propName;
    if (stateWithPrefix) {
      stateProp = `${this.type}${propName}`;
    }
    return (
      <Select
        value={selectedValue}
        items={listOfPossibleValues}
        label={propName}
        id={`${this.type}-${propName}`}
        onChange={value => this.setFormValues({ [stateProp]: value })}
      />
    );
  }

  renderCommonFields({
    withNew,
    withFeatured,
    withCustomLabel,
    withPublished,
    withLocked,
  } = {
    withNew: true, withFeatured: true, withCustomLabel: true, withPublished: true, withLocked: true,
  }) {
    const {
      published, locked, publishDate, unlockDate,
    } = this.state;

    const publishedState = published ? 'published' : 'unpublished';
    const lockedState = (() => {
      if (!locked) {
        return 'unlocked';
      }
      if (locked && unlockDate) {
        return 'locked-until';
      }
      return 'locked';
    })();

    return (
      <div className="mb-3">
        {(withNew || withFeatured)
        && (
          <div className="row">
            <div className="col-md-12">
              <label>Highlights</label>
              <div className="card mb-3">
                <div className="card-body pb-2">
                  <div className="row">
                    {withNew && (
                    <div className="col">
                      {this.renderCheckbox('newMedia', {
                        stateWithPrefix: false,
                        type: 'checkbox',
                        label: 'New',
                      })}
                    </div>
                    )}
                    {withFeatured && (
                    <div className="col">
                      {this.renderCheckbox('featured', {
                        stateWithPrefix: false,
                        type: 'checkbox',
                        label: 'Featured',
                      })}
                    </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

          </div>
        )}
        {withCustomLabel
        && (
          <div className="row">
            <div className="col-md-12">
              <label>
Custom label
                {' '}
                <span className="badge badge-warning">old app</span>
              </label>
              <div className="card mb-3">
                <div className="card-body pb-2">
                  <div className="row">
                    <div className="col-md-6">
                      {this.renderProperty('customLabelValue', {
                        stateWithPrefix: false,
                        label: 'Custom label preview',
                      })}
                    </div>
                    <div className="col-md-6">
                      {this.renderColorInput('customLabelColor', {
                        stateWithPrefix: false,
                        label: 'Custom label color',
                      })}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      {this.renderProperty('customLabelDetailValue', {
                        stateWithPrefix: false,
                        label: 'Custom label detail',
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {withPublished
        && (
          <div className="row">
            <div className="col-md-12">
              <label>Publish</label>
              <div className="card mb-3">
                <div className="card-body pb-2">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="radiosPublished"
                      id="radiosPublished1"
                      value="unpublished"
                      checked={publishedState === 'unpublished'}
                      onChange={(e) => {
                        if (e.target.checked) {
                          this.setFormValues({ publishDate: null, published: false });
                        }
                      }}
                    />
                    <label className="form-check-label" htmlFor="radiosPublished1">
                      Never publish
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="radiosPublished"
                      id="radiosPublished2"
                      value="published"
                      checked={publishedState === 'published'}
                      onChange={(e) => {
                        if (e.target.checked) {
                          this.setFormValues({ publishDate: moment().startOf('minute'), published: true });
                        }
                      }}
                    />
                    <label className="form-check-label" htmlFor="radiosPublished2">
                      Publish after
                      <DatePicker
                        label=""
                        value={publishDate}
                        onChange={(date) => {
                          this.setFormValues({ publishDate: date });
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {withLocked
        && (
          <div className="row">
            <div className="col-md-12">
              <label>Lock</label>

              <div className="card mb-3">
                <div className="card-body pb-2">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="radiosLocked"
                      id="radiosLocked1"
                      value="unlocked"
                      checked={lockedState === 'unlocked'}
                      onChange={(e) => {
                        if (e.target.checked) {
                          this.setFormValues({ locked: false, unlockDate: null });
                        }
                      }}
                    />
                    <label className="form-check-label" htmlFor="radiosLocked1">
                        Unlocked
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="radiosLocked"
                      id="radiosLocked2"
                      value="locked"
                      checked={lockedState === 'locked'}
                      onChange={(e) => {
                        if (e.target.checked) {
                          this.setFormValues({ locked: true, unlockDate: null });
                        }
                      }}
                    />
                    <label className="form-check-label" htmlFor="radiosLocked2">
                        Locked
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="radiosLocked"
                      id="radiosLocked3"
                      value="locked-until"
                      checked={lockedState === 'locked-until'}
                      onChange={(e) => {
                        if (e.target.checked) {
                          this.setFormValues({ locked: true, unlockDate: moment().endOf('minute') });
                        }
                      }}
                    />
                    <label className="form-check-label" htmlFor="radiosLocked3">
                      Locked until
                      <DatePicker
                        value={unlockDate}
                        onChange={(date) => {
                          this.setFormValues({ unlockDate: date });
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderDefaultFields() {
    return (
      <>
        {this.renderProperty('editorialTitle', {
          stateWithPrefix: false,
          label: 'Editorial title',
        })}
      </>
    );
  }

  renderPreviewField(uploadPreview, options = { stateField: 'preview' }) {
    const { [options.stateField]: stateValue, uploads } = this.state;
    const { isUploading } = uploads.find(u => u.stateField === options.stateField) || {};

    return (
      <div>
        <UploadContainer
          accept="image/jpeg,image/png"
          style={{ marginTop: 10, marginBottom: 10 }}
          preview={stateValue?.medium?.filename}
          onFileChange={(imageToUpload) => {
            this.setState({
              uploads: [
                ...uploads.filter(u => u.stateField !== options.stateField),
                {
                  id: imageToUpload.preview,
                  stateField: options.stateField,
                  imageToUpload,
                  isUploading: true,
                  uploadPreview: imageToUpload,
                },
              ],
            });
            uploadPreview(imageToUpload, options.uploadPath);
          }}
          uploadSuccess={!isUploading && stateValue}
        />

      </div>
    );
  }
}
export { DefaultModalProps };
export default DefaultModal;
