import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import { Season } from '../../components/modals';

const SeasonContainer = props => <Season {...props} />;

const mapStateToProps = state => ({
  uploads: selectors.app.uploadsSelector(state),
  isSaving: selectors.seasons.isSavingSelector(state),
});

const mapDispatchToProps = dispatch => ({
  createSeason: bindActionCreators(actions.seasons.uiActions.createSeason, dispatch),
  deleteSeason: bindActionCreators(actions.seasons.uiActions.deleteSeason, dispatch),
  updateSeason: bindActionCreators(actions.seasons.uiActions.updateSeason, dispatch),
  dismissModal: bindActionCreators(actions.app.uiActions.dismissModal, dispatch),
  uploadPreview: bindActionCreators(actions.media.uiActions.uploadPreview, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SeasonContainer);
