import { REHYDRATE } from 'redux-persist';
import { combineReducers } from 'redux';
import actions from '../actions';
import defaultConfig from '../../config';

const initialStatusState = 'loading';
const status = (state = initialStatusState, action = {}) => {
  switch (action.type) {
    case REHYDRATE:
      return 'loading';
    case actions.app.apiActionTypes.APP_START.FAILURE:
      return 'initialized';
    case actions.app.apiActionTypes.APP_START.SUCCESS:
      return 'initialized';
    case actions.auth.uiActionTypes.LOGOUT:
      return 'initialized';
    case actions.app.uiActionTypes.APPSTATE_CHANGE:
      if (state === 'loading') return 'loading';
      return action.payload.appState;
    default:
      return state;
  }
};

const config = (state = defaultConfig, action = {}) => {
  switch (action.type) {
    case actions.app.apiActionTypes.APP_START.SUCCESS: {
      if (action.payload.response && action.payload.response.config) {
        return { ...state, ...action.payload.response.config, API_ROOT: defaultConfig.API_ROOT };
      }
      return state;
    }
    default:
      return state;
  }
};

const initialModalState = {};
const modal = (state = initialModalState, action = {}) => {
  switch (action.type) {
    case actions.app.uiActionTypes.SHOW_MODAL:
      return action.payload.modal;
    case actions.app.uiActionTypes.DISMISS_MODAL:
      return initialModalState;
    default:
      return state;
  }
};

const initialUploadState = {};

const uploads = (state = initialUploadState, action = {}) => {
  switch (action.type) {
    case actions.app.apiActionTypes.UPLOAD_FILE.REQUEST:
      return {
        ...initialUploadState,
        [`${action.payload.request.file.preview}`]: {
          loading: true,
        },
      };
    case actions.app.apiActionTypes.UPLOAD_FILE.SUCCESS:
      return {
        ...initialUploadState,
        [`${action.payload.request.file.preview}`]: {
          loading: false,
          upload: action.payload.response[0],
        },
      };
    case actions.app.apiActionTypes.UPLOAD_FILE.FAILURE:
      return {
        ...initialUploadState,
        [`${action.payload.request.file.preview}`]: {
          loading: false,
          failed: true,
          upload: null,
        },
      };
    default:
      return state;
  }
};

const app = combineReducers({
  status,
  config,
  modal,
  uploads,
});

export default app;
