import React from 'react';
import classnames from 'classnames';
import styles from './Layout.scss';

type LayoutProps = {
  title: string,
  subtitle: string,
  description: string,
  category: string,
  children: any,
};

const Layout = ({
  title, subtitle, description, children, category,
}: LayoutProps) => (
  <div className={styles.root}>
    <div className={styles.heading}>
      <h3 className={styles.title}>{`${title}${category ? ` (${category})` : ''}`}</h3>
      {subtitle && <h6 className={styles.subtitle}>{subtitle}</h6>}
    </div>
    {description && (
      <p className={styles.description}>
        <strong>Description: </strong>
        {description}
      </p>
    )}
    {children}
  </div>
);
type LayoutButtonProps = {
  children: string,
};

Layout.Save = ({ children = 'Save', ...props }: LayoutButtonProps) => (
  <button className="btn btn-secondary" type="submit" {...props}>
    {children}
  </button>
);

Layout.Delete = ({ children = 'Delete', ...props }: LayoutButtonProps) => (
  <button className="btn btn-danger" type="button" {...props}>
    {children}
  </button>
);

Layout.Cancel = ({ children = 'Cancel', ...props }: LayoutButtonProps) => (
  <button className="btn btn-link" type="button" {...props}>
    {children}
  </button>
);
type LayoutActionsProps = {
  onDelete: void,
  onCancel: void,
  onSave: void,
  submmitting: boolean,
  showDelete: Boolean,
  disabled: Boolean
};
Layout.Actions = ({
  onDelete, onCancel, onSave, submmitting, showDelete, disabled,
}: LayoutActionsProps) => (
  <div className={`d-flex ${onDelete ? 'justify-content-between' : 'justify-content-end'}`}>
    {onDelete && showDelete ? <Layout.Delete onClick={onDelete}>delete</Layout.Delete> : <div />}
    <div>
      <Layout.Cancel disabled={disabled} onClick={onCancel}>cancel</Layout.Cancel>
      <Layout.Save disabled={disabled} onClick={onSave}>{submmitting ? 'saving...' : 'save'}</Layout.Save>
    </div>
  </div>
);
Layout.Listing = ({ children }: { children: any }) => (
  <div className={classnames('row', styles.listing)}>{children}</div>
);
export default Layout;
