import { createSelector } from 'reselect';

export const isLoadingSelector = createSelector(
  state => state.seasons.isLoading,
  isLoading => isLoading,
);

export const isSavingSelector = createSelector(
  state => state.seasons.isSaving,
  isSaving => isSaving,
);

export const seasonsSelector = createSelector(
  state => state.seasons.data,
  campaigns => campaigns,
);
