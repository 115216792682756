import React from 'react';
import config from '../config';

type SeasonArtProps = {
  style: Object,
  imageUrl: string,
};

class SeasonArt extends React.Component<SeasonArtProps> {
  state = {
    resolvedImageUrl: `https://${
      config.s3Endpoint
    }/assets/seasons/SEALAS_NOTAVAILABLE.small.jpg`,
  };

  async componentWillMount() {
    const { imageUrl } = this.props;
    const url = imageUrl;
    // See if the image exists
    const imageExists = await new Promise(resolve => {
      const fakeImgElement = new Image();
      fakeImgElement.onload = resolve.bind(null, true);
      fakeImgElement.onerror = resolve.bind(null, false);
      fakeImgElement.src = url;
    });
    if (imageExists) {
      this.setState({ resolvedImageUrl: imageUrl });
    }
  }

  render() {
    const { style } = this.props;
    return <img src={this.state.resolvedImageUrl} style={style} />;
  }
}

export default SeasonArt;
