import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import AuditsTable from '../../components/AuditsTable';

const AuditssTableTableContainer = props => <AuditsTable {...props} />;

const mapStateToProps = state => ({
  auditsData: selectors.audits.auditsDataSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getAuditsData: bindActionCreators(actions.audits.uiActions.getAuditsData, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuditssTableTableContainer);
