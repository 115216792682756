import React, { PureComponent } from 'react';
import { useDropzone } from 'react-dropzone';
import { v1 as uuid } from 'uuid';
import Loading from 'react-simple-loading';
import { colors } from '../styles/theme';
import styles from './Upload.scss';
import Loader from './Loader';

const Upload = ({
  dropText = 'Drop or click to upload an image',
  accept,
  uploadSuccess,
  onFileChange,
  preview = null,
  style,
}) => {
  const [previewUrl, setPreviewUrl] = React.useState(null);
  const [uploaded, setUploaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    if (uploadSuccess && loading) {
      setLoading(false);
      setUploaded(true);
    } else if (!uploadSuccess && !loading) {
      setUploaded(false);
      setPreviewUrl(null);
    }
  }, [uploadSuccess, loading]);
  const handleDrop = (files) => {
    if (files.length) {
      setPreviewUrl(URL.createObjectURL(files[0]));
      files[0].preview = uuid();
      onFileChange(files[0]);
      setLoading(true);
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept,
  });
  return (
    <div className={styles.DropzoneContainer} style={style}>
      <div {...getRootProps()} className={styles.Dropzone}>
        <input {...getInputProps()} />
        <div
          className={styles.Background}
          style={{
            backgroundImage: `url(${
              previewUrl && uploaded ? previewUrl : preview
            })`,
          }}
        />
        {loading && (
          <div className={styles.DropZoneTextOverlay}>
            <p>Uploading...</p>
          </div>
        )}
        {!loading && (
          <div className={styles.DropZoneTextOverlay}>
            <p>
              {isDragActive ? 'Drop here!' : uploaded ? 'Uploaded' : dropText}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Upload;
