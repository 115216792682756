import production from './production';
import staging from './staging';
import development from './development';

const getConfig = () => {
  if (process.env.API_ENDPOINT) {
    if (process.env.API_ENDPOINT === 'production' && window.location.hostname === 'localhost') {
      throw new Error('You cannot connect locally with production');
    }
    console.log(`load configuration with: ${process.env.API_ENDPOINT} config`);

    switch (process.env.API_ENDPOINT) {
      case 'localhost':
        return development;
      case 'staging':
        return staging;
      case 'production':
        return production;
      default:
        return staging;
    }
  }
  console.log(`load configuration for: ${window.location.hostname}`);
  switch (window.location.hostname) {
    case 'localhost':
      return development;
    case 'admin.staging.zender.media':
      return staging;
    case 'admin.zender.media':
      return production;
    default:
      return staging;
  }
};

export default getConfig();
