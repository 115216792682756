import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import { Campaign } from '../../components/modals';

const CampaignContainer = props => <Campaign {...props} />;

const mapStateToProps = state => ({
  isSaving: selectors.campaigns.isSavingSelector(state),
  voucherDetail: selectors.campaigns.voucherDetailSelector(state),
});

const mapDispatchToProps = dispatch => ({
  createCampaign: bindActionCreators(actions.campaigns.uiActions.createCampaign, dispatch),
  deleteCampaign: bindActionCreators(actions.campaigns.uiActions.deleteCampaign, dispatch),
  updateCampaign: bindActionCreators(actions.campaigns.uiActions.updateCampaign, dispatch),
  dismissModal: bindActionCreators(actions.app.uiActions.dismissModal, dispatch),
  downloadVouchers: bindActionCreators(actions.campaigns.uiActions.downloadVouchers, dispatch),
  searchVoucher: bindActionCreators(actions.campaigns.uiActions.searchVoucher, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignContainer);
