const sortBooleanFunction = (prop, direction) => (a, b) => {
  if (a[prop] === b[prop]) {
    return 0;
  }
  if (a[prop]) {
    return direction === 'desc' ? 1 : -1;
  }
  return direction === 'desc' ? -1 : 1;
};
const sortStringFunction = (prop, direction) => (a, b) => {
  if (direction === 'desc') {
    return `${b[prop]}`.localeCompare(a[prop]);
  }
  return `${a[prop]}`.localeCompare(b[prop]);
};
const sortDateFunction = (prop, direction) => (a, b) => {
  if (direction === 'desc') {
    return (new Date(b[prop])).getTime() - (new Date(a[prop])).getTime();
  }
  return (new Date(a[prop])).getTime() - (new Date(b[prop])).getTime();
};


const sortNumberFunction = (prop, direction) => (a, b) => {
  const numberA = parseInt(a[prop], 10);
  const numberB = parseInt(b[prop], 10);
  return direction === 'desc' ? numberB - numberA : numberA - numberB;
};
const isDate = date => (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));

const sortItems = (prop, direction, items) => {
  const allItems = items;
  let sortFunction = () => 0;
  if (allItems.length && typeof allItems[0][prop] === 'boolean') {
    sortFunction = sortBooleanFunction(prop, direction);
  } else if (allItems.length && isDate(allItems[0][prop])) {
    sortFunction = sortDateFunction(prop, direction);
  } else if (allItems.length && !Number.isNaN(parseInt(allItems[0][prop], 10))) {
    sortFunction = sortNumberFunction(prop, direction);
  } else {
    sortFunction = sortStringFunction(prop, direction);
  }
  if (prop && direction) {
    allItems.sort(sortFunction);
  }
  return allItems;
};

export {
  sortItems, sortBooleanFunction, sortNumberFunction, sortStringFunction,
};

export default sortItems;
