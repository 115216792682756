import React from 'react';
import { formatDate } from '../../utils/date';

export default {
  paginated: true,
  columns: [
    {
      prop: 'preview',
      label: 'Icon',
      sortable: false,
    },
    {
      prop: 'bannerImage',
      label: 'Banner',
      sortable: false,
    },
    {
      prop: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      prop: 'displayName',
      label: 'Title',
      sortable: true,
    },
    {
      prop: 'syncName',
      label: 'Sync name',
      sortable: true,
    },
    {
      prop: 'language',
      label: 'Language',
      sortable: true,
    },
    {
      prop: 'order',
      label: 'Order',
      sortable: true,
    },
    {
      prop: 'age',
      label: 'Age',
      sortable: true,
    },
    {
      prop: 'target',
      label: 'Target',
      sortable: true,
    },
    {
      prop: 'syncQuery',
      label: 'Sync query',
      sortable: true,
    },
  ],
  transformer: (row) => {
    const href = ((row.preview || {}).small || {}).filename || '';
    const bannerHref = ((row.bannerImage || {}).small || {}).filename || '';
    const id = `${row.id}-${row.name}`;
    return {
      ...row,
      id,
      key: id,
      preview: href ? <img src={href} style={{ width: 80 }} /> : null,
      bannerImage: bannerHref ? <img src={bannerHref} style={{ width: 80 }} /> : null,
      original: row,
      syncQuery: row.syncQuery ? <div className="column-icon"><i className="fa fa-check" /></div> : null,
    };
  },
};
const brandTargets = [
  { value: 'mix', label: 'Mix' },
  { value: 'boys', label: 'Boys' },
  { value: 'girls', label: 'Girls' },
];

const brandAges = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
];
const brandLanguages = [{ value: 'NL', label: 'NL' }, { value: 'FR', label: 'FR' }];

export { brandTargets, brandLanguages, brandAges };
