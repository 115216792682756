import React, { PureComponent } from 'react';
import seasonsTableConfig, { seasonsLanguages } from '../config/tables/seasons';
import Table from './Table';
import FilterWrapper from './Filters/Wrapper';
import Search from './Filters/Search';
import Select from './Filters/Select';
import { inString } from '../utils/search';
import { PAGE_SIZE } from '../config/pagination';

class SeasonsTable extends PureComponent {
  state = {
    search: '',
    language: null,
    page: 1,
    size: PAGE_SIZE,
  };

  componentDidMount() {
    this.getItems();
  }

  handleChange = property => (value) => {
    const newState = { [property]: value };
    if (property !== 'page') {
      newState.page = 1;
    }

    this.setState(newState);
    setTimeout(() => {
      this.getItems();
    });
  };

  handleSortChange = (sortBy, sortOrder) => {
    this.setState({ sort_by: sortBy, sort_order: sortOrder });
    setTimeout(() => {
      this.getItems();
    });
  };

  handleSearchChange = (e) => {
    const { debounceTimeoutId } = this.state;
    if (debounceTimeoutId) {
      clearTimeout(debounceTimeoutId);
    }

    const { value } = e.target;
    const timeoutId = setTimeout(() => {
      this.getItems();
    }, 400);

    this.setState({
      search: value,
      page: 1,
      debounceTimeoutId: timeoutId,
    });
  };

  getItems = () => {
    const queryObject = {};
    Object.keys(this.state).forEach((key) => {
      if (this.state[key] !== null && key !== 'selection') {
        const prop = key === 'isNew' ? 'new' : key;
        queryObject[prop] = this.state[key];
      }
    });
    this.props.getItems(queryObject);
  };

  handleButtonClick = (action, data) => {
    const { showModal } = this.props;
    showModal({ name: 'Season', item: data });
  };

  render() {
    const { loading, data } = this.props;
    const { search, language } = this.state;

    return (
      <div>
        <FilterWrapper>
          <Search
            value={search}
            onChange={this.handleSearchChange}
            id="seasons-search"
            placeholder="search"
            label="Search"
          />
          <Select
            value={language}
            onChange={this.handleChange('language')}
            id="language-select"
            items={seasonsLanguages}
            label="Language"
          />
        </FilterWrapper>
        <Table
          items={data.items || []}
          loading={loading}
          onAction={this.handleButtonClick}
          {...seasonsTableConfig}
          onPageChange={this.handleChange('page')}
          onSortChange={this.handleSortChange}
          count={data.total}
          applySort={false}
          paginated
        />
      </div>
    );
  }
}

export default SeasonsTable;
