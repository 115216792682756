import React from 'react';

import { MultiBrandsSelectContainer, BrandsSelectContainer } from '../../containers/components';
import Layout from './Layout';
import DefaultModal, { DefaultModalProps } from './DefaultModal';
import formatDate from '../../utils/date';

type OtherMediaModalProps = DefaultModalProps & {};

class OtherMedia extends DefaultModal<OtherMediaModalProps> {
  constructor(props) {
    super(props, true, 'other');
    this.state = {
      ...this.state,
      language: this.media.language,
      brand: this.media.brand || 'all-nl',
      multiBrands: this.media.multiBrands || [],
      mediaLanguage: this.media.language || 'NL',
      syncPreview: this.media.syncPreview,
      preview: this.media.preview,
      poster: this.media.poster,
    };
  }

  componentDidUpdate(newProps) {
    if (this.props.isSaving && !newProps.isSaving) {
      this.props.dismissModal();
    }
  }

  saveMedia() {
    const {
      multiBrands, brand, syncPreview, preview, poster,
    } = this.state;
    const updatedItem = {
      ...this.getStateValuesDefaultFields(),
      multiBrands,
      brand,
      preview,
      poster,
      syncPreview,
    };
    const { id } = this.media;

    if (id) {
      this.props.updateMediaItem(id, updatedItem);
    }
  }

  renderModalActions() {
    const { dismissModal, isSaving } = this.props;
    return (
      <Layout.Actions
        onCancel={() => dismissModal()}
        submmitting={isSaving}
        onSave={() => this.saveMedia()}
        disabled={this.isProcessingUploads()}
      />
    );
  }

  render() {
    const {
      mediaLanguage, brand, multiBrands, language, syncPreview,
    } = this.state;
    const { uploadPreview } = this.props;


    const {
      description,
      id,
      title,
      category,
      year,
      seasonId,
      brand: brandName,
      featuredCheckedDate,
      newCheckedDate,
      subCategory,

    } = this.media;
    return (
      <Layout title={title} category={category} subtitle={id} description={description}>
        <Layout.Listing>
          <div className="col">
            <dl>
              <dd>
                <strong>Production year: </strong>
                {year || '-'}
              </dd>
              <dd>
                <strong>Season id: </strong>
                {seasonId || '-'}
              </dd>
              <dd>
                <strong>Brand name: </strong>
                {brandName || '-'}
              </dd>
            </dl>
          </div>
          <div className="col">
            <dl>
              <dd>
                <strong>Featured date: </strong>
                {featuredCheckedDate ? formatDate(featuredCheckedDate) : '-'}
              </dd>
              <dd>
                <strong>New date: </strong>
                {newCheckedDate ? formatDate(newCheckedDate) : '-'}
              </dd>
            </dl>
          </div>
        </Layout.Listing>
        <hr />
        {this.renderCommonFields()}
        <label>Preview</label>
        {this.renderPreviewField(uploadPreview)}
        {syncPreview && (
          <button
            style={{ margin: '5px 0' }}
            className="btn btn-sm btn-secondary"
            type="submit"
            onClick={() => {
              this.setState({
                preview: syncPreview,
                syncPreview: null,
              });
            }}
          >
            Restore original sync preview
          </button>
        )}

        {subCategory === 'movie' && (
        <>
          <label>Poster</label>
          {this.renderPreviewField(uploadPreview, { uploadPath: 'assets/previews/posters', stateField: 'poster' })}
        </>
        )}
        <div>
          <BrandsSelectContainer
            id="game-distribution"
            label="Distribution"
            language={language}
            value={brand}
            onChange={value => this.setState({ brand: value })}
          />
        </div>
        <MultiBrandsSelectContainer
          id="multibrands-game"
          language={mediaLanguage}
          mainBrand={brand}
          value={multiBrands}
          onChange={brands => this.setState({ multiBrands: brands })}
        />

        {this.renderDefaultFields()}
        {this.renderModalActions()}
      </Layout>
    );
  }
}
export default OtherMedia;
