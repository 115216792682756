import React, { PureComponent } from 'react';
import SeasonsTableContainer from '../containers/components/SeasonsTable';
import LayoutContainer from '../containers/components/Layout';

type SeasonsScreenProps = {
  getSeasons: void,
};

class SeasonsScreen extends PureComponent<SeasonsScreenProps> {
  render() {
    return (
      <LayoutContainer title="Seasons">
        <SeasonsTableContainer />
      </LayoutContainer>
    );
  }
}

export default SeasonsScreen;
