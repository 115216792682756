import {
  createUiActionType, createUiAction, createApiActionTypes, createApiActions,
} from './util';

export const uiActionTypes = {
  GET_MEDIA: createUiActionType('MEDIA/GET_MEDIA'),
  GET_PLAYLIST_MEDIA: createUiActionType('MEDIA/GET_PLAYLIST_MEDIA'),
  GET_CONTENT_DETAIL: createUiActionType('MEDIA/GET_CONTENT_DETAIL'),
  SET_CONTENT_DETAIL: createUiActionType('MEDIA/SET_CONTENT_DETAIL'),
  CLEAR_CONTENT_DETAIL: createUiActionType('MEDIA/CLEAR_CONTENT_DETAIL'),
  SET_MINIHUB: createUiActionType('MEDIA/SET_MINIHUB'),
  SEARCH_MEDIA: createUiActionType('MEDIA/SEARCH_MEDIA'),
  CREATE_ITEM: createUiActionType('MEDIA/CREATE_ITEM'),
  UPDATE_ITEM: createUiActionType('MEDIA/UPDATE_ITEM'),
  DELETE_ITEM: createUiActionType('MEDIA/DELETE_ITEM'),
  BULK_UPDATE_ITEMS: createUiActionType('MEDIA/BULK_UPDATE_ITEMS'),
  SET_BULK_PROGRESS: createUiActionType('MEDIA/SET_BULK_PROGRESS'),
  UPLOAD_GAME_ZIP: createUiActionType('MEDIA/UPLOAD_GAME_ZIP'),
  UPLOAD_PREVIEW: createUiActionType('MEDIA/UPLOAD_PREVIEW'),
  EXPORT_MEDIA: createUiActionType('MEDIA/EXPORT_MEDIA'),
};

export const uiActions = {
  getMedia: query => createUiAction(uiActionTypes.GET_MEDIA, { query }),
  getPlaylistMedia: query => createUiAction(uiActionTypes.GET_PLAYLIST_MEDIA, { query }),
  getContentDetail: content => createUiAction(uiActionTypes.GET_CONTENT_DETAIL, { content }),
  clearContentDetail: () => createUiAction(uiActionTypes.CLEAR_CONTENT_DETAIL),
  searchMedia: query => createUiAction(uiActionTypes.SEARCH_MEDIA, { query }),
  createItem: item => createUiAction(uiActionTypes.CREATE_ITEM, { item }),
  updateItem: (id, update) => createUiAction(uiActionTypes.UPDATE_ITEM, { id, update }),
  deleteItem: id => createUiAction(uiActionTypes.DELETE_ITEM, { id }),
  bulkUpdateItems: (action, value, selection, actionConfig) => createUiAction(uiActionTypes.BULK_UPDATE_ITEMS, {
    action, value, selection, actionConfig,
  }),
  setBulkProgress: (progress, inProgress) => createUiAction(uiActionTypes.SET_BULK_PROGRESS, { progress, inProgress }),
  uploadGameZip: file => createUiAction(uiActionTypes.UPLOAD_GAME_ZIP, { file }),
  uploadPreview: (file, uploadPath, uploadKey, uploadType) => createUiAction(uiActionTypes.UPLOAD_PREVIEW, {
    file, uploadPath, uploadKey, uploadType,
  }),
  exportMedia: query => createUiAction(uiActionTypes.EXPORT_MEDIA, { query }),
};

export const apiActionTypes = {
  GET_MEDIA: createApiActionTypes('MEDIA/GET_MEDIA'),
  GET_EXPORT_MEDIA: createApiActionTypes('MEDIA/GET_EXPORT_MEDIA'),
  GET_PLAYLIST_MEDIA: createApiActionTypes('MEDIA/GET_PLAYLIST_MEDIA'),
  SEARCH_MEDIA: createApiActionTypes('MEDIA/SEARCH_MEDIA'),
  GET_ITEM: createApiActionTypes('MEDIA/GET_ITEM'),
  CREATE_ITEM: createApiActionTypes('MEDIA/CREATE_ITEM'),
  UPDATE_ITEM: createApiActionTypes('MEDIA/UPDATE_ITEM'),
  DELETE_ITEM: createApiActionTypes('MEDIA/DELETE_ITEM'),
};

export const apiActions = {
  getMedia: createApiActions(apiActionTypes.GET_MEDIA),
  getExportMedia: createApiActions(apiActionTypes.GET_EXPORT_MEDIA),
  getPlaylistMedia: createApiActions(apiActionTypes.GET_PLAYLIST_MEDIA),
  searchMedia: createApiActions(apiActionTypes.SEARCH_MEDIA),
  getItem: createApiActions(apiActionTypes.GET_ITEM),
  createItem: createApiActions(apiActionTypes.CREATE_ITEM),
  updateItem: createApiActions(apiActionTypes.UPDATE_ITEM),
  deleteItem: createApiActions(apiActionTypes.DELETE_ITEM),
};
