import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import { Serie } from '../../components/modals';

const SerieContainer = props => <Serie {...props} />;

const mapStateToProps = state => ({
  uploads: selectors.app.uploadsSelector(state),
  isSaving: selectors.series.isSavingSelector(state),
});

const mapDispatchToProps = dispatch => ({
  createSerie: bindActionCreators(actions.series.uiActions.createSerie, dispatch),
  deleteSerie: bindActionCreators(actions.series.uiActions.deleteSerie, dispatch),
  updateSerie: bindActionCreators(actions.series.uiActions.updateSerie, dispatch),
  dismissModal: bindActionCreators(actions.app.uiActions.dismissModal, dispatch),
  uploadPreview: bindActionCreators(actions.media.uiActions.uploadPreview, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SerieContainer);
