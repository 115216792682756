import { REHYDRATE } from 'redux-persist';
import { combineReducers } from 'redux';
import actions from '../actions';

const initialStatusState = 'loading';
const status = (state = initialStatusState, action = {}) => {
  switch (action.type) {
    case REHYDRATE:
      return 'initialized';
    default:
      return state;
  }
};

const initialIsLoadingState = false;
const isLoading = (state = initialIsLoadingState, action = {}) => {
  switch (action.type) {
    case actions.media.apiActionTypes.GET_MEDIA.REQUEST:
      return true;
    case actions.media.apiActionTypes.GET_MEDIA.SUCCESS:
    case actions.media.apiActionTypes.GET_MEDIA.FAILURE:
      return false;
    default:
      return state;
  }
};

const initialIsSavingState = false;
const isSaving = (state = initialIsSavingState, action = {}) => {
  switch (action.type) {
    case actions.media.apiActionTypes.CREATE_ITEM.REQUEST:
    case actions.media.apiActionTypes.UPDATE_ITEM.REQUEST:
      return true;
    case actions.media.apiActionTypes.CREATE_ITEM.SUCCESS:
    case actions.media.apiActionTypes.CREATE_ITEM.FAILURE:
    case actions.media.apiActionTypes.UPDATE_ITEM.SUCCESS:
    case actions.media.apiActionTypes.UPDATE_ITEM.FAILURE:
      return false;
    default:
      return state;
  }
};

const initialMediaState = { total: 0, items: [] };
const data = (state = initialMediaState, action = {}) => {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.payload.media && action.payload.media.data) {
        return action.payload.media.data;
      }
      return state;
    case actions.media.apiActionTypes.GET_MEDIA.REQUEST:
      return initialMediaState;
    case actions.media.apiActionTypes.GET_MEDIA.SUCCESS:
      return action.payload.response;
    case actions.media.apiActionTypes.CREATE_ITEM.SUCCESS: {
      const items = [action.payload.response, ...state.items];
      return { ...state, items };
    }
    case actions.media.apiActionTypes.UPDATE_ITEM.SUCCESS: {
      const index = state.items.findIndex(i => i.id === action.payload.response.id);
      const items = [
        ...state.items.slice(0, index),
        action.payload.response,
        ...state.items.slice(index + 1),
      ];
      return { ...state, items };
    }
    case actions.auth.uiActionTypes.LOGOUT:
      return initialMediaState;
    default:
      return state;
  }
};

const initialPlaylistMediaState = { total: 0, items: [], loading: false };
const playlistData = (state = initialPlaylistMediaState, action = {}) => {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.payload.media && action.payload.media.data) {
        return action.payload.media.data;
      }
      return state;
    case actions.media.apiActionTypes.GET_PLAYLIST_MEDIA.REQUEST:
      return { ...initialPlaylistMediaState, loading: true };
    case actions.media.apiActionTypes.GET_PLAYLIST_MEDIA.SUCCESS:
      return { ...action.payload.response, loading: false };
    case actions.auth.uiActionTypes.LOGOUT:
      return initialPlaylistMediaState;
    default:
      return state;
  }
};

const initialContentDetailState = {};
const detail = (state = initialContentDetailState, action = {}) => {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.payload.media && action.payload.media.detail) {
        return action.payload.media.detail;
      }
      return state;
    case actions.media.uiActionTypes.GET_CONTENT_DETAIL:
      return action.payload.content;
    case actions.media.apiActionTypes.GET_ITEM.SUCCESS:
      return action.payload.response || state;
    case actions.media.uiActionTypes.CLEAR_CONTENT_DETAIL:
      return initialContentDetailState;
    case actions.auth.uiActionTypes.LOGOUT:
      return initialContentDetailState;
    default:
      return state;
  }
};

const initialMinihubState = [];
const minihub = (state = initialMinihubState, action = {}) => {
  switch (action.type) {
    case actions.media.uiActionTypes.SET_MINIHUB:
      return action.payload.minihub;
    case actions.auth.uiActionTypes.LOGOUT:
      return initialMinihubState;
    default:
      return state;
  }
};

const initialBulkProgressState = {
  inProgress: false,
  progress: 0,
};

const bulkProgress = (state = initialBulkProgressState, action = {}) => {
  switch (action.type) {
    case actions.media.uiActionTypes.SET_BULK_PROGRESS:
      return action.payload;
    default:
      return state;
  }
};

const initialIsExportLoadingState = false;
const isExportLoading = (state = initialIsExportLoadingState, action = {}) => {
  switch (action.type) {
    case actions.media.apiActionTypes.GET_EXPORT_MEDIA.REQUEST:
      return true;
    case actions.media.apiActionTypes.GET_EXPORT_MEDIA.SUCCESS:
      return false;
    default:
      return state;
  }
};

const media = combineReducers({
  status,
  isLoading,
  isSaving,
  playlistData,
  data,
  detail,
  minihub,
  bulkProgress,
  isExportLoading,
});

export default media;
