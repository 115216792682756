import React from 'react';
import { formatDate } from '../../utils/date';

export default {
  paginated: true,
  columns: [
    {
      prop: 'poster',
      label: 'Poster',
    },
    {
      prop: 'banner',
      label: 'Banner',
    },
    {
      prop: 'id',
      label: 'Id',
      sortable: true,
    },
    {
      prop: 'title',
      label: 'Title',
      sortable: true,
    },
    {
      prop: 'language',
      label: 'Language',
      sortable: true,
    },
    {
      prop: 'brand',
      label: 'Distribution brand',
      sortable: true,
    },
    {
      prop: 'published',
      label: 'Published',
      sortable: true,
    },
    {
      prop: 'featured',
      label: 'Featured',
      sortable: true,
    },
    {
      action: 'edit',
      label: 'Action',
      button_label: 'Edit',
    },
  ],
  transformer: (row) => {
    const posterHref = row?.poster?.small?.filename || '';
    const bannerHref = row?.bannerImage?.small?.filename || '';
    let { title } = row;

    if (row.editorialTitle && row.editorialTitle.length) {
      title = (
        <dl>
          <dd>{row.editorialTitle}</dd>
          <dd>
            <strong>Original: </strong>
            {row.title}
          </dd>
        </dl>
      );
    }
    return {
      ...row,
      title,
      id: <span className="table-col-id">{row.id}</span>,
      poster: <img src={posterHref} style={{ width: 80 }} />,
      banner: <img src={bannerHref} style={{ width: 80 }} />,
      featured: row.featured ? <div className="column-icon"><i className="fa fa-check" /></div> : null,
      published: row.published ? <div className="column-icon"><i className="fa fa-check" /></div> : null,
      createdAt: formatDate(row.createdAt),
      updatedAt: formatDate(row.updatedAt),
      original: row,
    };
  },
};

export const seriesLanguages = [{ value: 'NL', label: 'NL' }, { value: 'FR', label: 'FR' }];
export const seriesBroadcastTags = [{ value: 'broadcast', label: 'Broadcast' }, { value: 'non-broadcast', label: 'Non-Broadcast' }];
