import React from 'react';
import { UploadContainer } from '../../containers/components';
import config from '../../config';
import DefaultModal from './DefaultModal';
import Layout from './Layout';

const defaultPreview = id => ({
  small: {
    filename: `https://${config.s3Endpoint}/assets/seasons/SEALAS_${id}.small.jpg`,
  },
  medium: {
    filename: `https://${config.s3Endpoint}/assets/seasons/SEALAS_${id}.medium.jpg`,
  },
  large: {
    filename: `https://${config.s3Endpoint}/assets/seasons/SEALAS_${id}.large.jpg`,
  },
});

export default class Season extends DefaultModal {
  constructor(props) {
    const original = (props.item || {}).original || {};
    super(props);
    this.state = {
      ...this.state,
      language: original.language || 'NL',
      brand: original.brand || 'all-nl',
      title: original.title,
      id: original.id,
      editorialTitle: original.editorialTitle || '',
      seasonId: original.seasonId || 0,
      preview: original.preview || {},
      published: original.published || false,
      featured: original.featured || false,
      new: original.new || false,
      upload: null,
    };
  }

  componentDidUpdate(newProps) {
    if (this.props.isSaving && !newProps.isSaving) {
      this.props.dismissModal();
    }
  }

  renderModalActions() {
    const { dismissModal, isSaving } = this.props;

    return (
      <Layout.Actions
        disabled={isSaving || this.isProcessingUploads()}
        onCancel={() => dismissModal()}
        submmitting={isSaving}
        onSave={() => this.saveSeason()}
      />
    );
  }

  saveSeason() {
    const {
      title, id, language, editorialTitle, brand, published, featured, new: isNew, preview, seasonId, upload,
    } = this.state;
    if (parseInt(id, 10) === 0 || title === '') {
      return;
    }
    const season = {
      title,
      seasonId,
      editorialTitle,
      language,
      published,
      featured,
      new: isNew,
      brand,
      upload: upload ? {
        file: upload,
      } : null,
      preview: preview || defaultPreview(id),
    };
    const {
      item, dismissModal, updateSeason, createSeason,
    } = this.props;

    if (item && item.original.id) {
      updateSeason(item.original.id, season);
    } else {
      createSeason(season);
    }
    dismissModal();
  }

  handleUploadImage = (file) => {
    const id = this.props.item?.original?.id;
    this.props.uploadPreview(file, 'assets/seasons', `SEALAS_${id}`, 'image/jpeg');
  }

  render() {
    const { item } = this.props;

    return (
      <Layout
        title={item.original.title}
        subtitle={(
          <>
            <strong>Id: </strong>
            {item.original.id}
            {' - '}
            <strong>Serie Id: </strong>
            {item.original.serieId}
          </>
        )}
      >

        {this.renderCommonFields({ withNew: true, withPublished: true, withFeatured: true })}

        {this.renderProperty('editorialTitle', {
          stateWithPrefix: false,
          label: 'Editorial title',
        })}

        <label>
Preview
          {' '}
          <span className="badge badge-warning">old app</span>
        </label>
        {this.renderPreviewField(this.handleUploadImage)}
        {this.renderModalActions()}
      </Layout>
    );
  }
}
