import React, { PureComponent } from 'react';
import BrandsTableContainer from '../containers/components/BrandsTable';
import LayoutContainer from '../containers/components/Layout';

type BrandsScreenProps = {
  getBrands: void,
};
class BrandsScreen extends PureComponent<BrandsScreenProps> {
  componentWillMount() {
    const { getBrands } = this.props;
    getBrands();
  }

  render() {
    return (
      <LayoutContainer title="Brands">
        <BrandsTableContainer />
      </LayoutContainer>
    );
  }
}

export default BrandsScreen;
