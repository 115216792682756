import { REHYDRATE } from 'redux-persist';
import { combineReducers } from 'redux';
import actions from '../actions';

const initialIsLoadingState = false;
const isLoading = (state = initialIsLoadingState, action = {}) => {
  switch (action.type) {
    case actions.series.apiActionTypes.GET_SERIES.REQUEST:
      return true;
    case actions.series.apiActionTypes.GET_SERIES.SUCCESS:
    case actions.series.apiActionTypes.GET_SERIES.FAILURE:
      return false;
    default:
      return state;
  }
};

const isSaving = (state = initialIsLoadingState, action = {}) => {
  switch (action.type) {
    case actions.series.apiActionTypes.CREATE_SERIE.REQUEST:
    case actions.series.apiActionTypes.UPDATE_SERIE.REQUEST:
    case actions.series.apiActionTypes.DELETE_SERIE.REQUEST:
      return true;
    case actions.series.apiActionTypes.CREATE_SERIE.SUCCESS:
    case actions.series.apiActionTypes.CREATE_SERIE.FAILURE:
    case actions.series.apiActionTypes.UPDATE_SERIE.SUCCESS:
    case actions.series.apiActionTypes.UPDATE_SERIE.FAILURE:
    case actions.series.apiActionTypes.DELETE_SERIE.SUCCESS:
    case actions.series.apiActionTypes.DELETE_SERIE.FAILURE:
      return false;
    default:
      return state;
  }
};

const initialSeriesState = [];
const data = (items = initialSeriesState, action = {}) => {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.payload.series && action.payload.series.data) {
        return action.payload.series.data;
      }
      return items;
    case actions.series.apiActionTypes.GET_SERIES.REQUEST:
      return initialSeriesState;
    case actions.series.apiActionTypes.GET_SERIES.SUCCESS:
      return action.payload.response;
    case actions.series.apiActionTypes.CREATE_SERIE.SUCCESS: {
      return [action.payload.response, ...items];
    }
    case actions.series.apiActionTypes.UPDATE_SERIE.SUCCESS: {
      const index = items.findIndex(i => i.id === action.payload.response.id);
      return [...items.slice(0, index), action.payload.response, ...items.slice(index + 1)];
    }
    case actions.series.apiActionTypes.DELETE_SERIE.SUCCESS: {
      const index = items.findIndex(i => i.id === action.payload.request.id);
      return [...items.slice(0, index), ...items.slice(index + 1)];
    }
    case actions.auth.uiActionTypes.LOGOUT:
      return initialSeriesState;
    default:
      return items;
  }
};

const series = combineReducers({
  isLoading,
  isSaving,
  data,
});

export default series;
