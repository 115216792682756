import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import selectors from '../../store/selectors';
import { Navigation } from '../../components';

import actions from '../../store/actions';

const NavigationContainer = props => <Navigation {...props} />;

const mapStateToProps = state => ({
  user: selectors.auth.userSelector(state),
});

const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(actions.auth.uiActions.logout, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavigationContainer);
