import React from 'react';

import BurgerMenu from './BurgerMenu';
import { menuItemsAdmin, menuItemsCampaignManager } from '../config/menu';
import styles from './Navigation.scss';

const Navigation = ({ logout, user, children }) => (
  <>
    <BurgerMenu menuItems={user.role === 'admin' ? menuItemsAdmin : menuItemsCampaignManager} />
    <div id="burger-content">
      <nav className="navbar navbar-fixed-top">
        <div className="container-fluid">
          <div className="navbar-header">
            <img src="logo.png" alt="studio 100 logo" style={{ width: 100 }} />
          </div>
          <div id="navbar">
            <ul className="nav navbar-nav navbar-right">
              <li>
                <button className="btn btn-link" onClick={logout}>
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <div className={styles.content}>{children}</div>
  </>
);

export default Navigation;
