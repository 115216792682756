import {
  createUiActionType, createUiAction, createApiActionTypes, createApiActions,
} from './util';

export const uiActionTypes = {
  GET_BRANDS: createUiActionType('BRANDS/GET_BRANDS'),
  CREATE_BRAND: createUiActionType('BRANDS/CREATE_BRAND'),
  UPDATE_BRAND: createUiActionType('BRANDS/UPDATE_BRAND'),
  DELETE_BRAND: createUiActionType('BRANDS/DELETE_BRAND'),
};

export const uiActions = {
  getBrands: query => createUiAction(uiActionTypes.GET_BRANDS, { query }),
  createBrand: (name, brand) => createUiAction(uiActionTypes.CREATE_BRAND, { name, brand }),
  updateBrand: (name, update) => createUiAction(uiActionTypes.UPDATE_BRAND, { name, update }),
  deleteBrand: name => createUiAction(uiActionTypes.DELETE_BRAND, { name }),
};

export const apiActionTypes = {
  GET_BRANDS: createApiActionTypes('BRANDS/GET_BRANDS'),
  CREATE_BRAND: createApiActionTypes('BRANDS/CREATE_BRAND'),
  UPDATE_BRAND: createApiActionTypes('BRANDS/UPDATE_BRAND'),
  DELETE_BRAND: createApiActionTypes('BRANDS/DELETE_BRAND'),
};

export const apiActions = {
  getBrands: createApiActions(apiActionTypes.GET_BRANDS),
  createBrand: createApiActions(apiActionTypes.CREATE_BRAND),
  updateBrand: createApiActions(apiActionTypes.UPDATE_BRAND),
  deleteBrand: createApiActions(apiActionTypes.DELETE_BRAND),
};
