import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import MediaTable from '../../components/MediaTable';

const MediaTableTableContainer = props => <MediaTable {...props} />;

const mapStateToProps = state => ({
  data: selectors.media.mediaSelector(state),
  loading: selectors.media.isLoadingSelector(state),
  bulkProgress: selectors.media.bulkProgressSelector(state),
  isExportLoading: selectors.media.isExportLoadingSelector(state),
});

const mapDispatchToProps = dispatch => ({
  bulkUpdateItems: bindActionCreators(
    actions.media.uiActions.bulkUpdateItems,
    dispatch,
  ),
  updateItem: bindActionCreators(actions.media.uiActions.updateItem, dispatch),
  getItems: bindActionCreators(actions.media.uiActions.getMedia, dispatch),
  search: bindActionCreators(actions.media.uiActions.searchMedia, dispatch),
  showModal: bindActionCreators(actions.app.uiActions.showModal, dispatch),
  exportMedia: bindActionCreators(
    actions.media.uiActions.exportMedia,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MediaTableTableContainer);
