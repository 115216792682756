import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import { SyncScreen } from '../../screens';

const SyncScreenContainer = props => <SyncScreen {...props} />;

const mapStateToProps = state => ({
  syncData: selectors.sync.syncDataSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getSyncData: bindActionCreators(actions.sync.uiActions.getSyncData, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SyncScreenContainer);
