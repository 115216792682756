import React, { PureComponent } from 'react';
import * as Modals from '../containers/modals';
import { NavigationContainer } from '../containers/components';
import styles from './Layout.scss';
import Loader from './Loader';
import ModalOverlay from './modals/Overlay';

type LayoutProps = {
  modal: Object,
  isLoading: boolean,
  children: any,
  dismissModal: void,
  title: string,
};
class Layout extends PureComponent<LayoutProps> {
  componentDidMount() {
    document.body.addEventListener('keyup', this.handleKeyPress);
  }

  componentWillUnmount() {
    document.body.removeEventListener('keyup', this.handleKeyPress);
  }

  handleKeyPress = e => {
    const { dismissModal } = this.props;
    if (e.which === 27) {
      dismissModal();
    }
  };

  renderModal({ name, item }) {
    const { dismissModal } = this.props;

    if (Modals[name]) {
      const Component = Modals[name];
      return (
        <ModalOverlay onClose={dismissModal}>
          <Component item={item} />
        </ModalOverlay>
      );
    }
    return null;
  }

  renderMain() {
    const { children, title } = this.props;
    return (
      <div id="burger-container">
        <NavigationContainer>
          <div className={styles.contentRoot}>
            {title && <h1 className={styles.title}>{title}</h1>}
            {children}
          </div>
        </NavigationContainer>
      </div>
    );
  }

  render() {
    const { modal, isLoading } = this.props;
    return (
      <div>
        {isLoading ? <Loader /> : this.renderMain()}
        {modal && modal.name && this.renderModal(modal)}
      </div>
    );
  }
}

export default Layout;
