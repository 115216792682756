import {
  createUiActionType, createUiAction, createApiActionTypes, createApiActions,
} from './util';

export const uiActionTypes = {
  GET_CAMPAIGNS: createUiActionType('CAMPAIGNS/GET_CAMPAIGNS'),
  CREATE_CAMPAIGN: createUiActionType('CAMPAIGNS/CREATE_CAMPAIGN'),
  UPDATE_CAMPAIGN: createUiActionType('CAMPAIGNS/UPDATE_CAMPAIGN'),
  DELETE_CAMPAIGN: createUiActionType('CAMPAIGNS/DELETE_CAMPAIGN'),
  DOWNLOAD_VOUCHERS: createUiActionType('CAMPAIGNS/DOWNLOAD_VOUCHERS'),
  SEARCH_VOUCHER: createUiActionType('CAMPAIGNS/SEARCH_VOUCHER'),
  EXPORT_ACTIVE_VOUCHERS: createUiActionType('CAMPAIGNS/EXPORT_ACTIVE_VOUCHERS'),
};

export const uiActions = {
  getCampaigns: query => createUiAction(uiActionTypes.GET_CAMPAIGNS, { query }),
  createCampaign: campaign => createUiAction(uiActionTypes.CREATE_CAMPAIGN, { campaign }),
  updateCampaign: (id, campaign) => createUiAction(uiActionTypes.UPDATE_CAMPAIGN, { id, campaign }),
  deleteCampaign: id => createUiAction(uiActionTypes.DELETE_CAMPAIGN, { id }),
  downloadVouchers: campaign => createUiAction(uiActionTypes.DOWNLOAD_VOUCHERS, { campaign }),
  searchVoucher: (id, code) => createUiAction(uiActionTypes.SEARCH_VOUCHER, { id, code }),
  exportActiveVouchers: () => createUiAction(uiActionTypes.EXPORT_ACTIVE_VOUCHERS, {}),
};

export const apiActionTypes = {
  GET_CAMPAIGN: createApiActionTypes('CAMPAIGNS/GET_CAMPAIGN'),
  GET_CAMPAIGNS: createApiActionTypes('CAMPAIGNS/GET_CAMPAIGNS'),
  CREATE_CAMPAIGN: createApiActionTypes('CAMPAIGNS/CREATE_CAMPAIGN'),
  UPDATE_CAMPAIGN: createApiActionTypes('CAMPAIGNS/UPDATE_CAMPAIGN'),
  DELETE_CAMPAIGN: createApiActionTypes('CAMPAIGNS/DELETE_CAMPAIGN'),
  SEARCH_VOUCHER: createApiActionTypes('CAMPAIGNS/SEARCH_VOUCHER'),
  ACTIVE_VOUCHERS: createApiActionTypes('CAMPAIGNS/ACTIVE_VOUCHERS'),
};

export const apiActions = {
  getCampaign: createApiActions(apiActionTypes.GET_CAMPAIGN),
  getCampaigns: createApiActions(apiActionTypes.GET_CAMPAIGNS),
  createCampaign: createApiActions(apiActionTypes.CREATE_CAMPAIGN),
  updateCampaign: createApiActions(apiActionTypes.UPDATE_CAMPAIGN),
  deleteCampaign: createApiActions(apiActionTypes.DELETE_CAMPAIGN),
  searchVoucher: createApiActions(apiActionTypes.SEARCH_VOUCHER),
  getActiveVouchers: createApiActions(apiActionTypes.ACTIVE_VOUCHERS),
};
