const colors = {
  black: '#000000',
  blacklighter: '#290D2F',
  green: '#78BE20',
  grey: '#666',
  lightblue: '#4488cc',
  lightgrey: '#ededed',
  middlegrey: '#aaa',
  red: '#EC354D',
  white: '#FFFFFF',
  yellow: '#F4E80B',
  indigo: '#34277E',
  indigodarker: '#271C65',
  blue: '#0896C8',
  bluelighter: '#0AAAE2',
  pink: '#EA1E38',
  pinklighter: '#EC354D',
  orangelighter: '#EB6620',
  orange: '#DD5D1A',
};

export default colors;
