import React, { PureComponent } from 'react';
import auditssTableConfig from '../config/tables/audits';
import Table from './Table';
import FilterWrapper from './Filters/Wrapper';
import Search from './Filters/Search';
import Select from './Filters/Select';
import DatePicker from './Form/DatePicker';


const VIEWS = [
  { value: 'all', label: 'All' },
  { value: 'modifications', label: 'Updated items' },
  { value: 'inserts', label: 'New items' },
  { value: 'published', label: 'Published' },
  { value: 'unpublished', label: 'Unpublished' },
  { value: 'locked', label: 'Locked' },
  { value: 'unlocked', label: 'Unlocked' },
];

type AuditsTableProps = {
  auditsData: Array,
  getAuditsData: Function,
};

class AuditsTable extends PureComponent<AuditsTableProps> {
  state = {
    search: '',
    view: VIEWS[1].value,
    date: null,
  }

  componentWillMount() {
    this.queryItems();
  }

  getItems() {
    const { auditsData } = this.props;
    return auditsData;
  }

  queryItems() {
    const { search, view, date } = this.state;
    const { getAuditsData } = this.props;
    const query = {
      search: search || null,
      view,
      date: date && date.format('YYYY-MM-DD'),
    };

    getAuditsData(query);
  }

  render() {
    const { search, view, date } = this.state;

    return (
      <div style={{ marginTop: 20 }}>
        <FilterWrapper>
          <Select
            value={view}
            id="view-select"
            items={VIEWS}
            label="View"
            withNone={false}
            onChange={(v) => {
              this.setState({ view: v, search: '' }, this.queryItems);
            }}
          />
          <DatePicker
            disabled={search === '' && view === 'all'}
            value={date}
            onChange={(v) => {
              this.setState({ date: v }, this.queryItems);
            }}
            showTimeSelect={false}
            label="Filter by date"
            dateFormat="DD/MM/YY"
          />
          <Search
            value={search}
            onChange={e => this.setState({ search: e.target.value })}
            id="id-search"
            placeholder="ID00000001"
            label="Find by objectID"
          />
          <button
            type="button"
            className="btn btn-secondary"
            style={{ marginTop: 15, marginLeft: -10 }}
            onClick={() => {
              this.setState({
                view: VIEWS[0].value,
              }, this.queryItems);
            }}
          >
            Find
          </button>
        </FilterWrapper>
        <Table
          items={this.getItems()}
          {...auditssTableConfig}
          paginated
        />
      </div>
    );
  }
}

export default AuditsTable;
