import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import classnames from 'classnames';

import styles from './ColorPicker.scss';

type ColorPickerProps = {
    value: string,
    onChange: void,
    label: string,
};

const ColorPicker = ({
  value,
  onChange,
  label,
}: ColorPickerProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [color, setColor] = useState(value);

  return (
    <div className={classnames('form-group', styles.root)}>
      {label && <label>{label}</label>}
      <div
        className={classnames('form-control', styles.colorBlock)}
        style={{ backgroundColor: color.hex }}
        onClick={() => setIsVisible(true)}
      />
      {isVisible && (
        <>
          <div
            onClick={() => setIsVisible(false)}
            className={styles.cover}
          />
          <SketchPicker
            className={styles.picker}
            color={value}
            onChange={(chosenColor) => {
              setColor(chosenColor);
              onChange(chosenColor);
            }}
          />
        </>
      )}
    </div>
  );
};
export default ColorPicker;
