import React from 'react';
import deepDiff from 'deep-diff';

const IGNORED_PATHS = ['updatedAt', 'auditData'];
const IGNORED_PATHS_REGEX = new RegExp(`^(${IGNORED_PATHS.join('|')})`);

function Value({ children }) {
  if (typeof children === 'boolean') {
    return children ? 'true' : 'false';
  }

  if (typeof children === 'string') {
    return children;
  }

  if (typeof children === 'undefined' || children === null) {
    return '--empty--';
  }


  return JSON.stringify(children);
}

function Diff({
  kind, path, lhs, rhs,
}) {
  return (
    <div style={{marginBottom: 10}}>
      <div style={{fontWeight: 700}}>
        {path.join('.')}
      </div>
      <div style={{paddingLeft: 5, paddingRight: 5, paddingTop: 2, paddingBottom: 2, color: 'red'}}>
        <Value>{lhs}</Value>
      </div>
      <div style={{paddingLeft: 5, paddingRight: 5, paddingTop: 2, paddingBottom: 2, color: 'green'}}>
        <Value>{rhs}</Value>
      </div>
    </div>
  );
}

function AuditsDifference({ before, after }) {
  const diff = deepDiff(before, after) || [];

  // Check for value equality
  // eslint-disable-next-line eqeqeq
  const filteredDiff = diff.filter(d => !IGNORED_PATHS_REGEX.test(d.path.join('.'))).filter(d => d.rhs != d.lhs);

  return (
    <div style={{ maxWidth: '50vw', background: '#eee', padding: 10 }}>
      {filteredDiff.map((d, i) => <Diff key={i} {...d} />)}
    </div>
  );
}

export default AuditsDifference;
