import React, { PureComponent } from 'react';
import CampaignsTablesContainer from '../containers/components/CampaignsTable';
import LayoutContainer from '../containers/components/Layout';

type CampaignsScreenProps = {
  getCampaigns: void,
};

class CampaignsScreen extends PureComponent<CampaignsScreenProps> {
  componentWillMount() {
    const { getCampaigns } = this.props;
    getCampaigns();
  }

  render() {
    return (
      <LayoutContainer title="Campaigns">
        <CampaignsTablesContainer />
      </LayoutContainer>
    );
  }
}

export default CampaignsScreen;
