import React from 'react';

const Loader = ({ message, note }) => (
  <div className="loader">
    <div>
      <i className="fa fa-spin fa-cog" />
    </div>
    <p>{message}</p>
    <p className="note">{note}</p>
  </div>
);

export default Loader;
