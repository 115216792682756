import React, { PureComponent } from 'react';
import MediaTableContainer from '../containers/components/MediaTable';
import CampaignsScreenContainer from '../containers/screens/campaigns';
import LayoutContainer from '../containers/components/Layout';

type HomeScreenProps = {
  user: Object,
};

class HomeScreen extends PureComponent<HomeScreenProps> {
  render() {
    const { user } = this.props;
    return (
      <LayoutContainer title={user.role === 'admin' ? 'Content' : 'Campaigns'}>
        {user && user.role === 'admin' && <MediaTableContainer />}
        {user && user.role === 'campaigns' && <CampaignsScreenContainer />}
      </LayoutContainer>
    );
  }
}

export default HomeScreen;
