import React from 'react';
import classnames from 'classnames';
import DatePickerComp from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import styles from './DatePicker.scss';
import { dateFormat as defaultDateFormat } from '../../utils/date';

type DatePickerProps = {
  timeFormat: string,
  dateFormat: string,
  value: Date,
  onChange: void,
  label: string,
  showTimeSelect: Boolean,
};

const DatePicker = ({
  timeFormat = 'HH:mm',
  dateFormat = defaultDateFormat,
  value,
  onChange,
  label,
  disabled,
  showTimeSelect = true,
}: DatePickerProps) => (
  <div className={classnames('form-group', styles.root, { disabled })}>
    {label && (
    <label>
      {label}
    </label>
    )}

    <div>
      <DatePickerComp
        disabled={disabled}
        isClearable
        className="form-control"
        showTimeSelect={showTimeSelect}
        timeFormat={timeFormat}
        dateFormat={dateFormat}
        selected={value}
        onChange={onChange}
      />
      <button
        type="button"
        className="btn btn-link btn-sm p-0"
        onClick={() => onChange(moment().endOf('minute'))}
      >
          Select now
      </button>
    </div>
  </div>
);
export default DatePicker;
