import { fork, all } from 'redux-saga/effects';
import {
  loginSaga,
  checkLoginSaga,
  bootstrapSaga,
  logoutSaga,
  getSyncSaga,
  getBrandsSaga,
  updateBrandSaga,
  createBrandSaga,
  deleteBrandSaga,
  uploadPreviewSaga,
  uploadGameZipSaga,
  getAuditsSaga,
} from './sagas';

import {
  getCampaignsSaga,
  createCampaignSaga,
  deleteCampaignSaga,
  updateCampaignSaga,
  downloadVouchersSaga,
  exportActiveVouchersSaga,
  searchVoucherSaga,
} from './campaigns';
import {
  getSeasonsSaga,
  createSeasonSaga,
  updateSeasonSaga,
  deleteSeasonSaga,
  addAllSeasonsSaga,
} from './seasons';
import {
  getSeriesSaga,
  createSerieSaga,
  updateSerieSaga,
  deleteSerieSaga,
} from './series';
import {
  createMediaItemSaga,
  updateMediaItemSaga,
  deleteMediaItemSaga,
  bulkUpdateMediaItemsSaga,
  exportMediaSaga,
  getMediaItemsSaga,
  getPlaylistMediaItemsSaga,
} from './media';

const rootSaga = function* () {
  yield all([
    fork(loginSaga),
    fork(checkLoginSaga),
    fork(bootstrapSaga),
    fork(logoutSaga),
    fork(createMediaItemSaga),
    fork(updateMediaItemSaga),
    fork(deleteMediaItemSaga),
    fork(bulkUpdateMediaItemsSaga),
    fork(getSyncSaga),
    fork(getAuditsSaga),
    fork(getBrandsSaga),
    fork(createBrandSaga),
    fork(updateBrandSaga),
    fork(deleteBrandSaga),
    fork(uploadPreviewSaga),
    fork(uploadGameZipSaga),
    fork(getCampaignsSaga),
    fork(createCampaignSaga),
    fork(deleteCampaignSaga),
    fork(updateCampaignSaga),
    fork(downloadVouchersSaga),
    fork(searchVoucherSaga),
    fork(getSeasonsSaga),
    fork(createSeasonSaga),
    fork(updateSeasonSaga),
    fork(deleteSeasonSaga),
    fork(addAllSeasonsSaga),
    fork(getSeriesSaga),
    fork(createSerieSaga),
    fork(updateSerieSaga),
    fork(deleteSerieSaga),
    fork(exportMediaSaga),
    fork(exportActiveVouchersSaga),
    fork(getMediaItemsSaga),
    fork(getPlaylistMediaItemsSaga),
  ]);
};

export default rootSaga;
