import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import { OtherMedia } from '../../components/modals';

const OtherMediaContainer = props => <OtherMedia {...props} />;

const mapStateToProps = state => ({
  isSaving: selectors.media.isSavingSelector(state),
  uploads: selectors.app.uploadsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  dismissModal: bindActionCreators(actions.app.uiActions.dismissModal, dispatch),
  createMediaItem: bindActionCreators(actions.media.uiActions.createItem, dispatch),
  updateMediaItem: bindActionCreators(actions.media.uiActions.updateItem, dispatch),
  uploadPreview: bindActionCreators(actions.media.uiActions.uploadPreview, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OtherMediaContainer);
