import { REHYDRATE } from 'redux-persist';
import { combineReducers } from 'redux';
import actions from '../actions';

const initialIsLoadingState = false;
const isLoading = (state = initialIsLoadingState, action = {}) => {
  switch (action.type) {
    case actions.brands.apiActionTypes.GET_BRANDS.REQUEST:
      return true;
    case actions.brands.apiActionTypes.GET_BRANDS.SUCCESS:
    case actions.brands.apiActionTypes.GET_BRANDS.FAILURE:
      return false;
    default:
      return state;
  }
};

const isSaving = (state = initialIsLoadingState, action = {}) => {
  switch (action.type) {
    case actions.brands.apiActionTypes.CREATE_BRAND.REQUEST:
    case actions.brands.apiActionTypes.UPDATE_BRAND.REQUEST:
    case actions.brands.apiActionTypes.DELETE_BRAND.REQUEST:
      return true;
    case actions.brands.apiActionTypes.CREATE_BRAND.SUCCESS:
    case actions.brands.apiActionTypes.CREATE_BRAND.FAILURE:
    case actions.brands.apiActionTypes.UPDATE_BRAND.SUCCESS:
    case actions.brands.apiActionTypes.UPDATE_BRAND.FAILURE:
    case actions.brands.apiActionTypes.DELETE_BRAND.SUCCESS:
    case actions.brands.apiActionTypes.DELETE_BRAND.FAILURE:
      return false;
    default:
      return state;
  }
};

const initialBrandsState = [];
const data = (state = initialBrandsState, action = {}) => {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.payload.brands && action.payload.brands.data) {
        return action.payload.brands.data;
      }
      return state;
    case actions.brands.apiActionTypes.GET_BRANDS.REQUEST:
      return initialBrandsState;
    case actions.brands.apiActionTypes.GET_BRANDS.SUCCESS:
      return action.payload.response;
    case actions.brands.apiActionTypes.CREATE_BRAND.SUCCESS: {
      return [action.payload.response, ...state];
    }
    case actions.brands.apiActionTypes.UPDATE_BRAND.SUCCESS: {
      const index = state.findIndex(i => i.name === action.payload.response.name);
      return [...state.slice(0, index), action.payload.response, ...state.slice(index + 1)];
    }
    case actions.brands.apiActionTypes.DELETE_BRAND.SUCCESS: {
      const index = state.findIndex(i => i.name === action.payload.request.name);
      return [...state.slice(0, index), ...state.slice(index + 1)];
    }
    case actions.auth.uiActionTypes.LOGOUT:
      return initialBrandsState;
    default:
      return state;
  }
};

const brands = combineReducers({
  isLoading,
  isSaving,
  data,
});

export default brands;
