import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';

import Toggle from '../../components/MediaToggle';

const SeasonToggleContainer = props => <Toggle {...props} />;

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  updateItem: bindActionCreators(actions.seasons.uiActions.updateSeason, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SeasonToggleContainer);
