import React from 'react';
import classnames from 'classnames';
import ReactSelect from 'react-select';
import styles from './Select.scss';

type SelectProps = {
  value: string,
  items: Array,
  label: string,
  id: string,
  onChange: void,
  canChooseNone: boolean,
};

const Select = ({
  items, value, onChange, id, label, canChooseNone,
}: SelectProps) => {
  const none = { value: null, label: 'Geen' };
  const selected = items.find(i => i.value === value);
  return (
    <div className={classnames(styles.root, 'form-group')}>
      <label htmlFor={id}>{label}</label>
      <ReactSelect
        id={id}
        options={[selected && canChooseNone ? none : null, ...items].filter(o => o)}
        value={selected}
        onChange={item => onChange(item.value)}
      />
    </div>
  );
};

export default Select;
