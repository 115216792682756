import React, { Component } from 'react';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { colors } from '../styles/theme';

const SortableItem = SortableElement(({
  result, styles, renderPreview, itemIdx,
}) => (
  <div style={{ ...styles.result }}>
    <div style={styles.addToPlaylist}>{itemIdx}</div>
    {renderPreview(result)}
  </div>
));

const SortableList = SortableContainer(({ results, styles, ...props }) => (
  <div style={styles.results}>
    {results.map((result, idx) => (
      <SortableItem
        key={`result-${idx}`}
        index={idx}
        itemIdx={idx + 1}
        result={result}
        styles={styles}
        {...props}
      />
    ))}
  </div>
));

export default class SortableComponent extends Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { updatePlaylist, results } = this.props;
    const updatedList = arrayMove(results, oldIndex, newIndex);
    updatePlaylist(updatedList);
  };

  render() {
    const { results, styles, ...props } = this.props;
    return <SortableList styles={styles} results={results} onSortEnd={this.onSortEnd} {...props} />;
  }
}
