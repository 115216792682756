import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import { LoginScreen } from '../../screens';

const LoginScreenContainer = props => <LoginScreen {...props} />;

const mapStateToProps = state => ({
  loginError: selectors.errors.loginSelector(state),
});

const mapDispatchToProps = dispatch => ({
  login: bindActionCreators(actions.auth.uiActions.login, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginScreenContainer);
