import React, { PureComponent } from 'react';
import voucherGenerator from 'voucher-code-generator';
import { colors } from '../../styles/theme';
import Layout from './Layout';
import DefaultModal from './DefaultModal';

class Campaign extends DefaultModal {
  constructor(props) {
    super(props, false, 'campaign');
    this.state = {
      campaignName: props.item.name || '',
      totalVouchers: props.item.totalVouchers || 0,
      campaignActive: props.item.active || false,
      campaignEmail: props.item.email || false,
      searchVoucher: '',
      showVoucherResult: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isSaving && !nextProps.isSaving) {
      this.props.dismissModal();
    }
  }

  saveCampaign() {
    const campaign = {
      name: this.state.campaignName,
      active: this.state.campaignActive,
      email: this.state.campaignEmail,
    };
    if (this.props.item.id) {
      this.props.updateCampaign(this.props.item.id, campaign);
    } else {
      if (this.state.campaignTotalVouchers > 5000000) {
        return alert(
          'The maximum number of vouchers per campaign is 50000. You can create another campaign with the same name.',
        );
      }
      this.props.createCampaign({
        ...campaign,
        totalVouchers: this.state.campaignTotalVouchers,
        vouchers: voucherGenerator.generate({
          length: 6,
          count: this.state.campaignTotalVouchers,
          charset: '123456789ABCDEFGHIJKLMNPQRSTUVWXYZ',
        }),
      });
    }
  }

  deleteCampaign(id) {
    if (confirm('Are you sure? This action can not be undone!')) {
      this.props.deleteCampaign(id);
    }
  }

  searchVoucher() {
    this.setState({ showVoucherResult: true });
    this.props.searchVoucher(this.props.item.id, this.state.searchVoucher);
  }

  renderModalActions() {
    const { item, dismissModal, isSaving } = this.props;
    return (
      <Layout.Actions
        onDelete={item.id && (() => this.deleteCampaign(item.id))}
        onCancel={() => dismissModal()}
        submmitting={isSaving}
        onSave={() => this.saveCampaign()}
      />
    );
  }

  render() {
    const { item } = this.props;
    let title = 'create campaign';
    if (Object.keys(item).length) {
      title = item.name;
    }

    return (
      <Layout title={title}>
        {this.renderProperty('name')}
        {!item.id && this.renderProperty('totalVouchers', 'number')}
        {this.renderCheckbox('active')}
        {this.renderCheckbox('email')}
        {item.totalVouchers > 0 && (
          <div
            style={styles.link}
            onClick={e => this.props.downloadVouchers(item)}
          >
            download voucher codes
          </div>
        )}
        {this.props.item.id && (
          <div style={styles.voucherSearchContainer}>
            <label htmlFor="campaign-voucher-search" style={styles.label}>
              search voucher
            </label>
            <input
              id="campaign-voucher-search"
              style={styles.input}
              type="text"
              onChange={e => this.setState({
                searchVoucher: e.target.value.toUpperCase(),
                showVoucherResult: false,
              })
              }
              value={this.state.searchVoucher}
            />
            <input type="submit" onClick={e => this.searchVoucher()} />
            {this.state.showVoucherResult && this.props.voucherDetail.code && (
              <p>{JSON.stringify(this.props.voucherDetail)}</p>
            )}
            {this.state.showVoucherResult && !this.props.voucherDetail.code && (
              <p>
                No issued voucher found with code '
                {this.state.searchVoucher}
'
              </p>
            )}
          </div>
        )}
        {this.renderModalActions()}
      </Layout>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  campaignContainer: {
    flex: 1,
  },
  modalTitle: {
    color: colors.blue,
    fontSize: 36,
    marginBottom: 20,
  },
  campaignSection: {
    backgroundColor: colors.lightgrey,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
  },
  campaignSectionHeader: {
    color: colors.grey,
    fontSize: 18,
    marginBottom: 20,
  },
  brandMetaSection: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  brandMetaField: {
    display: 'flex',
    marginRight: 5,
    marginBottom: 10,
    width: 300,
  },
  input: {
    flex: 1,
  },
  select: {
    flex: 1,
  },
  link: {
    color: colors.blue,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  label: {
    color: colors.blue,
    marginRight: 5,
    textTransform: 'capitalize',
  },
  brandName: {
    opacity: 0.6,
  },
  modalActions: {
    display: 'flex',
    // marginTop: 'auto',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: 10,
    marginTop: 10,
  },
  modalDelete: {
    color: colors.white,
    backgroundColor: colors.red,
    fontSize: 16,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 10,
    cursor: 'pointer',
    marginRight: 'auto',
  },
  modalCancel: {
    color: colors.blue,
    marginRight: 10,
    cursor: 'pointer',
  },
  modalSave: {
    fontSize: 16,
    backgroundColor: colors.blue,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 10,
    color: colors.white,
    cursor: 'pointer',
  },
  voucherSearchContainer: {
    marginTop: 20,
  },
};

export default Campaign;
