import React from 'react';
import { connect } from 'react-redux';
import selectors from '../../store/selectors';
import AppNavigator from '../../navigators/app';

const AppNavigatorContainer = ({ isLoggedIn, isLoading, error }) => (
  <AppNavigator isLoggedIn={isLoggedIn} isLoading={isLoading} error={error} />
);

const mapStateToProps = state => ({
  isLoggedIn: selectors.auth.isLoggedInSelector(state),
  isLoading: selectors.app.isLoadingSelector(state),
  error: selectors.errors.errorSelector(state),
});
export default connect(mapStateToProps)(AppNavigatorContainer);
