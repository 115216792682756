import React from 'react';
import { v4 as uuid } from 'uuid';
import { UploadContainer } from '../../containers/components';
import {
  brandLanguages,
  brandAges,
  brandTargets,
} from '../../config/tables/brands';
import Layout from './Layout';
import DefaultModal, { DefaultModalProps } from './DefaultModal';

type BrandModalProps = DefaultModalProps & {};

class Brand extends DefaultModal<BrandModalProps> {
  constructor(props) {
    super(props, false, 'brand');
    const { item } = this.props;
    const original = (item || {}).original || {};
    this.state = {
      ...this.state,
      brandName: original.name || '',
      brandSyncName: original.syncName || '',
      brandLanguage: original.language || 'NL',
      preview: original.preview,
      brandOrder: original.order || 99,
      brandAge: +(original.age || 0),
      brandTarget: original.target || 'mix',
      brandSyncQuery: original.syncQuery || '',
      brandTitle: original.displayName || '',
      bannerImage: original.bannerImage,
      brandFemininity: original.femininity ? original.femininity * 100 : 0,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  getImageUploadConversions(url, stateField) {
    return {
      small: { filename: url.replace('.original.', '.medium-small.') },
      medium: { filename: url.replace('.original.', '.medium.') },
      large: { filename: url.replace('.original.', '.large.') },
    };
  }

  componentWillReceiveProps(nextProps) {
    super.componentWillReceiveProps(nextProps);
    if (this.props.isSaving && !nextProps.isSaving) {
      this.props.dismissModal();
    }
  }

  saveBrand() {
    const brand = {
      syncName: this.state.brandSyncName,
      preview: this.state.preview,
      language: this.state.brandLanguage,
      order: parseInt(this.state.brandOrder, 10) || 99,
      age: this.state.brandAge,
      target: this.state.brandTarget,
      syncQuery: this.state.brandSyncQuery.length
        ? this.state.brandSyncQuery
        : null,
      displayName: this.state.brandTitle,
      bannerImage: this.state.bannerImage,
      femininity: (+this.state.brandFemininity || 0) / 100,
    };
    console.log(brand);
    if (this.props.item.name) {
      this.props.updateBrand(this.state.brandName, brand);
    } else if (this.state.brandName.length > 0) {
      this.props.createBrand(this.state.brandName, { ...brand, id: uuid() });
    } else {
      alert('Please provide brand name and image!');
    }
  }

  deleteBrand(name) {
    if (window.confirm('Are you sure? This action can not be undone!')) {
      this.props.deleteBrand(name);
    }
  }

  renderModalActions() {
    const { item, dismissModal, isSaving } = this.props;
    return (
      <Layout.Actions
        disabled={this.isProcessingUploads()}
        onDelete={item.name && (() => this.deleteBrand(item.name))}
        onCancel={() => dismissModal()}
        submmitting={isSaving}
        onSave={() => this.saveBrand()}
      />
    );
  }

  render() {
    const { uploadPreview } = this.props;
    return (
      <Layout title="Brand" subtitle={this.state.brandName}>
        {!this.props.item.name && this.renderProperty('name')}
        {this.renderProperty('title')}

        <label>Icon</label>
        {this.renderPreviewField(uploadPreview, { uploadPath: 'assets/brands', stateField: 'preview' })}

        <label>Banner</label>
        {this.renderPreviewField(uploadPreview, { uploadPath: 'assets/brands/banner', stateField: 'bannerImage' })}

        {this.renderSelect(
          'Language',
          this.state.brandLanguage,
          brandLanguages,
        )}
        {this.renderProperty('syncName', { label: 'Sync name' })}
        {this.renderProperty('order', { type: 'number' })}
        {this.renderProperty('Age', { type: 'number', inputProps: { step: 1, min: 0, max: 20 } })}
        {this.renderProperty('femininity', { type: 'number', inputProps: { step: 1, min: 0, max: 100 }, label: 'Femininity (0-100%)' })}
        {this.renderSelect('Target', this.state.brandTarget, brandTargets)}
        {this.renderProperty('SyncQuery', { label: 'Brand sync query' })}
        {this.renderModalActions()}
      </Layout>
    );
  }
}

export default Brand;
