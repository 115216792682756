import React from 'react';
import { createSwitchNavigator } from '@react-navigation/core';
import { createBrowserApp } from '@react-navigation/web';

import {
  LoginScreenContainer,
  HomeScreenContainer,
  BrandsScreenContainer,
  SyncScreenContainer,
  CampaignsScreenContainer,
  SeasonsScreenContainer,
  SeriesScreenContainer,
  AuditsScreenContainer,
} from '../containers/screens';

const Navigator = createSwitchNavigator(
  {
    /* Login: {
      screen: LoginScreenContainer,
      path: 'login',
    }, */
    Brands: {
      screen: BrandsScreenContainer,
      path: 'brands',
      navigationOptions: () => ({
        title: 'Studio 100 admin - brands',
      }),
    },
    Sync: {
      screen: SyncScreenContainer,
      path: 'sync',
      navigationOptions: () => ({
        title: 'Studio 100 admin - sync',
      }),
    },
    Campaigns: {
      screen: CampaignsScreenContainer,
      path: 'campaigns',
      navigationOptions: () => ({
        title: 'Studio 100 admin - campaigns',
      }),
    },
    Series: {
      screen: SeriesScreenContainer,
      path: 'series',
      navigationOptions: () => ({
        title: 'Studio 100 admin - series',
      }),
    },
    Seasons: {
      screen: SeasonsScreenContainer,
      path: 'seasons',
      navigationOptions: () => ({
        title: 'Studio 100 admin - seasons',
      }),
    },
    Audits: {
      screen: AuditsScreenContainer,
      path: 'audits',
      navigationOptions: () => ({
        title: 'Studio 100 admin - audit logs',
      }),
    },
    Home: {
      screen: HomeScreenContainer,
      path: '',
      navigationOptions: () => ({
        title: 'Studio 100 admin - content',
      }),
    },
  },
  {
    initialRouteName: 'Home',
  },
);

const AppContainer = createBrowserApp(Navigator);

class AppNavigator extends React.Component<{ isLoggedIn: boolean }> {
  render() {
    const { isLoggedIn } = this.props;

    if (!isLoggedIn) {
      return <LoginScreenContainer />;
    }
    return <AppContainer ref={this.navigation} />;
  }
}

export default AppNavigator;
