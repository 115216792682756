import React, { PureComponent } from 'react';
import Select from './Form/Select';

class BrandsSelect extends PureComponent {
  componentWillMount() {
    this.props.getBrands();
  }

  onChange(value) {
    this.props.onChange(value);
  }


  getBrandOptions = (brands) => {
    const brandOptions = [];
    brands.forEach((brand) => {
      brandOptions.push({
        value: brand.name,
        label: brand.name,
      });
    });

    return brandOptions;
  }

  render() {
    const languageBrands = this.props.brands.filter(b => b.language === this.props.language);
    const brands = languageBrands.map(b => ({ name: b.name }));
    const brandOptions = this.getBrandOptions(brands);
    const { label } = this.props;

    return (
      <div>
        <Select
          value={this.props.value}
          items={brandOptions}
          label={label || 'Brand'}
          id="category-filter"
          onChange={e => this.onChange(e)}
        />
      </div>
    );

    /*

      <select style={this.props.style} onChange={e => this.onChange(e)} value={this.props.value}>
        {brands.map(this.renderBrandOption.bind(this))}
      </select>

      */
  }
}

export default BrandsSelect;
