import { createSelector } from 'reselect';

export const isLoadingSelector = createSelector(
  state => state.series.isLoading,
  isLoading => isLoading,
);

export const isSavingSelector = createSelector(
  state => state.series.isSaving,
  isSaving => isSaving,
);

export const seriesSelector = createSelector(
  state => state.series.data,
  series => series,
);
