import { takeLatest, call, select } from 'redux-saga/effects';
import { apiCall } from './util';
import actions from '../actions';
import selectors from '../selectors';
import { api } from '../services';
// bind api calls to actions

const getSeasonsApiCall = apiCall.bind(
  null,
  actions.seasons.apiActions.getSeasons,
  api.getSeasons,
);
const createSeasonApiCall = apiCall.bind(
  null,
  actions.seasons.apiActions.createSeason,
  api.createSeason,
);
const updateSeasonApiCall = apiCall.bind(
  null,
  actions.seasons.apiActions.updateSeason,
  api.updateSeason,
);
const deleteSeasonApiCall = apiCall.bind(
  null,
  actions.seasons.apiActions.deleteSeason,
  api.deleteSeason,
);
const addAllSeasonsApiCall = apiCall.bind(
  null,
  actions.seasons.apiActions.addAllSeasons,
  api.addAllSeasons,
);

const getSeasonItems = function*(action, props) {
  const token = yield select(selectors.auth.tokenSelector);
  yield call(getSeasonsApiCall, { token, query: action.payload.query });
};

export const getSeasonsSaga = function*() {
  yield takeLatest(actions.seasons.uiActionTypes.GET_SEASONS, getSeasonItems);
};

const createSeason = function*(action) {
  const { season = {}, id } = action.payload;
  const token = yield select(selectors.auth.tokenSelector);
  yield call(createSeasonApiCall, { token, season });
};

export const createSeasonSaga = function*() {
  yield takeLatest(actions.seasons.uiActionTypes.CREATE_SEASON, createSeason);
};

const updateSeason = function*(action) {
  const { season = {}, id } = action.payload;
  const token = yield select(selectors.auth.tokenSelector);
  yield call(updateSeasonApiCall, { token, season, id });
};

export const updateSeasonSaga = function*() {
  yield takeLatest(actions.seasons.uiActionTypes.UPDATE_SEASON, updateSeason);
};

const deleteSeason = function*(action) {
  const { id } = action.payload;
  const token = yield select(selectors.auth.tokenSelector);
  yield call(deleteSeasonApiCall, { token, id });
};

export const deleteSeasonSaga = function*() {
  yield takeLatest(actions.seasons.uiActionTypes.DELETE_SEASON, deleteSeason);
};

const addAllSeasons = function*() {
  const token = yield select(selectors.auth.tokenSelector);
  yield call(addAllSeasonsApiCall, { token });
};

export const addAllSeasonsSaga = function*() {
  yield takeLatest(
    actions.seasons.uiActionTypes.ADD_ALL_SEASONS,
    addAllSeasons,
  );
};
