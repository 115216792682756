import React from 'react';

import TableHeaderCell from './TableHeaderCell';
import TableRow from './TableRow';

const directions = ['asc', 'desc', null];

const TableHeaders = ({
  activeFilters,
  columns,
  withActions,
  onFiltersChanged,
  withCheckboxes,
  onToggleAll,
  allSelected,
}) => {
  const changeSortOrder = (key) => {
    const filters = { ...activeFilters };
    let direction = directions[0];
    if (filters['sort-by'] === key) {
      const index = directions.indexOf(filters['sort-direction']);
      direction = directions[index + 1];
    }
    if (!direction) {
      filters['sort-by'] = null;
      filters['sort-direction'] = null;
    } else {
      filters['sort-by'] = key;
      filters['sort-direction'] = direction;
    }

    onFiltersChanged(filters);
  };
  const renderHeaderItem = (item) => {
    const Component = item.sortable ? TableHeaderCell.Sortable : TableHeaderCell;
    const props = {};
    if (item.className) {
      props.className = item.className;
    }
    if (item.sortable) {
      props.onClick = () => {
        changeSortOrder(item.prop);
      };

      props.direction = activeFilters['sort-by'] === item.prop ? activeFilters['sort-direction'] : null;
    }

    const keySuffix = 'prop' in item ? item.prop : item.action;
    return (
      <Component key={`table-header-${keySuffix}`} className="taxt-left" {...props}>
        {item.label}
      </Component>
    );
  };
  return (
    <TableRow>
      {withCheckboxes && (
        <TableHeaderCell>
          <input type="checkbox" onChange={() => onToggleAll()} checked={allSelected} />
        </TableHeaderCell>
      )}
      {columns && columns.map(renderHeaderItem)}
      {withActions && <TableHeaderCell>actions</TableHeaderCell>}
    </TableRow>
  );
};
TableHeaders.defaultProps = {
  activeFilters: {},
};
export default TableHeaders;
