import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import buildPager from './utils/buildPager';

class PaginationControlBar extends React.Component {
  state = {
    gotoValue: '',
  };

  handleGoto = (e) => {
    if (e.keyCode === 13) {
      let page = +e.target.value;
      if (Number.isNaN(page)) {
        return;
      }

      const {
        onPageChanged, totalItems, perPage, getLinkForPage, linkTemplate,
      } = this.props;
      const pageCount = Math.ceil(totalItems / perPage);
      if (page <= 0) {
        page = 1;
      } else if (page > pageCount) {
        page = pageCount;
      }

      this.setState({ gotoValue: page });
      if (onPageChanged) {
        onPageChanged(page, perPage);
      } else {
        window.location = getLinkForPage(page, linkTemplate);
      }
    }
  };

  renderLimitControls() {
    const {
      translations,
      onPerPageChanged,
      perPageOptions,
      perPage,
      activePage,
      perPageOptionsClassName,
    } = this.props;

    return (
      <div className={classnames('form-group', perPageOptionsClassName)}>
        {translations.perPage ? (
          <label className="limit-label" htmlFor="pcb-perpage">
            <span>
              {translations.perPage}
              :&nbsp;
            </span>
          </label>
        ) : null}
        <div className="custom-select">
          <select
            id="pcb-perpage"
            className="form-control"
            defaultValue={perPage}
            onChange={e => onPerPageChanged(e.target.value, activePage)}
          >
            {perPageOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.title}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }

  renderPager() {
    const {
      activePage,
      totalItems,
      perPage,
      onPageChanged,
      translations,
      getLinkForPage,
      linkTemplate,
      maxLinks,
      numberFormatter,
    } = this.props;

    const pageCount = Math.ceil(totalItems / perPage);
    const hasPrevious = activePage > 1;
    const hasNext = activePage < pageCount;

    const pages = buildPager({
      activePage,
      pageCount,
      gap: translations.gap,
      maxLinks,
    });

    return (
      <div className="pagination-wrapper">
        <ul className="pagination pagination-sm">
          {hasPrevious ? (
            <li className="page-item">
              <a
                className="page-link"
                href={getLinkForPage(activePage - 1, linkTemplate)}
                aria-label={translations.previous}
                onClick={(e) => {
                  if (onPageChanged) {
                    e.preventDefault();
                    onPageChanged(activePage - 1, perPage);
                  }
                }}
              >
                <span aria-hidden="true">
                  <i className="fa fa-chevron-left" />
                </span>
                &nbsp;
                <span>{translations.previous}</span>
              </a>
            </li>
          ) : null}

          {pages.map((option) => {
            const link = getLinkForPage(option, linkTemplate);
            const inner = option === translations.gap ? (
              <span className="page-link">{translations.gap}</span>
            ) : (
              <a
                className="page-link"
                href={link}
                onClick={(e) => {
                  if (onPageChanged) {
                    e.preventDefault();
                    onPageChanged(option, perPage);
                  }
                }}
              >
                {numberFormatter(option)}
              </a>
            );
            return (
              <li
                key={option}
                className={classnames('page-item', {
                  active: +option === +activePage,
                  spacer: option === translations.gap,
                })}
              >
                {inner}
              </li>
            );
          })}

          {hasNext ? (
            <li className="page-item">
              <a
                className="page-link"
                href={getLinkForPage(activePage + 1, linkTemplate)}
                aria-label={translations.next}
                onClick={(e) => {
                  if (onPageChanged) {
                    e.preventDefault();
                    onPageChanged(activePage + 1, perPage);
                  }
                }}
              >
                <span>{translations.next}</span>
                &nbsp;
                <span aria-hidden="true">
                  <i className="fa fa-chevron-right" />
                </span>
              </a>
            </li>
          ) : null}
        </ul>
      </div>
    );
  }

  renderGotoInput() {
    const { translations } = this.props;

    return (
      <div className="goto-input-wrapper">
        <label htmlFor="pcb-gotoinput">
          {translations.goto}
          <input
            id="pcb-gotoinput"
            type="text"
            onKeyDown={this.handleGoto}
            value={this.state.gotoValue}
            onChange={e => this.setState({ gotoValue: e.target.value })}
          />
        </label>
      </div>
    );
  }

  render() {
    const {
      perPageOptions,
      translations,
      totalItems,
      activePage,
      perPage,
      hideIfOnePage,
      className,
      numberFormatter,
      withGotoInput,
      showInfo,
    } = this.props;

    if (totalItems === 0 || (hideIfOnePage && totalItems <= perPage)) {
      return null;
    }
    const start = (activePage - 1) * perPage;
    const end = Math.min(start + perPage, totalItems);

    return (
      <div className={className}>
        <nav
          style={{
            padding: 10,
            paddingBottom: 0,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          {perPageOptions ? this.renderLimitControls() : null}
          {showInfo && (
            <div className="page-info-wrapper">
              <div className="page-count">
                <span className="page-count-current">
                  {numberFormatter(start + 1)}
                  {' '}
-
                  {numberFormatter(end)}
                </span>
                &nbsp;
                {translations.of}
                &nbsp;
                <span className="page-count-total">{numberFormatter(totalItems)}</span>
              </div>
              &nbsp;
            </div>
          )}
          {this.renderPager()}
          {withGotoInput ? this.renderGotoInput() : null}
        </nav>
      </div>
    );
  }
}
PaginationControlBar.propTypes = {
  totalItems: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  perPage: PropTypes.number,
  perPageOptions: PropTypes.arrayOf(PropTypes.object),
  onPerPageChanged: PropTypes.func,
  onPageChanged: PropTypes.func,
  maxLinks: PropTypes.number,
  getLinkForPage: PropTypes.func,
  linkTemplate: PropTypes.string,
  showInfo: PropTypes.bool,
  hideIfOnePage: PropTypes.bool,
  translations: PropTypes.shape({
    gap: PropTypes.string,
    perPage: PropTypes.string,
    of: PropTypes.string,
    previous: PropTypes.string,
    next: PropTypes.string,
    goto: PropTypes.string,
  }),
  className: PropTypes.string,
  perPageOptionsClassName: PropTypes.string,
  numberFormatter: PropTypes.func,
  withGotoInput: PropTypes.bool,
};
PaginationControlBar.defaultProps = {
  perPage: 10,
  perPageOptions: null,
  onPerPageChanged: null,
  onPageChanged: null,
  linkTemplate: '',
  showInfo: true,
  hideIfOnePage: true,
  maxLinks: 7,
  getLinkForPage: (page, linkTemplate) => linkTemplate.replace('$p', page),
  translations: {
    gap: '..',
    perPage: 'per page',
    of: 'of',
    previous: 'Previous',
    next: 'Next',
    goto: 'Goto',
  },
  className: 'pagination-controls-bar',
  perPageOptionsClassName: 'pcb-per-page-options',
  numberFormatter: num => num,
  withGotoInput: false,
};
export default PaginationControlBar;
