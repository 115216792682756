import React from 'react';
import AuditsDifference from '../../components/AuditsDifference';
import { formatDate } from '../../utils/date';

const SOURCE_LABEL = {
  api: 'admin',
  'api:resyncSyncEntry': 'admin:resync',
  'cronjob:setPublishedMedia': 'worker:setPublishedMedia',
  'cronjob:setUnlockedMedia': 'worker:setUnlockedMedia',
  'cronjob:resetExportedNewOrPromotedMedia': 'worker:resetExportedNewOrPromotedMedia',
};

export default {
  paginated: true,
  columns: [
    {
      prop: 'timestamp',
      label: 'Timestamp',
    },
    {
      prop: 'type',
      label: 'Event & source',
    },
    {
      prop: 'objectId',
      label: 'Object ID',
    },
    {
      prop: 'tableName',
      label: 'Object Type',
    },
    {
      prop: 'diff',
      label: 'Difference',
      className: 'text-center',
    },
  ],
  transformer: row => ({
    id: row.id,
    timestamp: `${formatDate(row.timestamp)}`,
    type: <>{row.type}<br />{SOURCE_LABEL[row.source] || '-'}</>,
    tableName: [row.tableName, row.images?.new?.category, row.images?.new?.subCategory !== row.images?.new?.category && row.images?.new?.subCategory].filter(n => n).join(':'),
    objectId: <>{row.contentId}<br /><strong>{row.images?.new?.title }</strong></>,
    diff: row.images?.new && row.images?.old ? <AuditsDifference before={row.images.old} after={row.images.new} /> : null,
  }),
};
