import React, { PureComponent } from 'react';
import SeriesTableContainer from '../containers/components/SeriesTable';
import LayoutContainer from '../containers/components/Layout';

type SeriesScreenProps = {
  getSeries: void,
};

class SeriesScreen extends PureComponent<SeriesScreenProps> {
  render() {
    return (
      <LayoutContainer title="Series">
        <SeriesTableContainer />
      </LayoutContainer>
    );
  }
}

export default SeriesScreen;
