import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import { HomeScreen } from '../../screens';

const HomeScreenContainer = props => <HomeScreen {...props} />;

const mapStateToProps = state => ({
  user: selectors.auth.userSelector(state),
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomeScreenContainer);
