import React from 'react';
import styles from './Search.scss';

const Search = ({
  value, onChange, placeholder, label, id,
}) => (
  <div className={styles.root}>
    <label htmlFor={id}>{label}</label>
    <input
      id={id}
      type="text"
      className="form-control"
      onChange={onChange}
      placeholder={placeholder}
    />
  </div>
);
export default Search;
