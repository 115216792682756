import React from 'react';
import ReactJson from 'react-json-view';
import Layout from './Layout';
import DefaultModal, { DefaultModalProps } from './DefaultModal';

type JsonModalProps = DefaultModalProps & {};

class Json extends DefaultModal<JsonModalProps> {
  constructor(props) {
    super(props, false, 'json');
  }

  render() {
    const { item } = this.props;
    const { name } = item;
    let { json } = item;

    if (Array.isArray(json) && json.length === 1) {
      [json] = json;
    }

    return (
      <Layout title={name}>
        <ReactJson collapsed={2} name={false} src={json} />
      </Layout>
    );
  }
}

export default Json;
