import { createSelector } from 'reselect';

export const statusSelector = createSelector(
  state => state.app.status,
  status => status,
);

export const isLoadingSelector = createSelector(
  statusSelector,
  status => status === 'loading',
);

export const modalSelector = createSelector(
  state => state.app.modal,
  modal => modal,
);

export const uploadsSelector = createSelector(
  state => state.app.uploads,
  uploads => uploads,
);
