import React, { PureComponent } from 'react';
import campaignsTableConfig from '../config/tables/campaigns';
import Table from './Table';
import FilterWrapper from './Filters/Wrapper';

type CampaignsTableProps = {
  campaigns: Array,
  showModal: void,
  loading: boolean,
  exportActiveVouchers: void,
};

class CampaignsTable extends PureComponent<CampaignsTableProps> {
  state = {};

  getCampaigns() {
    const { campaigns } = this.props;
    return campaigns;
  }

  handleButtonClick = (action, item) => {
    const { showModal } = this.props;

    showModal({ name: 'Campaign', item });
  };

  createCampaign = (e, item = {}) => {
    const { showModal } = this.props;
    showModal({ name: 'Campaign', item });
  };

  render() {
    const { loading, exportActiveVouchers } = this.props;
    return (
      <div>
        <div className="d-flex justify-content-end">
          <button className="btn btn-secondary mr-2" onClick={e => this.createCampaign(e, {})}>
            Create campaign
          </button>
          <button className="btn btn-primary" onClick={exportActiveVouchers}>
            Export used vouchers
          </button>
        </div>
        <FilterWrapper />
        <Table
          items={this.getCampaigns()}
          loading={loading}
          onAction={this.handleButtonClick}
          {...campaignsTableConfig}
          paginated
        />
      </div>
    );
  }
}

export default CampaignsTable;
