import { createSelector } from 'reselect';
import actions from '../actions';

export const loginSelector = createSelector(
  state => state.errors.data,
  errors => errors.find(error => error.type === actions.auth.apiActionTypes.LOGIN.FAILURE),
);

export const errorSelector = createSelector(
  state => state.errors.data,
  errors => errors[errors.length - 1],
);
