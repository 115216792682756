import { REHYDRATE } from 'redux-persist';
import { combineReducers } from 'redux';
import actions from '../actions';

const initialIsLoadingState = false;
const isLoading = (state = initialIsLoadingState, action = {}) => {
  switch (action.type) {
    case actions.seasons.apiActionTypes.GET_SEASONS.REQUEST:
      return true;
    case actions.seasons.apiActionTypes.GET_SEASONS.SUCCESS:
    case actions.seasons.apiActionTypes.GET_SEASONS.FAILURE:
      return false;
    default:
      return state;
  }
};

const isSaving = (state = initialIsLoadingState, action = {}) => {
  switch (action.type) {
    case actions.seasons.apiActionTypes.CREATE_SEASON.REQUEST:
    case actions.seasons.apiActionTypes.UPDATE_SEASON.REQUEST:
    case actions.seasons.apiActionTypes.DELETE_SEASON.REQUEST:
      return true;
    case actions.seasons.apiActionTypes.CREATE_SEASON.SUCCESS:
    case actions.seasons.apiActionTypes.CREATE_SEASON.FAILURE:
    case actions.seasons.apiActionTypes.UPDATE_SEASON.SUCCESS:
    case actions.seasons.apiActionTypes.UPDATE_SEASON.FAILURE:
    case actions.seasons.apiActionTypes.DELETE_SEASON.SUCCESS:
    case actions.seasons.apiActionTypes.DELETE_SEASON.FAILURE:
      return false;
    default:
      return state;
  }
};

const initialSeasonsState = [];
const data = (state = initialSeasonsState, action = {}) => {
  const { items } = state;

  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.payload.seasons && action.payload.seasons.data) {
        return action.payload.seasons.data;
      }
      return state;
    case actions.seasons.apiActionTypes.GET_SEASONS.REQUEST:
      return initialSeasonsState;
    case actions.seasons.apiActionTypes.GET_SEASONS.SUCCESS:
      return action.payload.response;
    case actions.seasons.apiActionTypes.CREATE_SEASON.SUCCESS: {
      return [action.payload.response, ...state];
    }
    case actions.seasons.apiActionTypes.ADD_ALL_SEASONS.SUCCESS: {
      return [...action.payload.response];
    }
    case actions.seasons.apiActionTypes.UPDATE_SEASON.SUCCESS: {
      const index = items.findIndex(i => i.id === action.payload.response.id);
      return {
        ...state,
        items: [...items.slice(0, index), action.payload.response, ...items.slice(index + 1)],
      };
    }
    case actions.seasons.apiActionTypes.DELETE_SEASON.SUCCESS: {
      const index = items.findIndex(i => i.id === action.payload.request.id);
      return {
        ...state,
        items: [...items.slice(0, index), ...items.slice(index + 1)],
      };
    }
    case actions.auth.uiActionTypes.LOGOUT:
      return initialSeasonsState;
    default:
      return state;
  }
};

const seasons = combineReducers({
  isLoading,
  isSaving,
  data,
});

export default seasons;
