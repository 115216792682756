import {
  createUiActionType, createUiAction, createApiActionTypes, createApiActions,
} from './util';

export const uiActionTypes = {
  GET_SEASONS: createUiActionType('SEASONS/GET_SEASONS'),
  CREATE_SEASON: createUiActionType('SEASONS/CREATE_SEASON'),
  UPDATE_SEASON: createUiActionType('SEASONS/UPDATE_SEASON'),
  DELETE_SEASON: createUiActionType('SEASONS/DELETE_SEASON'),
  ADD_ALL_SEASONS: createUiActionType('SEASONS/ADD_ALL_SEASONS'),
};

export const uiActions = {
  getSeasons: query => createUiAction(uiActionTypes.GET_SEASONS, { query }),
  createSeason: season => createUiAction(uiActionTypes.CREATE_SEASON, { season }),
  updateSeason: (id, season) => createUiAction(uiActionTypes.UPDATE_SEASON, { id, season }),
  deleteSeason: id => createUiAction(uiActionTypes.DELETE_SEASON, { id }),
  addAllSeasons: () => createUiAction(uiActionTypes.ADD_ALL_SEASONS, {}),
};

export const apiActionTypes = {
  GET_SEASON: createApiActionTypes('SEASONS/GET_SEASON'),
  GET_SEASONS: createApiActionTypes('SEASONS/GET_SEASONS'),
  CREATE_SEASON: createApiActionTypes('SEASONS/CREATE_SEASON'),
  UPDATE_SEASON: createApiActionTypes('SEASONS/UPDATE_SEASON'),
  DELETE_SEASON: createApiActionTypes('SEASONS/DELETE_SEASON'),
  ADD_ALL_SEASONS: createApiActionTypes('SEASONS/ADD_ALL_SEASONS'),
};

export const apiActions = {
  getSeason: createApiActions(apiActionTypes.GET_SEASON),
  getSeasons: createApiActions(apiActionTypes.GET_SEASONS),
  createSeason: createApiActions(apiActionTypes.CREATE_SEASON),
  updateSeason: createApiActions(apiActionTypes.UPDATE_SEASON),
  deleteSeason: createApiActions(apiActionTypes.DELETE_SEASON),
  addAllSeasons: createApiActions(apiActionTypes.ADD_ALL_SEASONS),
};
