import React, { Component } from 'react';
import Select from 'react-select';

class MultiBrandsSelect extends Component {
  componentWillMount() {
    const { getBrands } = this.props;
    const brands = getBrands();
  }

  onChange(multiBrands) {
    const { onChange } = this.props;
    const newMultiBrandsArray = multiBrands.map(multiBrand => multiBrand.value);
    onChange(newMultiBrandsArray);
  }

  componentDidUpdate(newProps) {
    const { mainBrand, value, onChange } = this.props;
    if (mainBrand !== newProps.mainBrand) {
      const updatedMultiBrands = value.filter(v => v !== mainBrand);
      onChange(updatedMultiBrands);
    }
  }

  itemToOption(item) {
    return { value: item.name, label: item.name };
  }

  valueToOption(value) {
    return { value, label: value };
  }

  filterBrands(brands, language, mainBrand) {
    let brandsFilteredByLanguage = [];
    if (mainBrand === 'all') {
      const [brandToRemove, ...remainingBrands] = brands
        .filter(b => b.language === language)
        .map(b => this.itemToOption(b));
      brandsFilteredByLanguage = [...remainingBrands];
    } else {
      brandsFilteredByLanguage = brands
        .filter(b => b.language === language)
        .filter(b => b.name !== mainBrand)
        .map(b => this.itemToOption(b));
    }
    return brandsFilteredByLanguage;
  }

  render() {
    const {
      brands, value, language, style, mainBrand,
    } = this.props;
    const brandsFilteredByLanguage = this.filterBrands(brands, language, mainBrand);
    const formattedValues = value.map(v => this.valueToOption(v));
    return (
      <div className="form-group">
        <label>Multi-brands</label>
        <Select
          onChange={this.onChange.bind(this)}
          value={formattedValues}
          options={brandsFilteredByLanguage}
          closeMenuOnSelect={false}
          isMulti
          isSearchable
        />
      </div>
    );
  }
}

export default MultiBrandsSelect;
