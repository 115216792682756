import React from 'react';
import JSZip from 'jszip';
import {
  UploadContainer,
  BrandsSelectContainer,
  MultiBrandsSelectContainer,
} from '../../containers/components';
import formatAllBrandByLanguage from '../../utils/brand';
import { colors } from '../../styles/theme';
import DefaultModal from './DefaultModal';
import Layout from './Layout';
import Select from '../Form/Select';
import { findGameItem, getMediaItem as getApiMediaItem } from '../../store/services/api';

class Game extends DefaultModal {
  constructor(props) {
    super(props, true, 'game');
    const {
      id,
      gameId,
      title,
      downloadUrl,
      language,
      resources,
      preview,
      brand,
      published,
      multiBrands,
    } = this.media;
    this.state = {
      ...this.state,
      id,
      gameId: gameId || id,
      gameTitle: title || '',
      zipUploading: false,
      zipUpload: {
        url: downloadUrl,
      },
      preview,
      gameLanguage: language || 'NL',
      gameIndex: resources || '',
      brand: brand || 'all-nl',
      published: published || false,
      multiBrands: multiBrands || [],
    };
  }


  async saveGame() {
    const {
      gameId,
      id,
      zipUpload,
      gameIndex,
      gameTitle,
      isPreviewUploading,
      preview,
    } = this.state;
    const { token } = this.props;

    if (!zipUpload || !zipUpload.url) {
      return alert('We need a valid zip upload first!');
    }
    if (!gameIndex || !gameIndex.length > 0) {
      return alert('No valid index.html for game found.');
    }
    if (!gameTitle || !gameTitle.length > 0) {
      return alert('Please provide a game title!');
    }
    if (isPreviewUploading || !preview) {
      return alert('Wait till preview is uploaded');
    }

    // Just making sure there isn't already a game with this game ID
    const gameResult = await findGameItem({ token, gameId });
    if (gameResult.response?.id && gameResult.response?.id !== id) {
      // Id found and it's not the same as ours
      return alert('A game already exists with this game ID');
    }

    // We also don't wan't the game ID to be the same as an existing ID
    const mediaResult = await getApiMediaItem({ token, id: gameId });
    if (mediaResult.response?.id && mediaResult.response?.id !== id) {
      return alert('A media item already exists with the provided game ID as ID');
    }

    const item = {
      title: this.state.gameTitle,
      resources: this.state.gameIndex,
      downloadUrl: this.state.zipUpload.url,
      language: this.state.gameLanguage,
      category: 'games',
      subCategory: 'games',
      brand: this.state.brand,
      new: false,
      published: this.state.published,
      locked: false,
      preview: this.state.preview,
      multiBrands: this.state.multiBrands,
      ...this.getStateValuesDefaultFields(),
    };

    if (this.state.id) {
      this.props.updateMediaItem(this.state.id, item);
    } else {
      this.props.createMediaItem({
        ...item,
        id: item.gameId,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    super.componentWillReceiveProps(nextProps);

    if (this.state.uploadKey && nextProps.uploads[this.state.uploadKey]) {
      const state = {
        zipUploading: nextProps.uploads[this.state.uploadKey].loading,
        zipUpload: nextProps.uploads[this.state.uploadKey].upload || {},
      };
      if (state.zipUpload.url) {
        state.gameIndex = state.zipUpload.url.replace(
          '.zip',
          `/${this.state.topDirName}/index.html`,
        );
      }
      this.setState(state);
    }

    if (this.props.isSaving && !nextProps.isSaving) {
      this.props.dismissModal();
    }
  }

  uploadZip(zipFile) {
    const zip = new JSZip();
    zip
      .loadAsync(zipFile)
      .then((zipContents) => {
        const dir = Object.values(zipContents.files).find(f => f.dir === true);
        const topDirName = dir && dir.name ? dir.name.split('/')[0] : '';
        this.setState({
          gameTitle: topDirName,
          uploadKey: zipFile.preview,
          topDirName,
        });
        this.props.uploadGameZip(zipFile);
      })
      .catch((err) => {
        console.error(err);
        alert(
          'Ooops. Parsing zip file went wrong... Are you sure this is a valid .zip archive?',
        );
        this.setState({ zipUploading: false });
      });
  }

  renderZip() {
    const zipUrl = (this.state.zipUpload || {}).url || '';
    if (zipUrl && zipUrl.length > 0) {
      return (
        <div>
          <div
            style={styles.resetZip}
            onClick={e => this.setState({ zipUpload: {}, gameIndex: '' })}
          >
            reset
          </div>
          <a style={styles.zipUrl} target="_blank" href={zipUrl}>
            {zipUrl}
          </a>
        </div>
      );
    }

    return (
      <UploadContainer
        style={{ marginTop: 10, marginBottom: 10, width: 300 }}
        preview={zipUrl}
        dropText="Drop a zip archive here"
        onFileChange={zip => this.uploadZip(zip)}
        loading={this.state.zipUploading}
      />
    );
  }

  renderModalActions() {
    const { dismissModal, isSaving } = this.props;
    return (
      <Layout.Actions
        onCancel={() => dismissModal()}
        submmitting={isSaving}
        onSave={() => this.saveGame()}
      />
    );
  }

  render() {
    const { uploadPreview } = this.props;
    const zipUrl = (this.state.zipUpload || {}).url || '';
    const { description, id, category } = this.media;

    let title = 'create game';
    if (Object.keys(this.media).length) {
      title = this.state.gameTitle;
    }

    const { gameLanguage, brand, multiBrands } = this.state;
    return (
      <Layout
        title={title}
        category={category}
        subtitle={id}
        description={description}
      >
        {this.renderProperty('gameId', {
          stateWithPrefix: false,
          label: 'Game ID',
        })}
        {this.renderCommonFields()}
        <label>Zip upload</label>
        {this.renderZip()}

        {this.state.gameIndex && this.state.gameIndex.length > 0 && (
          <div style={styles.gameSection}>
            <div>
              <label>Game preview</label>
            </div>
            <iframe src={this.state.gameIndex} style={styles.gameIframe} />
          </div>
        )}
        <div>
          <div>
            <label>Game screenshot</label>
          </div>

          {this.renderPreviewField(uploadPreview)}
          <div>
            <Select
              value={this.state.gameLanguage}
              items={[
                { value: 'NL', label: 'NL' },
                { value: 'FR', label: 'FR' },
              ]}
              label="Language"
              id="game-language"
              onChange={value => this.setState({ gameLanguage: value, brand: formatAllBrandByLanguage(this.state.brand, value) })}
            />
          </div>
          {this.renderProperty('title', { label: 'Title' })}
          <div>
            <BrandsSelectContainer
              id="game-distribution"
              style={styles.select}
              label="Distribution"
              language={this.state.gameLanguage}
              value={this.state.brand}
              onChange={brand => this.setState({ brand })}
            />
          </div>
        </div>
        <MultiBrandsSelectContainer
          id="multibrands-game"
          language={gameLanguage}
          mainBrand={brand}
          value={multiBrands}
          onChange={brands => this.setState({ multiBrands: brands })}
        />
        {this.renderDefaultFields()}
        {this.renderModalActions()}
      </Layout>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  gameContainer: {
    flex: 1,
  },
  modalTitle: {
    color: colors.blue,
    fontSize: 36,
    marginBottom: 20,
  },
  gameSection: {
    backgroundColor: colors.lightgrey,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
  },
  gameSectionHeader: {
    color: colors.grey,
    fontSize: 18,
    marginBottom: 20,
  },
  gameMetaSection: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  gameMetaField: {
    display: 'flex',
    marginRight: 5,
    marginBottom: 10,
    width: 300,
  },
  input: {
    flex: 1,
  },
  select: {
    flex: 1,
  },
  label: {
    color: colors.blue,
    marginRight: 5,
    textTransform: 'capitalize',
  },
  brandId: {
    opacity: 0.6,
  },
  modalActions: {
    display: 'flex',
    // marginTop: 'auto',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: 10,
    marginTop: 10,
  },
  modalCancel: {
    color: colors.blue,
    marginRight: 10,
    cursor: 'pointer',
  },
  modalSave: {
    fontSize: 16,
    backgroundColor: colors.blue,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 10,
    color: colors.white,
    cursor: 'pointer',
  },
  resetZip: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: colors.bluelighter,
  },
  zipUrl: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  gameIframe: {
    width: 320,
    height: (320 * 3) / 4,
  },
  selectFilter: {
    width: '100%',
  },
};

export default Game;
