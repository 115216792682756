import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import { BrandsSelect } from '../../components';

const BrandsSelectContainer = props => <BrandsSelect {...props} />;

const mapStateToProps = state => ({
  brands: selectors.brands.brandsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getBrands: bindActionCreators(actions.brands.uiActions.getBrands, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrandsSelectContainer);
