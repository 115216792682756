import React, { PureComponent } from 'react';
import brandsTableConfig, {
  brandLanguages,
  brandTargets,
} from '../config/tables/brands';
import Table from './Table';
import Search from './Filters/Search';
import FilterWrapper from './Filters/Wrapper';
import Select from './Filters/Select';
import { inString } from '../utils/search';

class BrandsTable extends PureComponent {
  state = {
    search: '',
    language: null,
    target: null,
  };

  getItems() {
    const { brands } = this.props;
    const { search, language, target } = this.state;
    if ((search && search.length) || language || target) {
      return brands.filter(item => {
        if (language && item.language !== language) {
          return false;
        }
        if (target && item.target !== target) {
          return false;
        }
        if (inString(item.name, search)) {
          return true;
        }
        if (inString(item.syncName, search)) {
          return true;
        }
        return false;
      });
    }
    return brands;
  }

  handleEdit = (e, item = {}) => {
    const { showModal } = this.props;
    e.preventDefault();
    showModal({ name: 'Brand', item });
  };

  handleLanguageChange = language => {
    this.setState({ language });
  };

  handleTargetChange = target => {
    this.setState({ target });
  };

  handleSearchChange = e => {
    const { value } = e.target;
    this.setState({ search: value });
  };

  render() {
    const { loading } = this.props;
    const { search, language, target } = this.state;
    return (
      <div>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-secondary"
            onClick={e => this.handleEdit(e)}
          >
            create brand
          </button>
        </div>
        <FilterWrapper>
          <Search
            value={search}
            onChange={this.handleSearchChange}
            id="media-search"
            placeholder="search"
            label="Search Name or Sync name"
          />
          <Select
            value={language}
            onChange={this.handleLanguageChange}
            id="language-select"
            items={brandLanguages}
            label="Language"
          />
          <Select
            value={target}
            onChange={this.handleTargetChange}
            id="target-select"
            items={brandTargets}
            label="Target"
          />
        </FilterWrapper>
        <Table
          items={this.getItems()}
          loading={loading}
          onEdit={this.handleEdit}
          {...brandsTableConfig}
          paginated
        />
      </div>
    );
  }
}

export default BrandsTable;
