import auth from './auth';
import app from './app';
import errors from './errors';
import media from './media';
import audits from './audits';
import sync from './sync';
import brands from './brands';
import campaigns from './campaigns';
import seasons from './seasons';
import series from './series';

export default {
  auth,
  app,
  errors,
  media,
  sync,
  brands,
  campaigns,
  seasons,
  series,
  audits,
};
