import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import { MultiBrandsSelect } from '../../components';

const MultiBrandsSelectContainer = props => <MultiBrandsSelect {...props} />;

const mapStateToProps = state => ({
  brands: selectors.brands.brandsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getBrands: bindActionCreators(actions.brands.uiActions.getBrands, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MultiBrandsSelectContainer);
