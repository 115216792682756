import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import selectors from '../../store/selectors';
import Layout from '../../components/Layout';

const LayoutContainer = props => <Layout {...props} />;

const mapStateToProps = state => ({
  isLoading: selectors.app.isLoadingSelector(state),
  isLoggedIn: selectors.auth.isLoggedInSelector(state),
  modal: selectors.app.modalSelector(state),
});

const mapDispatchToProps = dispatch => ({
  showModal: bindActionCreators(actions.app.uiActions.showModal, dispatch),
  dismissModal: bindActionCreators(actions.app.uiActions.dismissModal, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LayoutContainer);
