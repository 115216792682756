import {
  createUiActionType, createUiAction, createApiActionTypes, createApiActions,
} from './util';

export const uiActionTypes = {
  LOGOUT: createUiActionType('AUTH/LOGOUT'),
  LOGIN: createUiActionType('AUTH/LOGIN'),
};

export const uiActions = {
  logout: () => createUiAction(uiActionTypes.LOGOUT),
  login: loginData => createUiAction(uiActionTypes.LOGIN, loginData),
};

export const apiActionTypes = {
  AUTHORIZE: createApiActionTypes('AUTH/AUTHORIZE'),
  LOGIN: createApiActionTypes('AUTH/LOGIN'),
};

export const apiActions = {
  authorize: createApiActions(apiActionTypes.AUTHORIZE),
  login: createApiActions(apiActionTypes.LOGIN),
};
