import React, { PureComponent } from 'react';
import SyncTableContainer from '../containers/components/SyncTable';
import LayoutContainer from '../containers/components/Layout';

type SyncScreenProps = {
  getSyncData: void,
};

class SyncScreen extends PureComponent<SyncScreenProps> {
  componentWillMount() {
    const { getSyncData } = this.props;
    getSyncData();
  }

  render() {
    return (
      <LayoutContainer title="Sync">
        <SyncTableContainer />
      </LayoutContainer>
    );
  }
}

export default SyncScreen;
