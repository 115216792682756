import { formatDate } from '../../utils/date';

export default {
  paginated: true,
  columns: [
    {
      prop: 'id',
      label: 'Campaign Id',
      sortable: true,
    },

    {
      prop: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      prop: 'active',
      label: 'Campaign active',
      sortable: true,
    },
    {
      prop: 'email',
      label: 'Email campaign',
      sortable: true,
    },
    {
      prop: 'totalVouchers',
      label: 'Vouchers total',
      sortable: true,
    },
    {
      prop: 'activeVouchers',
      label: 'Vouchers active',
      sortable: true,
    },
    {
      prop: 'createdAt',
      label: 'Created',
      sortable: true,
    },
    {
      prop: 'updatedAt',
      label: 'Updated',
      sortable: true,
    },
    {
      action: 'edit',
      label: 'Edit campaign',
      button_label: 'Edit',
    },
  ],
  transformer: row => ({
    ...row,
    id: `${row.id}`,
    createdAt: formatDate(row.createdAt),
    updatedAt: formatDate(row.updatedAt),
    active: row.active.toString(),
    email: (row.email || '').toString(),
    original: row,
  }),
};
