import {
  createUiActionType, createUiAction, createApiActionTypes, createApiActions,
} from './util';

export const uiActionTypes = {
  GET_SYNC_DATA: createUiActionType('SYNC/GET_SYNC_DATA'),
};

export const uiActions = {
  getSyncData: () => createUiAction(uiActionTypes.GET_SYNC_DATA, {}),
};

export const apiActionTypes = {
  GET_SYNC_DATA: createApiActionTypes('SYNC/GET_SYNC_DATA'),
};

export const apiActions = {
  getSyncData: createApiActions(apiActionTypes.GET_SYNC_DATA),
};
