import React from 'react';
import {
  BrandsSelectContainer,
  MultiBrandsSelectContainer,
} from '../../containers/components';
import formatAllBrandByLanguage from '../../utils/brand';
import Select from '../Form/Select';
import Input from '../Form/Input';
import { colors } from '../../styles/theme';
import SortableList from '../SortableList';
import Layout from './Layout';
import DefaultModal from './DefaultModal';

class Playlist extends DefaultModal {
  constructor(props) {
    super(props);
    const { item = {} } = props;
    const {
      category = 'video',
      language = 'NL',
      title = '',
      description = '',
      brand = '',
      multiBrands = [],
      type = item && item.type ? item.type : 'movie',
      preview,
    } = item.original || item;

    const playlistItems = this.getPlaylistItems();

    this.state = {
      ...this.state,
      category,
      language,
      playlistBrand: brand || 'all-nl',
      search: '',
      results: playlistItems,
      showResults: true,
      playlistTitle: title,
      playlistDescription: description,
      playlistItems,
      type,
      possibleTypes: this.setPossibleTypes(category),
      multiBrands,
      preview,
    };
  }

  getItems = () => {
    const {
      search, category, distribution, language,
    } = this.state;
    const queryObject = {
      search,
      category,
      distribution,
      language,
      size: 200,
    };

    this.props.getItems(queryObject);
  };

  getPlaylistItems() {
    const { item = {} } = this.props;
    const { original = {} } = item;
    const { items = [] } = original;
    return items;
  }

  isPlaylistItem(item = {}) {
    return this.state.playlistItems
      .filter(i => i !== null && i !== undefined)
      .map(i => i.id)
      .includes(item.id);
  }

  addToPlaylist(item) {
    const filteredPlaylistItems = this.state.playlistItems.filter(
      i => i.id !== item.id,
    );
    const playlistItems = [...filteredPlaylistItems, item];
    this.setState({ playlistItems });
  }

  removeFromPlaylist(item) {
    const playlistItems = this.state.playlistItems.filter(
      i => i.id !== item.id,
    );
    this.setState({ playlistItems });
  }

  changeCategory(category) {
    this.setState(
      {
        category,
        playlistItems: [],
        possibleTypes: this.setPossibleTypes(category),
        type: this.setPossibleTypes(category)[0],
      },
      () => this.search(this.state.search),
    );
  }

  changeLanguage(language) {
    const { playlistBrand } = this.state;
    this.setState({
      language,
      playlistBrand: formatAllBrandByLanguage(playlistBrand, language),
    }, () => this.search(this.state.search));
  }

  changeType(type) {
    this.setState({ type });
  }

  setPossibleTypes = (category) => {
    let possibleTypes = [];
    if (category === 'video') {
      possibleTypes = ['movie', 'series', 'extra'];
    }
    if (category === 'music') {
      possibleTypes = ['karaoke', 'clips'];
    }
    if (category === 'games') {
      possibleTypes = ['games'];
    }
    if (category === 'books') {
      possibleTypes = ['books'];
    }
    return possibleTypes;
  };

  search(value) {
    const { debounceTimeoutId } = this.state;
    if (debounceTimeoutId) {
      clearTimeout(debounceTimeoutId);
    }

    const timeoutId = setTimeout(() => {
      this.getItems();
    }, 400);

    this.setState({
      search: value,
      debounceTimeoutId: timeoutId,
    });
  }

  savePlaylist() {
    const {
      item, updateMediaItem, createMediaItem, dismissModal,
    } = this.props;
    const {
      playlistTitle,
      playlistDescription,
      playlistItems,
      category,
      language,
      multiBrands,
      type,
      preview,
    } = this.state;
    const { playlistBrand: brand } = this.state;

    const saveItem = {
      ...this.getStateValuesDefaultFields(),
      title: playlistTitle,
      description: playlistDescription,
      items: playlistItems.map(i => ({ id: i.id })),
      category,
      subCategory: 'playlist',
      language,
      brand: formatAllBrandByLanguage(brand, language),
      multiBrands,
      type,
      preview,
      multiCategoryContent:
        [...new Set(playlistItems.map(i => i.category))].length > 1,
    };

    if (saveItem.items.length === 0) {
      alert('There needs to be at least one item in your playlist!');
    } else {
      if (item && item.id) {
        updateMediaItem(item.original ? item.original.id : item.id, saveItem);
      } else {
        createMediaItem(saveItem);
      }
      dismissModal();
    }
  }

  resetResults() {
    this.setState({
      search: '',
    });
    this.getItems();
  }

  renderPreview(item) {
    if (!item) {
      return;
    }
    const { preview = {} } = item;
    const previewImage = (preview.small || preview.medium || {}).filename;
    const textStyle = this.isPlaylistItem(item) ? { color: colors.black } : {};
    return (
      <div style={{ width: 350 }}>
        <div>
          <span style={styles.playlistItemCategory}>{item.category}</span>
          {['video', 'music'].includes(item.category) && (
            <span style={styles.playlistItemSubCategory}>
              {item.subCategory}
            </span>
          )}
          {item.published ? (
            <span style={styles.playlistItemPublished}>published</span>
          ) : (
            <span style={styles.playlistItemUnpublished}>unpublished</span>
          )}
          {item.locked ? (
            <span style={styles.playlistItemLocked}>locked</span>
          ) : (
            <span style={styles.playlistItemUnlocked}>unlocked</span>
          )}
        </div>
        <div style={styles.previewContainer}>
          <img src={previewImage} style={styles.previewImage} />
          <div>
            <div style={styles.playlistItemMeta}>
              <span style={styles.playlistItemId}>{item.id}</span>
            </div>
            <div>
              <span style={{ ...styles.previewTitle, ...textStyle }}>
                {item.title}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderResult(result, resultIdx) {
    const isPlaylistItem = this.isPlaylistItem(result);
    const resultStyle = isPlaylistItem
      ? { backgroundColor: colors.lightblue }
      : {};
    const textStyle = isPlaylistItem ? { color: colors.white } : {};
    return (
      <div
        key={`result-${resultIdx}`}
        style={{ ...styles.result, ...resultStyle }}
      >
        <div
          style={{ ...styles.addToPlaylist, ...textStyle }}
          onClick={e => (isPlaylistItem
            ? this.removeFromPlaylist(result)
            : this.addToPlaylist(result))
          }
        >
          {isPlaylistItem ? '-' : '+'}
        </div>
        {this.renderPreview(result)}
      </div>
    );
  }

  renderSearchResults() {
    const { data = {} } = this.props;
    const { distribution } = this.state;
    const { items, loading } = data;

    const allItems = [
      ...items.filter(item => (item.subCategory !== 'playlist' && (!distribution || item.distribution === distribution))),
      ...this.getPlaylistItems(),
    ].filter(
      ({ id: currentId }, i, a) => a.findIndex(({ id }) => id === currentId) === i,
    );

    if (loading) {
      return 'Loading...';
    }

    if (allItems.length > 0) {
      return (
        <div style={{ display: 'flex' }}>
          <div style={styles.results}>
            {
              allItems.map(this.renderResult.bind(this))
            }
          </div>
          <SortableList
            styles={styles}
            results={this.state.playlistItems}
            renderPreview={this.renderPreview.bind(this)}
            updatePlaylist={updatedResultsOrder => this.setState({ playlistItems: updatedResultsOrder })
            }
          />
        </div>
      );
    }
    return null;
  }

  renderPlaylistItem(item, itemIdx) {
    return (
      <div key={`item-${itemIdx}`} style={styles.playlistItem}>
        <div
          style={styles.removeFromPlaylist}
          onClick={e => this.removeFromPlaylist(item)}
        >
          -
        </div>
        {this.renderPreview(item)}
      </div>
    );
  }

  renderContentFilter() {
    return (
      <div>
        <div style={styles.playlistSectionHeader}>Playlist items</div>
        <div style={styles.playlistMetaField}>
          <Input
            value={this.state.search}
            label="Search"
            type="text"
            id="search-filter"
            onChange={e => this.search(e.target.value)}
          />
        </div>
        {this.state.results.length > 0 && (
          <div style={{ display: 'flex', flex: 1 }}>
            <div style={styles.resetResults} onClick={e => this.resetResults()}>
              reset
            </div>
            <div
              onClick={e => this.setState({ showResults: !this.state.showResults })
              }
              style={styles.toggleResults}
            >
              toggle results
            </div>
          </div>
        )}
      </div>
    );
  }

  renderPlaylistMeta() {
    const playlistTypes = [];
    this.state.possibleTypes.forEach((type) => {
      playlistTypes.push({ value: type, label: type });
    });

    return (
      <div style={{ width: 700 }}>
        <div style={styles.playlistSectionHeader}>Playlist info</div>
        <div>
          <Select
            value={this.state.category}
            items={[
              { value: 'video', label: 'Video' },
              { value: 'music', label: 'Music' },
              { value: 'books', label: 'Books' },
              { value: 'games', label: 'Games' },
            ]}
            label="Category"
            id="category-filter"
            onChange={value => this.changeCategory(value)}
          />
        </div>
        <div>
          <Select
            value={this.state.type}
            items={playlistTypes}
            label="Playlist type"
            id="playlist-type-filter"
            onChange={value => this.changeType(value)}
          />
        </div>
        <div>
          <Select
            value={this.state.language}
            items={[{ value: 'NL', label: 'NL' }, { value: 'FR', label: 'FR' }]}
            label="Language"
            id="language-filter"
            onChange={value => this.changeLanguage(value)}
          />
        </div>
        <div>
          <MultiBrandsSelectContainer
            id="multiBrands-filter"
            language={this.state.language}
            style={{ display: 'inline-block', width: 300 }}
            value={this.state.multiBrands}
            mainBrand={this.state.playlistBrand}
            onChange={multiBrands => this.setState({ multiBrands })}
          />
        </div>
        <div>
          <Input
            value={this.state.playlistTitle}
            label="Title"
            type="text"
            id="playlist-title"
            onChange={e => this.setState({ playlistTitle: e.target.value })}
          />
        </div>
        <div>
          <Input
            value={this.state.playlistDescription}
            label="Description"
            type="text"
            id="playlist-description"
            onChange={e => this.setState({ playlistDescription: e.target.value })
            }
          />
        </div>
        <div>
          <BrandsSelectContainer
            id="playlist-distribution"
            style={styles.select}
            language={this.state.language}
            value={this.state.playlistBrand}
            onChange={brand => this.setState({ playlistBrand: brand })}
          />
        </div>
      </div>
    );
  }

  renderPlaylist() {
    const { showResults, results } = this.state;

    return (
      <div style={styles.playlist}>
        {this.renderPlaylistMeta()}
        {this.renderContentFilter()}
        {showResults && this.renderSearchResults()}
        {(!showResults) && (
          <SortableList
            styles={styles}
            results={this.state.playlistItems}
            renderPreview={this.renderPreview.bind(this)}
            updatePlaylist={updatedResultsOrder => this.setState({ playlistItems: updatedResultsOrder })
            }
          />
        )}
      </div>
    );
  }

  deletePlaylist() {
    const { item, dismissModal } = this.props;
    if (confirm('Are you sure? This action can not be undone!')) {
      this.props.deleteMediaItem(item.original ? item.original.id : item.id);
      dismissModal();
    }
  }

  renderModalActions() {
    const { dismissModal, isSaving, item } = this.props;

    return (
      <Layout.Actions
        disabled={this.isProcessingUploads()}
        showDelete={item && item.id}
        onCancel={() => dismissModal()}
        submmitting={isSaving}
        onDelete={() => this.deletePlaylist()}
        onSave={() => this.savePlaylist()}
      />
    );
  }

  render() {
    const { uploadPreview } = this.props;
    const { preview } = this.state;

    return (
      <Layout title="create playlist">
        {this.renderCommonFields()}
        {this.renderPreviewField(uploadPreview)}
        {preview && (
        <button
          style={{ margin: '0 0 5px 0' }}
          className="btn btn-sm btn-secondary"
          type="submit"
          onClick={() => {
            this.setState({
              preview: null,
            });
          }}
        >
          Clear custom preview
        </button>
        )}
        {this.renderPlaylist()}
        {this.renderModalActions()}
      </Layout>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    width: 300,
  },
  label: {
    color: colors.blue,
    marginRight: 5,
  },
  selectFilter: {
    marginRight: 10,
  },
  playlist: {
    flex: 1,
  },
  playlistItems: {
    // backgroundColor: colors.lightblue,
    maxHeight: 400,
    marginTop: 15,
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: 10,
    overflow: 'scroll',
    overflowX: 'hidden',
  },
  results: {
    // position: 'relative',
    maxHeight: 400,
    marginTop: 15,
    marginBottom: 15,
    width: '50%',
    display: 'inline-block',
    // flex: 1,
    // flexDirection: 'column',
    overflow: 'scroll',
    overflowX: 'hidden',
  },
  toggleResults: {
    marginLeft: 'auto',
    marginRight: 10,
    color: colors.lightblue,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  resetResults: {
    color: colors.lightblue,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  result: {
    display: 'flex',
    flexShrink: 0,
    marginBottom: 1,
    paddingTop: 5,
    paddingBottom: 5,
    alignItems: 'center',
    borderBottom: 'solid #e8e8e8 1px',
  },
  playlistSection: {
    backgroundColor: colors.lightgrey,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
  },
  playlistSectionHeader: {
    color: colors.grey,
    fontSize: 18,
    marginBottom: 20,
  },
  playlistMetaSection: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  playlistMetaField: {
    marginRight: 5,
    width: 300,
  },
  playlistMetaFieldMultiBrands: {
    width: 500,
  },
  playlistItem: {
    display: 'flex',
    flexShrink: 0,
    marginBottom: 1,
    paddingTop: 5,
    paddingBottom: 5,
    alignItems: 'center',
    backgroundColor: colors.lightblue,
  },
  playlistItemMeta: {
    marginBottom: 5,
  },
  playlistItemId: {
    opacity: 0.6,
    fontSize: 10,
  },
  playlistItemCategory: {
    marginLeft: 0,
    padding: 2,
    borderRadius: 4,
    backgroundColor: colors.green,
    color: colors.white,
    fontSize: 10,
  },
  playlistItemSubCategory: {
    marginLeft: 5,
    padding: 2,
    borderRadius: 4,
    backgroundColor: colors.orange,
    color: colors.white,
    fontSize: 10,
  },
  playlistItemPublished: {
    marginLeft: 5,
    padding: 2,
    borderRadius: 4,
    backgroundColor: colors.bluelighter,
    color: colors.white,
    fontSize: 10,
  },
  playlistItemUnpublished: {
    marginLeft: 5,
    padding: 2,
    borderRadius: 4,
    backgroundColor: colors.pinklighter,
    color: colors.white,
    fontSize: 10,
  },
  playlistItemLocked: {
    marginLeft: 5,
    padding: 2,
    borderRadius: 4,
    backgroundColor: colors.indigo,
    color: colors.white,
    fontSize: 10,
  },
  playlistItemUnlocked: {
    marginLeft: 5,
    padding: 2,
    borderRadius: 4,
    backgroundColor: colors.green,
    color: colors.white,
    fontSize: 10,
  },
  input: {
    width: 200,
  },
  addToPlaylist: {
    margin: 5,
    color: colors.lightblue,
    cursor: 'pointer',
    width: 20,
    display: 'flex',
    justifyContent: 'center',
  },
  removeFromPlaylist: {
    margin: 5,
    color: colors.white,
    cursor: 'pointer',
  },
  previewContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  previewImage: {
    height: 50,
    marginRight: 10,
  },
  previewTitle: {
    color: colors.blue,
    fontSize: 12,
  },
  modalActions: {
    display: 'flex',
    // marginTop: 'auto',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: 10,
    marginTop: 10,
  },
  modalTitle: {
    color: colors.blue,
    fontSize: 36,
    marginBottom: 20,
  },
  modalCancel: {
    color: colors.blue,
    marginRight: 10,
    cursor: 'pointer',
  },
  modalSave: {
    fontSize: 16,
    backgroundColor: colors.blue,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 10,
    color: colors.white,
    cursor: 'pointer',
  },
  select: {
    flex: 1,
  },
};

export default Playlist;
