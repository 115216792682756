import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import styles from './BulkActions.scss';
import Select from './Form/Select';
import bulkActions from '../config/bulkActions';
import DatePicker from './Form/DatePicker';
import Checkbox from './Form/Checkbox';
import Input from './Form/Input';
import ColorInput from './Form/ColorInput';

type BulkActionsProps = {
  bulkProgress: Object,
  onBulkClick: void,
  onCancel: void,
  selectionLength: number,
};

class BulkActions extends React.Component<BulkActionsProps> {
  state = {
    bulkAction: null,
    bulkActionValue: null,
    bulkFinished: false,
  };

  componentWillReceiveProps(nextProps) {
    const { bulkProgress } = this.props;
    if (bulkProgress.inProgress && !nextProps.bulkProgress.inProgress) {
      this.setState({ bulkFinished: true, bulkActionValue: null });
      setTimeout(() => {
        this.setState({ bulkFinished: false });
      }, 2000);
    }
  }

  handleBundleClick = (...args) => {
    const { onBulkClick } = this.props;
    if (confirm('Are you really sure?')) {
      onBulkClick(...args);
    }
  };

  handleBulkActionChange = (value) => {
    const selected = bulkActions.find(a => a.value === value);
    if (selected && selected.defaultValue) {
      this.setState({ bulkAction: value, bulkActionValue: selected.defaultValue });
    } else {
      this.setState({ bulkAction: value, bulkActionValue: null });
    }
  };

  handleBulkActionValueChange = (value) => {
    this.setState({ bulkActionValue: value });
  };

  renderActionButton = ({ prefix, value }) => {
    const { bulkAction } = this.state;
    const { bulkProgress, selectionLength } = this.props;
    const selectedItem = bulkActions.find(a => a.value === bulkAction);
    return (
      <button
        type="button"
        className="btn btn-secondary"
        disabled={bulkProgress.inProgress || value === null}
        onClick={() => this.handleBundleClick(bulkAction, value, selectedItem)}
        style={{ marginRight: 5 }}
      >
        {`${prefix} ${selectionLength} item${selectionLength === 1 ? '' : 's'}`}
      </button>
    );
  }

  render() {
    const { bulkAction, bulkActionValue, bulkFinished } = this.state;
    const { bulkProgress, onCancel } = this.props;
    const selectedItem = bulkActions.find(a => a.value === bulkAction);

    return (
      <div className={styles.root}>
        <h4>Bulk action</h4>
        <div className="row">
          <div className="col">
            <Select
              items={bulkActions}
              id="bulk-action-select"
              label="Select the action you would like to perform"
              onChange={this.handleBulkActionChange}
              value={bulkAction}
            />
          </div>
          <div className="col">
            <div>
              {selectedItem && selectedItem.type === 'datetime' && (
                <DatePicker
                  label={selectedItem.propertyLabel}
                  value={bulkActionValue}
                  onChange={(date) => {
                    this.setState({ bulkActionValue: date });
                  }}
                />
              )}
              {selectedItem && selectedItem.type === 'sub-form' && (
                <>
                  {selectedItem.fields && selectedItem.fields.map(({ value, type, propertyLabel }) => (
                    <>
                      {type === 'text' && (
                        <Input
                          value={bulkActionValue && bulkActionValue[value] || ''}
                          label={propertyLabel}
                          type="text"
                          id={value}
                          onChange={e => this.setState({
                            bulkActionValue: {
                              ...bulkActionValue,
                              [value]: e.target.value,
                            },
                          })}
                        />
                      )}
                      {type === 'color' && (
                        <ColorInput
                          value={bulkActionValue && bulkActionValue[value] || {}}
                          label={propertyLabel}
                          id={value}
                          onChange={color => this.setState({
                            bulkActionValue: {
                              ...bulkActionValue,
                              [value]: color.hex,
                            },
                          })}
                        />
                      )}
                    </>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
        <div>

          {selectedItem && selectedItem.type === 'checkbox' ? (
            <>
              {selectedItem.propertyLabelOn && this.renderActionButton({ prefix: selectedItem.propertyLabelOn, value: true })}
              {selectedItem.propertyLabelOff && this.renderActionButton({ prefix: selectedItem.propertyLabelOff, value: false })}
            </>
          ) : (
            this.renderActionButton({ prefix: selectedItem?.actionPrefix || 'Apply to', value: bulkActionValue })
          )}
          <button
            type="button"
            disabled={bulkProgress.inProgress}
            onClick={onCancel}
            className="btn btn-link"
          >
            cancel
          </button>
        </div>


        {bulkProgress.inProgress && (
          <div className="progress" style={{ marginTop: 10, marginBottom: 10 }}>
            <div className="progress-bar bg-info" role="progressbar" style={{ width: `${bulkProgress.progress}%` }}>
              {`${bulkProgress.progress}% done`}
            </div>
          </div>
        )}

        {bulkFinished && (
          <div className={styles.progressContainer}>
            <div className={styles.progress}>done!</div>
          </div>
        )}
      </div>
    );
  }
}
export default BulkActions;
