import React from 'react';

import Table from './Table';
import TableRow from './TableRow';
import TableCell from './TableCell';
import styles from './Placeholder.scss';

import generator from './generator';

const Row = ({ columns }) => {
  const columnItems = [];
  for (let i = 0; i < columns; i += 1) {
    columnItems.push(
      <TableCell key={`list-placeholder-row-item-${i}`}>{generator.word(1)}</TableCell>,
    );
  }

  return <TableRow>{columnItems}</TableRow>;
};

const TablePlaceHolder = ({
  children, rows, columns, headers,
}) => {
  const rowItems = [];
  for (let i = 0; i < rows; i += 1) {
    rowItems.push(<Row columns={columns} key={`list-placeholder-row-${i}`} />);
  }

  return (
    <div className={styles.root}>
      <Table>
        <thead>{headers}</thead>
        <tbody className={styles.redacted}>{rowItems}</tbody>
      </Table>

      <div className={styles.overlay}>
        <div>{children}</div>
      </div>
    </div>
  );
};
TablePlaceHolder.defaultProps = {
  rows: 15,
  columns: 4,
};
export default TablePlaceHolder;
